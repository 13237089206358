export default   {
    reducer_profil : function( state = {
        user : null
    }, action){

    let _state = {...state}

    if(action.user)
      _state.user = action.user  
      return _state
    }
    
  }