import React from "react";
import watch from 'redux-watch'
import { Backdrop, CircularProgress, FormControlLabel, FormControl, FormLabel,RadioGroup, Radio} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Refresh from '@material-ui/icons/Refresh';
import Clear from '@material-ui/icons/Clear';
import Domain from '@material-ui/icons/Domain';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/DeleteForever';
import DomainAddIcon from '@material-ui/icons/AddBox';
import ReactTooltip from "react-tooltip";
import { Modal } from 'react-bootstrap';
import '../styles/Stations.css';
import cookies from "../Cookies";
import Routes from '../Routes.js'
import { connect } from 'react-redux';
import Stations_State from '../states/Stations_State.js'
import util from "../util";
import * as Excel from "exceljs";
import {saveAs } from 'file-saver'
// Logger with default options
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import conf from '../config.js'

 
const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration : false  , // print the duration of each action?
  timestamp : true, // print the timestamp with each action?
 
  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.
 
  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.
 
  logger : console, // implementation of the `console` API.
  logErrors : true, // should the logger catch, log, and re-throw errors?
 
  diff : false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`

});

var config = conf.config ;
var store_stations = createStore(Stations_State.reducer_stations, applyMiddleware(logger))
var store_filters = createStore(Stations_State.reducer_filters, applyMiddleware(logger))

class Main extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
      export_csv : "1",
      key : Date.now()
    }

  }

  async componentDidMount() {

    document.title = "Quantum - Stations"; 

    store_stations.dispatch({type:1, page_loading_spinner : true})  
    
    let filters = store_filters.getState()
    let res = await Routes.get("/Stations", filters, true)

    if(!res) return null
    
    store_stations.dispatch({type : 1, stations : res, page_loading_spinner : false  })             
  }
 
  render() {

    if(!this.props.loggedIn || !cookies.rights ||(cookies.rights && !cookies.rights.stations_view)){
      return window.location.replace(config.front_host)
    }

    return <div key={this.state.key}>

      {this.state.notification}

      <div class="container-fluid background">
        
         <PageLoadingSpinner />

        <FilterMenu />

        <ModalConfirm/>

        <ExportModal />

        <ModalAddStation />

        <ModalUpdateStation />

        <ModalDeleteStation/> 

        <StationsList class="row" />
 
      </div> 
    </div>
  }

}
 
export default connect(
  (state) => ({
    authorized_stations: state.authorized_stations, 
    loggedIn : state.loggedIn
  })
)(Main) 

class StationsList extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      key : Date.now()
    }
  }

  componentDidMount(){
    store_filters.subscribe(()=>{
      this.setState({key:Date.now()})
    })
      let w1 = watch(store_stations.getState, 'stations')
      store_stations.subscribe(w1((newVal, oldVal, objectPath) => {
        this.setState({key : Date.now()})
      }))
  }

  render(){

  let html = []
  let stations = store_stations.getState().stations;
  let filters = store_filters.getState();

  if(!stations) return
   
  let stations_filtered = []
  
  stations.forEach((u)=>{

    if(filters.station.trim() !== "" && !u.station.toLowerCase().includes(filters.station.toLowerCase())){
      return
    }

    if((!u.origin_zone && filters.origin_zone) || ( filters.origin_zone.trim() !== "" && !u.origin_zone.toLowerCase().includes(filters.origin_zone.toLowerCase())) ){
       return
     }

    if(filters.sales_region.trim() !== "" && !u.sales_region.toLowerCase().includes(filters.sales_region.toLowerCase())){
      return
    }

    if(filters.client_id.trim() !== "" && !u.client_id.toLowerCase().includes(filters.client_id.toLowerCase())){
      return
    }

    if(filters.station_name.trim() !== "" && !u.station_name.toLowerCase().includes(filters.station_name.toLowerCase())){
      return
    }

    if( (!u.country_code && filters.country_code) || (filters.country_code.trim() !== "" && !u.country_code.toLowerCase().includes(filters.country_code.toLowerCase()))){
      return
    }

    if( (!u.brand_code && filters.brand_code) || (filters.brand_code.trim() !== "" && !u.brand_code.toLowerCase().includes(filters.brand_code.toLowerCase())) ){
      return
    }

    if( (!u.entity_code && filters.entity_code) || (filters.entity_code.trim() !== "" && !u.entity_code.toLowerCase().includes(filters.entity_code.toLowerCase())) ){
      return
    }
    
  
    stations_filtered.push(u) 

    html.push(    

      <tr>

        <td >{stations.indexOf(u) + 1}</td>
        <td >{u.entity_code}</td>
        <td >{u.station}</td>
        <td >{u.origin_zone}</td>
        <td >{u.sales_region}</td>
        <td >{u.client_id}</td>
        <td >{u.station_name}</td>
        <td >{u.country_code}</td>
        <td >{u.brand_code}</td>

        <td>
          <button class ='btn btn-primary' style = {{paddingLeft : '0.5em', paddingRight : '0.5em', marginLeft : '1em'}}
            onClick = {(e)=> {
              u.id = u.station
              store_stations.dispatch({type:1, one_station : u, updateModal : true })
            }}
            data-tip = "Update Station"
            data-type = 'info'
          >
            <Edit fontSize="small"/>
          </button>

          <button class ='btn btn-danger' style = {{paddingLeft : '0.5em', paddingRight : '0.5em', marginLeft : '1em'}}
            onClick = {(e)=> {
              u.id = u.station
              store_stations.dispatch({type:1, one_station : u, deleteModal : true })
            }}
            data-tip = "Delete Station"
            data-type = 'info'    
          >
            <Delete fontSize="small"/>
          </button>
        </td>
      </tr>
    ) 
  })

  store_stations.dispatch({type:1, stations_filtered : stations_filtered, countStations : stations_filtered.length })
  
  return (
    <div class="col users-list" key={this.state.key}>
      <table   class="table-users table-striped">
        <thead>
          <tr>
            <th scope="" >#</th>
            <th scope="col">Entity</th>
            <th scope="" >Station</th>
            <th scope="col" >Origin zone</th>
            <th scope="col" >Sales region</th>
            <th scope="col" >Client</th>
            <th scope="col" >Name</th>
            <th scope="col">Country</th>
            <th scope="col">Brand</th>
            <th scope="col" >Edit</th>
          </tr>
        </thead>
        <tbody class="us-list-body">
          {html}
        </tbody>
      </table>
    </div> 
  )
    
}

}

class FilterMenu extends React.Component{
  
  constructor(props){
    super(props)
    this.state = { 
       key : Date.now(),
       scrolled : false       
      }
  }

  componentDidMount(){
    let w1 = watch(store_filters.getState, 'reset')
    store_filters.subscribe(w1((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now(), scrolled : false})
    }))

    let w2 = watch(store_stations.getState, 'stations')
    store_stations.subscribe(w2((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now(), scrolled : false})
    }))
  }

  async onClickReset(e){
    store_filters.dispatch({type : 1, reset : true})
    this.onClickRefresh()
  }

  async onClickRefresh(e){
    store_stations.dispatch({type:1, page_loading_spinner : true})
    let filters = store_filters.getState()
    let res = await Routes.get("/Stations", filters, true)
    store_stations.dispatch({type : 1, stations : res, page_loading_spinner : false  }) 
  }

  render(){
    const filters_state = store_filters.getState();

    return (
      <div key={this.state.key} class= { "filter-menu"}>
        <div class="row">
          <div class="col-2">
            <button 
              type="button" 
              class="btn btn-success" 
              data-tip = "Stations List"
              data-type = 'info'>
              <Domain fontSize="small"/>
              <span>{"  "}{store_stations.getState().countStations}</span>
            </button>

            <button 
              type="button" 
              class="btn btn-danger" 
              data-tip = "Clear Search"
              data-type = 'info'
              onClick={async (e)=>{ this.onClickReset(e)}}>
                 <Clear fontSize="small"/>
                 
            </button>

            <button 
              type="button" 
              class="btn btn-primary" 
              data-tip = "Refresh"
              data-type = 'info'
              onClick={async (e)=>{this.onClickRefresh(e)}}>
                 <Refresh fontSize="small"/>
            </button>

            <button type="button" 
                class="btn btn-success"  
                data-tip = "Download"
                data-type = 'info'
                onClick={ (e) => store_stations.dispatch({type : 1, exportModal : true, export_type : "csv"})} > 
               <GetAppIcon fontSize="small"/>
            </button>
            {
             // <ReactTooltip place="top" effect="solid" delayHide={500} />
            }
            <ExportButton/>
          </div>

          <div class="col-10">
            <div class="row row-filter"> {/* FILTERS */}
              <div class="col-1">
                <label >Station</label>
                <input type="text" class="form-control" defaultValue={filters_state.station} 
                  onChange={(e)=>{ 
                    store_filters.dispatch({type : 1, station : e.target.value})
                  }}
                />
              </div>

              <div class="col-1">
                <label >Origin zone</label>
                <input type="text" class="form-control" defaultValue={filters_state.origin_zone} 
                  onChange={(e)=>{ 
                    store_filters.dispatch({type : 1, origin_zone : e.target.value})
                  }}
                />
              </div>

              <div class="col-1">
                <label >Sales region</label>
                <input type="text" class="form-control" defaultValue={filters_state.sales_region} 
                  onChange={(e)=>{ store_filters.dispatch({type : 1, sales_region : e.target.value})}
                }/>
              </div>


              <div class="col-2">
                <label >Country</label>
                <input type="text" class="form-control" defaultValue={filters_state.country_code} 
                  onChange={(e)=>{ 
                    store_filters.dispatch({type : 1, country_code : e.target.value})
                  }}
                />
              </div>

              <div class="col-2">
                <label >Brand</label>
                <input type="text" class="form-control" defaultValue={filters_state.brand_code} 
                  onChange={(e)=>{ 
                    store_filters.dispatch({type : 1, brand_code : e.target.value})
                  }}
                />
              </div>

              <div class="col-2">
                <label >Entity</label>
                <input type="text" class="form-control" defaultValue={filters_state.entity_code} 
                maxLength = {3}
                  onChange={(e)=>{ 
                    store_filters.dispatch({type : 1, entity_code : e.target.value})
                  }}
                />
              </div>

              <div class="col-1" >
                <button 
                  data-tip = "Add new station"
                  data-type = 'info'
                  type='button' class ="btn btn-success" style = {{marginTop : '2em'}}
                  onClick = {(e) => {
                    store_stations.dispatch({type:1,  addModal : true})
                  }}
                >
                  <DomainAddIcon fontSize="small"/>
                  {  
                    <ReactTooltip place="top" effect="solid" delayHide={500} />
                  }
                </button>
              </div>
            </div>  
          </div>
        </div>
      </div>
    )
  }
}

class PageLoadingSpinner extends React.Component{
  constructor(props){
    super(props);

    this.state = {  key : Date.now() }
    this.style = {
      position: "absolute",
      left: "50%",
      margin : "auto",
      "margin-top" : "20em",
      "z-index" : "10",
      color : "white"
    }
  }

  componentDidMount(){
    let w1 = watch(store_stations.getState, 'page_loading_spinner')
    store_stations.subscribe(w1((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }

  render(){
    let show = store_stations.getState().page_loading_spinner
    if(!show) return null
    
    else{
      return (
        <div 
          key={this.state} 
          style = {this.style} 
          class="" 
          role="status"
        >
          <Backdrop   open={true}  >
          <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )
    }
  }
}

class ModalAddStation extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }
  }

  componentDidMount(){
    let w = watch(store_stations.getState, 'addModal')
    store_stations.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))  
  }

  onClickSave(){

    if(!checkFields()){
      store_stations.dispatch({type : 1, confirmModal : true})
    }
     
    this.setState({key : Date.now()}) 
  }

  onClickClose(){
    store_stations.dispatch({type:1,  addModal : false})   
    store_stations.dispatch({
      type:1, 
      error : false, 
      errors : {
        station : "", 
        origin_zone : "", 
        sales_region : "", 
        client_id : "", 
        station_name : "",
        country_code : "",
        brand_code : "",
        entity_code : ""
      }
    })

    store_stations.dispatch({
      type:1, 
      one_station : {
        id : null, 
        station : "", 
        origin_zone : "", 
        sales_region : "", 
        client_id : "", 
        station_name : "",
        country_code : "",
        brand_code : "",
        entity_code : ""
      }
    })    
  }
  
  render(){

   let show = store_stations.getState().addModal;
   let one_station = store_stations.getState().one_station
   let errors = store_stations.getState().errors

  return(
      <Modal show={show}
        dialogClassName="modal-m"
        onHide={()=>{store_stations.dispatch({type:1,  addModal : true})} }>
        <Modal.Header className="station_modal_header">
          <Modal.Title >
            <div>
              Add new Station 
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Station</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.station ? "is-invalid" : "" )} 
                maxLength = {3}
                defaultValue={one_station.station} 
                onChange={(e)=>{ 
                  store_stations.dispatch({type : 1, addStation : true,  station : e.target.value})
                }}
              />
              <div class ='error'>{errors.station  ? errors.station : ""}</div>
            </div>

            <div class="col-6">
              <label >Origine zone</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.origin_zone ? "is-invalid" : "" )} 
                defaultValue={one_station.origin_zone} 
                maxLength = {5}
                onChange={(e)=>{ 
                  store_stations.dispatch({type : 1, addStation : true,  origin_zone : e.target.value})
                }}
              />
              <div class ='error'>{errors.origin_zone ? errors.origin_zone : ""}</div>
            </div>
          </div>

          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Sales region</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.sales_region ? "is-invalid" : "" )} 
                maxLength = {3}
                defaultValue={one_station.sales_region} 
                onChange={(e)=>{ store_stations.dispatch({type : 1, addStation : true,  sales_region : e.target.value})}}
              />
              <div class ='error'>{errors.sales_region  ? errors.sales_region : ""}</div>
            </div>
            
            <div class="col-6">
              <label >Client</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.client_id ? "is-invalid" : "" )} 
                maxLength = {3}
                defaultValue={one_station.client_id} 
                onChange={(e)=>{ store_stations.dispatch({type : 1, addStation : true,  client_id : e.target.value})}}
              />
              <div class ='error'>{errors.client_id  ? errors.client_id : ""}</div>
            </div>

          </div>

          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Name</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.station_name ? "is-invalid" : "" )} 
                maxLength = {50}
                defaultValue={one_station.station_name} 
                onChange={(e)=>{ store_stations.dispatch({type : 1, addStation : true,  station_name :e.target.value})}}
              />
              <div class ='error'>{errors.station_name  ? errors.station_name : ""}</div>
            </div>

            <div class="col-6">
              <label >Country</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.country_code ? "is-invalid" : "" )} 
                defaultValue={one_station.country_code} 
                maxLength = {2}
                onChange={(e)=>{ store_stations.dispatch({type : 1, addStation : true,  country_code :e.target.value})}}
              />
              <div class ='error'>{errors.country_code ? errors.country_code : ""}</div>
            </div>
          </div>     

          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Brand</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.brand_code ? "is-invalid" : "" )} 
                defaultValue={one_station.brand_code} 
                maxLength = {50}
                onChange={(e)=>{ store_stations.dispatch({type : 1, addStation : true,  brand_code :e.target.value})}}
              />
              <div class ='error'>{errors.brand_code ? errors.brand_code : ""}</div>
            </div>

            <div class="col-6">
              <label >Entity</label>
              <input 
                type="text"  
                class= {"form-control " + (errors.entity_code ? "is-invalid" : "" )} 
                defaultValue={one_station.entity_code} 
                maxLength = {4}
                onChange={(e)=>{ store_stations.dispatch({type : 1, addStation : true,  entity_code :e.target.value})}}
              />
              <div class ='error'>{errors.entity_code ? errors.entity_code : ""}</div>
            </div>
          </div>


          <div class="row" style = {{textAlign : 'center'}}>
            <div class="col-4"></div>

            <div class="col-4">
              <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}} 
                onClick={async (e)=>{this.onClickSave(e)}}>
                Save
              </button>

              <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
                onClick={(e)=>{this.onClickClose(e)}}>
                Close
              </button>

            </div>
            <div class="col-4"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>      
    )
  }
}

class ModalUpdateStation extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }
  }

  componentDidMount(){
    let w = watch(store_stations.getState, 'updateModal')
    store_stations.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
       
  }

  onClickUpdate(e){
    let updated = store_stations.getState().stationUpdated

    if(updated){

        if(!checkFields()){
          store_stations.dispatch({type : 1, confirmModal : true})
        }

        this.setState({key : Date.now()})

    }else{

      store_stations.dispatch({type:1,  updateModal : false})  

      store_stations.dispatch({
        type:1, 
        error : false, 
        errors : {
          station : "", 
          origin_zone : "", 
          sales_region : "",
          client_id : "", 
          station_name : "", 
          country_code : "", 
          brand_code : "",
          entity_code : ""
        }})  

      store_stations.dispatch({
        type:1, 
        one_station : {
          id : null, 
          station : "", 
          origin_zone : "", 
          sales_region : "",
          client_id : "", 
          station_name : "", 
          country_code : "", 
          brand_code : "",
          entity_code : ""
        }}) 

    }
  }

  onClickClose(e){
    
    store_stations.dispatch({type:1,  updateModal : false})  

    store_stations.dispatch({
      type:1, 
      error : false, 
      errors : {
        station : "",
        origin_zone : "", 
        sales_region : "",
        client_id : "", 
        station_name : "", 
        country_code : "", 
        brand_code : "",
        entity_code : ""
      }})  

    store_stations.dispatch({
      type:1, 
      one_station : {
        id : null, 
        station: "",
        origin_zone : "", 
        sales_region : "",
        client_id : "", 
        station_name : "", 
        country_code : "", 
        brand_code : "",
        entity_code : ""
      }})  

  }
  
  render(){

   let show = store_stations.getState().updateModal;
   let one_station = store_stations.getState().one_station
   let errors = store_stations.getState().errors
 
    return(
      <Modal show={show}
      dialogClassName="modal-m"
   
      onHide={()=>{store_stations.dispatch({type:1,  updateModal : true})} }>
      <Modal.Header className="station_modal_header">
        <Modal.Title>
          <div>
            Update Station
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 

        <div class = 'row'    style = {this.modal_style}>
          <div class="col-6">
            <label > Station </label>
            <input 
              type="text" class= {"form-control " + (errors.station  ? "is-invalid" : "" )} 
              defaultValue={one_station.station} 
              maxLength = {3}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  station : e.target.value, stationUpdated: true})
              }} disabled />
            <div class ='error'>{errors.station  ? errors.station : ""}</div>
          </div>

          <div class="col-6">
            <label >Origin zone</label>
            <input type="text" class= {"form-control " + (errors.origin_zone  ? "is-invalid" : "" )}
              defaultValue={one_station.origin_zone} 
              maxLength = {5}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  origin_zone : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.origin_zone  ? errors.origin_zone : ""}</div>
          </div>

        </div>

        <div class = 'row'    style = {this.modal_style}>
          <div class="col-6">
            <label >Sales region</label>
            <input type="text" class= {"form-control " + (errors.sales_region  ? "is-invalid" : "" )}
              defaultValue={one_station.sales_region} 
              maxLength = {3}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  sales_region : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.sales_region  ? errors.sales_region : ""}</div>
          </div>

          <div class="col-6">
            <label >Client</label>
            <input type="text" class= {"form-control " + (errors.client_id  ? "is-invalid" : "" )}
              defaultValue={one_station.client_id} 
              maxLength = {3}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  client_id : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.client_id  ? errors.client_id : ""}</div>
          </div>

          <div class="col-6">
            <label >Name</label>
            <input type="text" class= {"form-control " + (errors.station_name  ? "is-invalid" : "" )}
              defaultValue={one_station.station_name} 
              maxLength = {50}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  station_name : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.station_name  ? errors.station_name : ""}</div>
          </div>

          <div class="col-6">
            <label >Country</label>
            <input type="text" class= {"form-control " + (errors.country_code  ? "is-invalid" : "" )}
              defaultValue={one_station.country_code} 
              maxLength = {2}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  country_code : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.country_code  ? errors.country_code : ""}</div>
          </div>
        </div>

        

        <div class = 'row'    style = {this.modal_style}>
          <div class="col-6">
            <label >Brand</label>
            <input type="text" class= {"form-control " + (errors.brand_code  ? "is-invalid" : "" )}
              defaultValue={one_station.brand_code} 
              maxLength = {50}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  brand_code : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.brand_code  ? errors.brand_code : ""}</div>
          </div>

          <div class="col-6">
            <label >Entity</label>
            <input type="text" class= {"form-control " + (errors.entity_code  ? "is-invalid" : "" )}
              defaultValue={one_station.entity_code} 
              maxLength = {4}
              onChange={(e)=>{ 
                store_stations.dispatch({type : 1, updateStation : true,  entity_code : e.target.value, stationUpdated: true})
              }}/>
            <div class ='error'>{errors.entity_code  ? errors.entity_code : ""}</div>
          </div>
        </div>
        <div class="row" style = {{textAlign : 'center'}}>

          <div class="col-4"></div>

            <div class="col-4">
       
              <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}}
                onClick={async (e)=>{ this.onClickUpdate(e)}}>Update
              </button>

              <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
                onClick={(e)=>{this.onClickClose(e)}}>
                  Close
              </button>

            </div>

            <div class="col-4"></div> 

        </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>      
    )
  }

}

class ModalDeleteStation extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }
  }
  
  componentDidMount(){
    
    let w = watch(store_stations.getState, 'deleteModal')
    store_stations.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }

  async onClickYes(e){
     
    let params = store_stations.getState().one_station
    

    if(params.id){
      store_stations.dispatch({type:1,  page_loading_spinner : true})

      params.delete = true

      
      let filters = store_filters.getState()
      let res = await Routes.get('/Stations', filters, true)

      store_stations.dispatch({type : 1, stations : res,  page_loading_spinner : false  }) 
      store_stations.dispatch({type:1,  deleteModal : false})  
      store_stations.dispatch({
        type:1, 
        one_station : {
          id : null, 
          station : "", 
          origin_zone : "", 
          sales_region : "",
          client_id : "", 
          station_name : "", 
          country_code : "", 
          brand_code : "",
          entity_code : ""
        }
      }) 
      this.setState({key : Date.now()})
      
    }
  }

  onClickNo(e){
    store_stations.dispatch({type:1,  deleteModal : false})  
    store_stations.dispatch({
      type:1, 
      one_station : {
        id : null,
        station : "", 
        origin_zone : "", 
        sales_region : "",
        client_id : "", 
        station_name : "", 
        country_code : "", 
        brand_code : "",
        entity_code : ""
      }
    })  
  }

  render(){

    let show = store_stations.getState().deleteModal;
    let one_station = store_stations.getState().one_station
    
    return(
      <Modal 
        show={show}
        dialogClassName="modal-m"
        onHide={()=>{store_stations.dispatch({type:1,  deleteModal : true})} }
      >
        <Modal.Header className="station_modal_header">
          <Modal.Title>
            <div>
              Do you want to delete this station ?
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Station</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.station} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Origin zone</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.origin_zone} disabled = {true}/>
            </div>
          </div>

          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Sales region</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.sales_region} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Client</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.client_id} disabled = {true}/>
            </div>
          </div>

          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Name</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.station_name} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Country</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.country_code} disabled = {true}/>
            </div>

          </div>


          <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Brand</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.brand_code} disabled = {true}/>
            </div> 

            <div class="col-6">
              <label >Entity</label>
              <input type="text" class= {"form-control"}  defaultValue={one_station.entity_code} disabled = {true}/>
            </div> 
          </div>

          <div class="row" style = {{textAlign : 'center'}}>
            <div class="col-4"></div>
            <div class="col-4">
              <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}}
                onClick={async (e)=>{this.onClickYes(e)}}>
                  Yes
              </button>

              <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
                onClick={(e)=>{this.onClickNo(e)}}>
                    No
              </button>
            </div>
            <div class="col-4"></div> 
          </div>
        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>      
    )
  }
}

class ModalConfirm extends React.Component{
 

  componentDidMount(){
    let w = watch(store_stations.getState, 'confirmModal')
    store_stations.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
    
  }
  
  async onClickYes(e){
    store_stations.dispatch({type : 1, page_loading_spinner : true})
    
    let params = store_stations.getState().one_station
    
    if(store_stations.getState().addModal){
      await Routes.post('/Station', params, true)
    }else if(store_stations.getState().updateModal){
      await Routes.post('/UpdateStation', params, true)
    }

    let filters = store_filters.getState()
    let res = await Routes.get("/Stations",filters, true)

    store_stations.dispatch({type : 1, stations : res }) 
    store_stations.dispatch({type : 1, page_loading_spinner : false})
    store_stations.dispatch({type:1, confirmModal : false})

    store_stations.dispatch({
      type:1, 
      one_station : {
        id : null, 
        station : "", 
        origin_zone : "", 
        sales_region : "",
        client_id : "", 
        station_name : "", 
        country_code : "", 
        brand_code : "",
        entity_code : ""
      }
    })

    
    store_stations.dispatch({type:1, addModal : false})
    store_stations.dispatch({type:1, updateModal : false})
    store_stations.dispatch({type:1, stationUpdated : false})

  }

  onClickNo(e){
    e.preventDefault();
    store_stations.dispatch({type:1, confirmModal : false})
    store_stations.dispatch({type:1, addModal : false})               
    store_stations.dispatch({type:1, updateModal : false})  
    store_stations.dispatch({type:1, stationUpdated : false})
  }

  render(){

    let show = store_stations.getState().confirmModal;

    return(
      <Modal show={show} onHide={()=>{}}>
        <Modal.Header className="station_modal_header">
          <Modal.Title style = {{textAlign : 'center', width : "100%"}}>
            <div >
              {store_stations.getState().addModal ? "Do you want to save?" : "Do you want to save changes?"}
            </div>      
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div class="row">
            <div class="col-2"></div>
              <button type="button" class="col-2 btn btn-success" 
                onClick={async (e)=>{this.onClickYes(e)}}>
                Yes
              </button>
                
              <div class="col-1"></div>
                
              <button type="button" class="col-2 btn btn-danger" 
                onClick={(e)=>{this.onClickNo(e)}}>
                No
              </button>

              <div class="col-1"></div>
                  
              <button type="button" class="col-2 btn btn-secondary" 
                onClick={(e)=>{store_stations.dispatch({type:1, confirmModal : false})}}>
                Cancel
              </button>
            </div>
          </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>      
    )
  }
}

class ExportModal extends React.Component{

  componentDidMount(){
    let w = watch(store_stations.getState, 'exportModal')
    store_stations.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
    
  }
  
  async download(e){
    let {export_type} = store_stations.getState()
    
    let stations = this.props.authorized_stations
    
    if(export_type === "csv"){
      exportStationstoCSV(stations);
      store_stations.dispatch({type:1, export : true})
    }else if(export_type === "excel"){
      exportStationstoExcel(stations)
    }else if(export_type === "json")
      exportStationstoJSON(stations)
    else
       return

    let logs = {
      type : "EXPORT_STATIONS",
      to : export_type
    }

    await Routes.get("/Logger",logs, true) 

    store_stations.dispatch({type : 1 , exportModal : false})
  }



  render(){

    let show = store_stations.getState().exportModal;

    return(
      <Modal show={show} onHide={()=>{}}>
        <Modal.Header className="station_modal_header">
          <Modal.Title style = {{textAlign : 'center', width : "100%"}}>
            Choose your Output
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div class = "row">           
            <div class = "col-1"></div>
            <div class = "col-10">
              <FormControl>
                <FormLabel id="row-radio-buttons-group-label">Export to :  </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  defaultValue="csv"
                  name="row-radio-buttons-group"
                  onClick={(e) => {store_stations.dispatch({type : 1, export_type : e.target.value})}}
                >
                  <FormControlLabel value="csv" control={<Radio />} label="CSV" />
                  <FormControlLabel value="excel" control={<Radio />} label="Excel" />
                  <FormControlLabel  value="json" control={<Radio />} label="JSON" />
                </RadioGroup>
              </FormControl>
            </div>
            <div class = "col-1"></div>
          </div>

          <br/>
            
          <div class="row">
              <div class="col-3"></div>
        
              <button type="button" class="col-2 btn btn-success" 
                onClick={async (e)=>{this.download(e)}}>
                Download
              </button>
                
              <div class="col-2"></div>
                                  
              <button type="button" class="col-2 btn btn-danger" 
                onClick={(e)=>{store_stations.dispatch({type:1, exportModal : false})}}>
                Close
              </button>

              <div class="col-3"></div>
          </div>
          
        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>      
    )
  }
}

class ExportButton extends React.Component{

  componentDidMount(){
    let w1 = watch(store_stations.getState, 'export')
    store_stations.subscribe(w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({key : Date.now()}, ()=>{
          document.getElementById("link_export").click()
        });
      }))

  }

  render(){
    let filename = "Quantum_Stations_" + util.convertDate(new Date(), false, ".") + ".csv"
    return (
      <a 
        id="link_export" 
        download = {filename}
        href={"data:text/csv;charset=UTF-8," + store_stations.getState().stations_csv }
        hidden
      >
      </a>
    )
  }
}


function checkFields(){
 
  let one_station = store_stations.getState().one_station

  store_stations.dispatch({
    type:1, 
    error : false, 
    errors : { 
      station : "", 
      origin_zone : "", 
      sales_region : "",
      client_id : "", 
      station_name : "", 
      country_code : "", 
      brand_code : "",
      entity_code : ""
    }
  })

  if(!one_station.station.trim()){
    store_stations.dispatch({type:1, error : true, station : "Missing"})
  }
  
  if(!one_station.sales_region.trim()){
    store_stations.dispatch({type:1, error : true, sales_region : "Missing"})
  }

  if(!one_station.client_id.trim()){
    store_stations.dispatch({type:1, error : true, client_id : "Missing"})
  }

  if(!one_station.station_name.trim()){
    store_stations.dispatch({type:1, error : true, station_name : "Missing"})
  }

  if(!one_station.country_code.trim()){
    store_stations.dispatch({type:1, error : true, country_code : "Missing"})
  }

  if(!one_station.entity_code.trim()){
    store_stations.dispatch({type:1, error : true, entity_code : "Missing"})
  }


  return store_stations.getState().error
}

function exportStationstoCSV(stations = null) {
  
   var csv = "Station;OriginZone;SalesRegion;ClientId;Name;CountryCode;BrandCode;EntityCode";
   csv = csv +  "\n"
   
   store_stations.getState().stations_filtered.forEach((u)=>{
     csv = csv + 
     u.station  + ";" + 
     u.origin_zone + ";" + 
     u.sales_region + ";" + 
     u.client_id + ";" + 
     u.station_name + ";" + 
     u.country_code + ";" + 
     u.brand_code + ";" +
     u.entity_code + ";" 
     csv = csv +  "\n"
   });
   store_stations.dispatch({type:1, stations_csv : csv })
 
}

function  exportStationstoExcel(stations = null){


  let {stations_filtered} = store_stations.getState()
  stations_filtered = stations_filtered ? stations_filtered : []

  let columns = [
    "Station",
    "Origin zone",
    "Sales region",
    "Client Id",
    "Name",
    "Country Code",
    "Brand Code",
    "Entity Code"
  ]    

  let data = []

  stations_filtered.forEach((u)=>{
    

    data.push([
      u.station,
      u.origin_zone,
      u.sales_region,
      u.client_id,
      u.station_name,
      u.country_code,
      u.brand_code,
      u.entity_code
    ])
   
  });

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('STATIONS');
  worksheet.getColumn("B").width = 20
  worksheet.getColumn("C").width = 20
  worksheet.getColumn("D").width = 20
  worksheet.getColumn("E").width = 20
  worksheet.getColumn("F").width = 20
  worksheet.getColumn("G").width = 20
  worksheet.getColumn("H").width = 30
  worksheet.getColumn("I").width = 20
 

  worksheet.addTable({
    name: 'StationsTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: false,
    },
    columns: columns.map((c) => {return {name : c, filterButton  : true } }) ,
    rows:data,
  });

//FORMATTAGE
  worksheet.getRow(1).eachCell((cell) => {
  cell.border= {
    top: {style:'thin', color:"€"},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
  };
  cell.fill = {
    type: 'pattern',
    pattern:'darkVertical',
    bgColor:{argb:'ff1e3194'},
    fgColor:{argb:'ff1e3194'}    
  };
  cell.font = {
    name: 'Arial Black',
    color: { argb: 'FFFFFFFF' },
    family: 2,
    size: 11,
  } 
});

// save under export.xlsx
  workbook.xlsx.writeBuffer('STATIONS.xlsx').then(function (data) {
    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    let filename = "Quantum_Stations_" + util.convertDate(new Date(), false, ".") + ".xlsx"
    saveAs(blob, filename);
  });




 

}

function  exportStationstoJSON(stations = null){

  let {stations_filtered} = store_stations.getState()
  stations_filtered = stations_filtered ? stations_filtered : []

  let data = []

  stations_filtered.forEach((u)=>{

    let one_station = {
      Station :  u.station,
      OriginZone :  u.origin_zone,
      SalesRegion :  u.sales_region,
      ClientId : u.client_id,
      Name : u.station_name,
      CountryCode : u.country_code,
      BrandCode : u.brand_code,
      EntityCode : u.entity_code
    }

    data.push(one_station)
   
  });

    var blob = new Blob([JSON.stringify(data)], {type: "application/json"});
    let filename = "Quantum_Stations_" + util.convertDate(new Date(), false, ".") + ".json"
    saveAs(blob, filename);

} 
