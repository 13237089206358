/////////// MSAL
import * as Msal from "msal";
import conf from '../config.js'
var config = conf.config ;

////////////// GRAPH CONFIG
// Add here the endpoints for MS Graph API services you would like to use.
// const graphConfig = {
//   graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
//   graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages"
// };

 
////////////// authCOnfig
const msalConfig = {
  auth: {
    clientId:  "9b086e01-97b6-4c31-b110-be88c3a48806", // new : "d09e0485-e9fd-4d17-9969-dd5916e3559f" 
    authority:"https://login.microsoftonline.com/0ec012a5-6e77-4428-bfde-1e3a7cebfcd8"  , //new  "https://login.microsoftonline.com/3c84a2f8-1248-4a7f-b35e-348e723b6dd4"
    redirectUri: config.front_host,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};  
  
// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

// Add here scopes for access token to be used at MS Graph API endpoints.
// const tokenRequest = {
//   scopes: ["Mail.Read"]
// };

///////////// UI
// Select DOM elements to work with

// const mailButton = document.getElementById("readMail");
// const profileButton = document.getElementById("seeProfile");
const profileDiv = document.getElementById("profile-div");


// function updateUI(data, endpoint) {
//   console.log('Graph API responded at: ' + new Date().toString());

//   if (endpoint === graphConfig.graphMeEndpoint) {
//     const title = document.createElement('p');
//     title.innerHTML = "<strong>Title: </strong>" + data.jobTitle;
//     const email = document.createElement('p');
//     email.innerHTML = "<strong>Mail: </strong>" + data.mail;
//     const phone = document.createElement('p');
//     phone.innerHTML = "<strong>Phone: </strong>" + data.businessPhones[0];
//     const address = document.createElement('p');
//     address.innerHTML = "<strong>Location: </strong>" + data.officeLocation;
//     profileDiv.appendChild(title);
//     profileDiv.appendChild(email);
//     profileDiv.appendChild(phone);
//     profileDiv.appendChild(address);
    
//   } else if (endpoint === graphConfig.graphMailEndpoint) {
//       if (data.value.length < 1) {
//         alert("Your mailbox is empty!")
//       } else {
//         const tabList = document.getElementById("list-tab");
//         tabList.innerHTML = ''; // clear tabList at each readMail call
//         const tabContent = document.getElementById("nav-tabContent");

//         data.value.map((d, i) => {
//           // Keeping it simple
//           if (i < 10) {
//             const listItem = document.createElement("a");
//             listItem.setAttribute("class", "list-group-item list-group-item-action")
//             listItem.setAttribute("id", "list" + i + "list")
//             listItem.setAttribute("data-toggle", "list")
//             listItem.setAttribute("href", "#list" + i)
//             listItem.setAttribute("role", "tab")
//             listItem.setAttribute("aria-controls", i)
//             listItem.innerHTML = d.subject;
//             tabList.appendChild(listItem)
    
//             const contentItem = document.createElement("div");
//             contentItem.setAttribute("class", "tab-pane fade")
//             contentItem.setAttribute("id", "list" + i)
//             contentItem.setAttribute("role", "tabpanel")
//             contentItem.setAttribute("aria-labelledby", "list" + i + "list")
//             contentItem.innerHTML = "<strong> from: " + d.from.emailAddress.address + "</strong><br><br>" + d.bodyPreview + "...";
//             tabContent.appendChild(contentItem);
//           }
//         });
//       }
//   }
// }

//////////// GRAPH
/** */
// function callMSGraph(endpoint, token, callback) {
//   const headers = new Headers();
//   const bearer = `Bearer ${token}`;

//   headers.append("Authorization", bearer);

//   const options = {
//       method: "GET",
//       headers: headers
//   };

//   console.log('request made to Graph API at: ' + new Date().toString());
  
//   fetch(endpoint, options)
//     .then(response => response.json())
//     .then(response => callback(response, endpoint))
//     .catch(error => console.log(error))
// }


// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new Msal.UserAgentApplication(msalConfig);

export default {
  
  signIn : async function() {
    return await myMSALObj.loginPopup(loginRequest)
      .then(loginResponse => {
        console.log('id_token acquired at: ' + new Date().toString());
        console.log(loginResponse);
        

        return myMSALObj.getAccount()
        //if (myMSALObj.getAccount())  showWelcomeMessage(myMSALObj.getAccount());
          
        
        
      }).catch(error => {
        console.log(error);
      });
  }

  ,

  signOut : function() {
    myMSALObj.logout();
  }

}



// function getTokenPopup(request) {
//   return myMSALObj.acquireTokenSilent(request)
//     .catch(error => {
//       console.log(error);
//       console.log("silent token acquisition fails. acquiring token using popup");
          
//       // fallback to interaction when silent call fails
//         return myMSALObj.acquireTokenPopup(request)
//           .then(tokenResponse => {
//             return tokenResponse;
//           }).catch(error => {
//             console.log(error);
//           });
//     });
// }

// function seeProfile() {
//   if (myMSALObj.getAccount()) {
//     getTokenPopup(loginRequest)
//       .then(response => {
//         callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
//         profileButton.classList.add('d-none');
//         mailButton.classList.remove('d-none');
//       }).catch(error => {
//         console.log(error);
//       });
//   }
// }

// function readMail() {
//   if (myMSALObj.getAccount()) {
//     getTokenPopup(tokenRequest)
//       .then(response => {
//         callMSGraph(graphConfig.graphMailEndpoint, response.accessToken, updateUI);
//       }).catch(error => {
//         console.log(error);
//       });
//   }
// }
