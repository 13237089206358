import Cookies from 'universal-cookie';

var cookies = new Cookies();

cookies = {
  first_name : cookies.get('first_name'),
  last_name : cookies.get('last_name'),
  user : cookies.get('user') ,
  station : cookies.get('station'),
  entity : cookies.get('entity'),
  region : cookies.get('region'),
  email_contact : cookies.get('email_contact'),
  account : cookies.get('account'),
  rights : cookies.get('rights'),
  role : cookies.get('role'),
  default_hq : cookies.get('default_hq'),
  user_carriers : cookies.get('user_carriers'),
  user_except_carriers : cookies.get('user_except_carriers'),
  sales_region : cookies.get('sales_region'),
  origin_zone : cookies.get('origin_zone'),
  analytics_filters : cookies.get('analytics_filters'),
  last_login : cookies.get('last_login'),
  home_release_display : cookies.get('home_release_display'),
  release_expired_time : cookies.get('release_expired_time'),
  gdpr_condition : cookies.get('gdpr_condition')
}


export default cookies