import React from "react";
import { Backdrop, CircularProgress, FormControlLabel, Checkbox, FormControl, FormLabel,RadioGroup, Radio} from '@material-ui/core';
import { createStore } from 'redux'
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import watch from 'redux-watch'
import '../styles/Home.css'
import Util from '../util.js'
import Nav_State from '../states/Nav_State.js'
import '../styles/Nav.css'
import cookies from "../Cookies";
import AccountCircle from '@material-ui/icons/AccountCircle'

var store_nav = createStore(Nav_State.reducer_nav)
 
class Main extends React.Component {
constructor(props) {
    super(props);  
    this.state = {
      key : Date.now()
    };
  }

async componentDidMount() {
  store_nav.subscribe(()=>{
    this.setState({key:Date.now()})
  })
  
  let current_url = window.location.hash.replace("#", "")
  store_nav.dispatch({type:1, current_url : current_url})
  window.addEventListener('hashchange', function(){
   store_nav.dispatch({type:1,current_url : window.location.hash.replace("#", "") })
  })
}

render() {
  
  let current_url = store_nav.getState().current_url;
  current_url =  window.location.hash.replace("#", "")
  const {queued_quotes} = this.props

  let role = ""

  if(cookies.role === Util.USER_ROLES.ADMIN) 
    role = "Administrator"
  else if(cookies.role === Util.USER_ROLES.STATION_ADMIN ) 
    role = "Station Admin"
  else if(cookies.role === Util.USER_ROLES.SALES_STD) 
    role = "Sales STD"
  else if(cookies.role === Util.USER_ROLES.SALES_LITE) 
    role = "Sales Lite"
  else if(cookies.role === Util.USER_ROLES.OPS_LITE) 
    role = "Ops Lite"
  else if(cookies.role === Util.USER_ROLES.OPS_STD) 
    role = "Ops STD"
  
  return (
    <div class="row col main-nav" key={this.state.key} >

      <ul class="nav nav-tabs ">
        <li class="nav-item">
          <a 
            class={"nav-link " + (current_url === "/" ? "current_button" : "")}
            onClick={(e)=> store_nav.dispatch({type:1, current_url : "/"})}
            href="#/"
          >Home
          </a>
        </li>
        
        {this.props.loggedIn ?
        [
          <li class="nav-item">
            <a 
              class={"nav-link " + (current_url === "/List" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/List"})}
              href="#/List" 
            >
              List{queued_quotes !== 0 ?  < span style = {{marginLeft : '1em'}} class="badge badge-purple"> {queued_quotes} </span> : null }
            </a>
          </li>
          ,
          cookies.rights && cookies.rights.quote_view ? 
          <li class="nav-item">
            <a 
              class={"nav-link " + (current_url.substring(0,7) === "/Editor" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Editor"})}
              href="#/Editor">
                Quote
            </a>
          </li>
          : 
          <li></li>
          ,
          cookies.rights && cookies.rights.analytics_view ?
          <li class="nav-item">
            <a 
              class={"nav-link " + (current_url === "/Analytics" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Analytics"})}
              href="#/Analytics"
            >
              Analytics
            </a>
          </li>
          :
          <li></li>
          ,
          cookies.rights && cookies.rights.rates_view ?
          
            <li class="nav-item">
            <a 
              class={"nav-link " + (current_url === "/Rates" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Rates"})}
              href="#/Rates"
            >
              Rates
            </a>
          </li> 
          
          : 

          <li></li>
 
          ,
 
         
          cookies.rights && cookies.rights.users_view ?
          <li class="nav-item">
            <a 
              class={"nav-link " + (current_url === "/Users" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Users"})}
              href="#/Users"
            >
                Users
            </a>
          </li>
          :
         <li></li>
         ,

         cookies.rights && cookies.rights.stations_view ?
         <li class="nav-item">
           <a 
             class={"nav-link " + (current_url === "/Stations" ? "current_button" : "")} 
             onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Stations"})}
             href="#/Stations"
           >
               Stations
           </a>
         </li>
         :
        <li></li>,

        cookies.rights && cookies.rights.settings_view ?
   
        <li class="nav-item">
          <a 
            class={"nav-link " + (current_url === "/Settings" ? "current_button" : "")} 
            onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Settings"})}
            href="#/Settings">
              Settings
          </a>
        </li>
        :
        <li></li>
        
      
        ]
        :
        null
        }

        <li class="nav-item">
          <a 
            class={"nav-link " + (current_url === "/Help" ? "current_button" : "")} 
            onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Help"})}
            href="#/Help"
          >
            Help 
          </a>
        </li>

      </ul >
        
      {this.props.loggedIn  &&
        <ul class="nav nav-tabs station-info" style={{background:"none"}}>
          
          <li class="nav-item">
            <p style={{margin : "1em"}}><strong>Default Station :</strong> {cookies.station} ({cookies.sales_region})  |  <strong>Role : </strong> {role} {cookies.region === "WLD" ? " - HQ" : ""} </p>
          </li>

            <li class="nav-item">
            <a 
              class={"nav-link " + (current_url === "/Profile" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Profile"})}
              href="#/Profile"
            >
              <AccountCircle/> 
            </a>
          </li>

        </ul>
      }

     
    
    </div> 
  )
}
}

class ModalDeleteUser extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }
  }
  
  componentDidMount(){
    
    let w = watch(store_nav.getState, 'deleteModal')
    store_nav.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }




  render(){

   let show = store_nav.getState().deleteModal;
   let user = {}
    
  return(
    <Modal 
      show={show}
      dialogClassName="modal-m"
      onHide={()=>{store_nav.dispatch({type:1,  deleteModal : false})} }
    >
      <Modal.Header className="user_modal_header">
        <Modal.Title>
          <div>
            Do you want to delete this user ?
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      
        <div class = 'row'  style = {this.modal_style}>

            <div class="col-6">
              <label >Entity</label>
              <input type="text" class= {"form-control"}  defaultValue={""} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Stations</label>
              <input type="text" class= {"form-control"}  defaultValue={""} disabled = {true}/>
            </div>

        </div>

        <div class = 'row'  style = {this.modal_style}>


            <div class="col-6">
              <label >First Name</label>
              <input type="text" class= {"form-control"} defaultValue={""} disabled ={true} />
            </div>

            <div class="col-6">
              <label >Last Name</label>
              <input type="text" class= {"form-control"} defaultValue={""} disabled = {true}/>
            </div>


        </div>

        <div class = 'row'  style = {this.modal_style}>

            <div class="col-6">
              <label >Account</label>
              <input type="text" class= {"form-control"} defaultValue={""} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Email Contact</label>
              <input type="text"class= {"form-control"} defaultValue={""} disabled = {true}/>
            </div>

        </div>

        <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Allowed Carriers</label>
              <input type="text" class= {"form-control"}  defaultValue={""} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Denied Carriers</label>
              <input type="text" class= {"form-control"}  defaultValue={""} disabled = {true}/>
            </div>
        </div>

        <div class = 'row'  style = {this.modal_style}>

            <div class="col-10">
              <label >Role</label>
              <input type="text"class= {"form-control"} defaultValue={""} disabled = {true}/>
            </div>


            <div class="col-2" style={{textAlign : "center" }}>
              <FormControlLabel
                value="bottom"
                control = {        
                  <Checkbox
                    defaultChecked = {true}
                    disabled = {true}
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                }
                label={"Discovery"}
                labelPlacement="top"
              />
            </div>

        </div>
        
        <div class = 'row'    style = {this.modal_style}>

          <div class="col-3">
            <label >Date Creation</label>
            <input 
              type="text" class= "form-control"
              disabled 
              defaultValue={user.date_created ? Util.dateToString(new Date(user.date_created)) : ""} 
            />
          </div>

          
          <div class="col-3">
            <label >Last Update</label>
            <input 
              type="text" 
              class= "form-control" 
              disabled
              defaultValue={user.date_updated ? Util.dateToString(new Date(user.date_updated)) : ""} 
            />
          </div>

          <div class="col-3">
            <label >First Login</label>
            <input 
              type="text" 
              class= "form-control"
              disabled
              defaultValue={user.first_login ? Util.dateToString(new Date(user.first_login)) : "Never"} 
            />
          </div>

          <div class="col-3">
            <label >Last Login</label>
            <input 
              type="text" 
              class= "form-control"
              disabled
              defaultValue={user.last_login ? Util.dateToString(new Date(user.last_login)) : "Never"} 
            />
          </div>

        </div>
        
        <div class="row" style = {{textAlign : 'center'}}>

          <div class="col-4"></div>
            
          <div class="col-4">
       
            <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}}
              onClick={async (e)=>{}}>
                Yes
            </button>

            <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
              onClick={(e)=>{}}>
                  No
            </button>

          </div>

          <div class="col-4"></div> 

        </div>
        
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>      
    )

  }

}
 
export default connect(
  (state) => ({
    configuration: state.configuration, 
    authorized_stations : state.authorized_stations, 
    loggedIn : state.loggedIn, 
    queued_quotes : state.queued_quotes
  })
)(Main)