import React from "react";
import watch from "redux-watch";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import GavelIcon from "@material-ui/icons/Gavel";
import EmailIcon from "@material-ui/icons/Email";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Help from "@material-ui/icons/Help";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteForever";
import Person from "@material-ui/icons/Person";
import ReactTooltip from "react-tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { SketchPicker } from "react-color";
import conf from "../config.js";
import Routes from "../Routes.js";
import util from "../util.js";
import "../styles/Settings.css";
import Settings_State from "../states/Settings_State.js";
import cookies from "../Cookies.js";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import ColorizeIcon from "@material-ui/icons/Colorize";

const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration: false, // print the duration of each action?
  timestamp: true, // print the timestamp with each action?

  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.

  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.

  logger: console, // implementation of the `console` API.
  logErrors: true, // should the logger catch, log, and re-throw errors?

  diff: false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
});

var config = conf.config;
var store_settings = createStore(
  Settings_State.reducer_settings,
  applyMiddleware(logger)
);

const OPERS = ["Flight", "Truck", "Flight/Truck"]


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loadTry: 0,
    };
  }

  async componentDidMount() {
    document.title = "Quantum - Settings";

    let w1 = watch(store_settings.getState, "custom_list");
    store_settings.subscribe(
      w1((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async load() {
    let { airline_portfolio, custom_list } = store_settings.getState();

 
    if (this.state.loadTry > 3 || this.state.loaded) return;

    if (!this.props.custom_list) {
      this.setState({ loadTry: this.state.loadTry + 1 });
    } else {
      custom_list = await Routes.get("/Custom", {}, true);

     
      custom_list = custom_list? custom_list.customs? custom_list.customs : []: [];
      let url_params = util.parseUrl(window.location.href);
      let default_custom = custom_list.filter((c) =>c.station === cookies.station && c.carrier === (url_params.carrier ? url_params.carrier : "***"))[0];

      if (!default_custom){
        default_custom = custom_list.filter((c) =>c.station === "***" && c.carrier === "***")[0]
     }
     
      store_settings.dispatch({
        type: 1,
        custom_list: custom_list,
        current_station: default_custom.station,
        current_carrier: default_custom.carrier,
        current_config: default_custom.config ? default_custom.config: undefined,
        default_config: default_custom,
        logo: default_custom.config ? default_custom.config.logo : undefined,
      });


      if (!airline_portfolio) {
        let station = default_custom.station ? default_custom.station : "***" ;
        let carrier = default_custom.carrier && default_custom.carrier !== "***" ? default_custom.carrier : null;
        let airline_portfolio = await Routes.get("/AirlinePortfolio",{ station: station, carrier: carrier },true);
        store_settings.dispatch({
          type: 1,
          airline_portfolio: airline_portfolio ? airline_portfolio.data : [],
        });
      }

      this.setState({ loaded: true });
    }
  }

  render() {
    if (!this.props.loggedIn || !cookies.rights || (cookies.rights && !cookies.rights.settings_view)) {
      return window.location.replace(config.front_host);
    }

    if (!this.state.loaded) {
      this.load();
      return null;
    }

    store_settings.dispatch({
      type: 1,
      carriers_list: this.props.carriers_list ? this.props.carriers_list : [],
    });

    return (
      // <div key = {this.state.key} >
      <div>
        <div class="container-fluid background">
          <FilterMenu
            authorized_stations={this.props.authorized_stations}
            carriers_list={this.props.carriers_list}
            update_custom={this.props.update_custom}
          />
          <ConfigForm authorized_stations={this.props.authorized_stations} />
          <br />
        </div>
        <PageLoadingSpinner />
      </div>
    );
  }
}

class FilterMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
    };
  }
  componentDidMount() {
    let w2 = watch(store_settings.getState, "current_station");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_settings.getState, "current_carrier");
    store_settings.subscribe(
      w3((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onChangeStation(e, ss) {

    let { custom_list, default_config, current_carrier } = store_settings.getState();

    if (!ss) return;

    let new_config = custom_list.filter((c) =>c.station === ss.station && c.carrier.replace(" ", "") === current_carrier.replace(" ", ""))[0];
  

    let update_by = new_config ? new_config.update_by : null;
    let date_updated = new_config ? new_config.date_updated : null;

    if(!new_config && default_config && ss){
      let config = default_config.config
        config.sales_region = ss.sales_region
        config.country = ss.country_code
        default_config.config = config
    }

    store_settings.dispatch({
      type: 1,
      current_station: ss.station,
      country_code: ss.country_code,
      current_config: !new_config ? default_config.config : new_config.config,
      gha_info: new_config && new_config.gha_info ? new_config.gha_info : [],
      update_by: update_by,
      date_updated: date_updated,
      reset_all_sales_conditions: false,
    });


    let station = new_config ? new_config.station : "***";
    let carrier = new_config && new_config.carrier !== "***" ? new_config.carrier.replace(" ", "") : null;

    let airline_portfolio = await Routes.get("/AirlinePortfolio",{ station: station, carrier: carrier },true);

    store_settings.dispatch({
      type: 1,
      airline_portfolio: airline_portfolio ? airline_portfolio.data : [],
    });
  }

  async onChangeCarrier(e, ss) {
    let { custom_list, current_station, default_config } = store_settings.getState();

    if (!ss) return;

    let new_config = custom_list.filter((c) => c.station === current_station && c.carrier.replace(" ", "") === ss.code)[0];

    let update_by = new_config ? new_config.update_by : null;
    let date_updated = new_config ? new_config.date_updated : null;



    store_settings.dispatch({
      type: 1,
      current_carrier: ss.code,
      current_config: !new_config ? default_config.config : new_config.config,
      update_by: update_by,
      date_updated: date_updated,
      gha_info: new_config && new_config.gha_info ? new_config.gha_info : [],
      reset_all_sales_conditions: false,
    });

    if (!new_config) {
      new_config = custom_list.filter((c) => c.station === current_station && c.carrier.replace(" ", "") === "***")[0];
    }

    let station = new_config ? new_config.station : "***";
    let carrier =new_config && new_config.carrier !== "***" ? new_config.carrier.replace(" ", "") : null;

    let airline_portfolio = await Routes.get("/AirlinePortfolio",{ station: station, carrier: carrier },true);

    store_settings.dispatch({
      type: 1,
      airline_portfolio: airline_portfolio ? airline_portfolio.data : [],
    });

    window.history.pushState(
      {},
      document.title,
      config.front_host + "/#/Settings?carrier=" + ss.code
    );
  }

  async onSave(e) {
    e.preventDefault();

    if (errors()) return;

    let settings_state = store_settings.getState();

    store_settings.dispatch({ type: 1, loadingSpinner: true });

    let params1 = {
      station: settings_state.current_station,
      carrier: settings_state.current_carrier,
      config: { ...settings_state.current_config },
      reset_all_sales_conditions: settings_state.reset_all_sales_conditions,
      
      gha_info : settings_state.gha_info
    };

    params1.config.logo = null;

    await Routes.post("/Custom", params1, true);

    let params2 = {
      station: settings_state.current_station,
      carrier: settings_state.current_carrier,
      logo: settings_state.current_config.logo,
    };

    await Routes.post("/CustomLogo", params2, true);

    setTimeout(ReactTooltip.hide, 1000);

    let custom_list = await Routes.get("/Custom", {}, true);
    custom_list = custom_list
      ? custom_list.customs
        ? custom_list.customs
        : []
      : [];


    this.props.update_custom(custom_list);
    store_settings.dispatch({ type: 1, custom_list });
    store_settings.dispatch({ type: 1, loadingSpinner: false });
  }

  render() {

    let settings_state = store_settings.getState();
    let default_config = settings_state.default_config;
    let station_list = this.props.authorized_stations;

    let update_by = settings_state && settings_state.update_by ? settings_state.update_by : null;
    update_by = update_by ? update_by : default_config.update_by;

    let date_updated = settings_state && settings_state.date_updated ? settings_state.date_updated : null;
    date_updated = date_updated ? date_updated : default_config.date_updated;

    if (!station_list.filter((s) => s.station === "***")[0])
      station_list.push({
        country_code: "",
        station: "***",
        station_name: "Default",
      });

    let carriers_list = this.props.carriers_list;
    if (!carriers_list.filter((s) => s.code === "***")[0])
      carriers_list.push({ code: "***", name: "Default" });

    let default_station = station_list.filter((s) => s.station === settings_state.current_station)[0];
    let default_carrier = carriers_list.filter((s) => s.code === settings_state.current_carrier)[0];

    let save_auth =
      cookies.rights &&
      cookies.rights.settings_view &&
      !(
        settings_state.current_station === "***" &&
        settings_state.current_carrier === "***" &&
        cookies.region !== "WLD"
      );

    return (
      <div class="row " key={this.state.key}>
        <div class="col-1"></div>

        <div class="col-2">
          <label>Station</label>

          <Autocomplete
            popupIcon={null}
            disableCloseOnSelect={false}
            id="checkboxes-tags-demo"
            class="form-group"
            fullWidth
            style={{ width: "100%" }}
            defaultValue={
              settings_state.current_station === "***"
                ? { country_code: "", station: "***", station_name: "Default" }
                : {
                    country_code: settings_state.country_code,
                    station: settings_state.current_station,
                    station_name: default_station.station_name,
                  }
            }
            options={station_list}
            getOptionLabel={(option) =>
              option.country_code +
              " - " +
              option.station_name +
              " - " +
              option.station
            }
            onChange={this.onChangeStation}
            renderInput={(params) => (
              <TextField
                class="form-control"
                style={{ height: "100%", marginBottom: 15 }}
                {...params}
                placeholder="Type"
                fullWidth
              />
            )}
          />
        </div>

        <div class="col-2">
          <label>Carrier</label>

          <Autocomplete
            popupIcon={null}
            disableCloseOnSelect={false}
            id="checkboxes-tags-demo"
            class="form-group"
            fullWidth
            style={{ width: "100%" }}
            defaultValue={
              settings_state.current_carrier === "***"
                ? { code: "***", name: "Default" }
                : {
                    code: settings_state.current_carrier,
                    name: default_carrier.name,
                  }
            }
            options={carriers_list}
            getOptionLabel={(option) => option.code + " - " + option.name}
            onChange={this.onChangeCarrier}
            renderInput={(params) => (
              <TextField
                class="form-control"
                style={{ height: "100%", marginBottom: 15 }}
                {...params}
                placeholder="Type"
                fullWidth
              />
            )}
          />
        </div>

        <div class="col-1">
          <button
            id = "btn-save-main"
            data-tip="Save"
            data-type="info"
            delay-hide={1000}
            type="button"
            class=" btn  btn-success centered "
            disabled={!save_auth}
            onClick={async (e) => {
              this.onSave(e);
            }}
          >
            <SaveIcon fontSize="small" />
            <ReactTooltip place="top" effect="solid" />
          </button>
        </div>

        <div class="col-1">
          <label>Last update</label>
          <p>
            {date_updated ? util.dateToString(new Date(date_updated)) : "-"}
          </p>
        </div>

        <div class="col-1">
          <label>Update by</label>
          <p>{update_by ? update_by : "-"}</p>
        </div>
      </div>
    );
  }
}

class ConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.airlineContentEditableRef = React.createRef();
    this.generalContentEditableRef = React.createRef();
    this.state = {
      key: Date.now(),
      list: [{ name: "EUR" }, { name: "USD" }, { name: "YEN" }],
      isBold_Airline: false,
      isHighlighted_Airline: false,
      isTextBold_Airline: false,
      isTextHighlighted_Airline: false,
      isBold_General: false,
      isHighlighted_General: false,
      isTextBold_General: false,
      isTextHighlighted_General: false,
    };
    this.handleLogo = this.handleLogo.bind(this);
    this.resetConfig = this.resetConfig.bind(this);
  }

  componentDidMount() {
    let w2 = watch(store_settings.getState, "current_station");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_settings.getState, "current_carrier");
    store_settings.subscribe(
      w3((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w4 = watch(store_settings.getState, "current_config.logo");
    store_settings.subscribe(
      w4((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w5 = watch(store_settings.getState, "current_config.quote_main_color");
    store_settings.subscribe(
      w5((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w6 = watch(store_settings.getState, "current_config.quote_font_color");
    store_settings.subscribe(
      w6((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w7 = watch(store_settings.getState, "refresh");
    store_settings.subscribe(
      w7((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w8 = watch(store_settings.getState, "change_config");
    store_settings.subscribe(
      w8((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    /* BOLD/HAIGHLIGHT
    document.removeEventListener('selectionchange', this.handleSelectionChange_General);
    */
  }

  componentWillUnmount() {
    /* BOLD/HAIGHLIGHT
    document.removeEventListener('selectionchange', this.handleSelectionChange_Airline)
    document.removeEventListener('selectionchange', this.handleSelectionChange_General);
    */
  }

  /* BOLD/HAIGHLIGHT 
  handleSelectionChange_Airline = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const parentElement = range.commonAncestorContainer.parentElement;
      const isBold_Airline = parentElement && parentElement.closest('b') !== null;
      const isHighlighted_Airline = document.queryCommandValue('backColor') === 'rgb(255, 255, 0)';
      this.setState({ isTextBold_Airline: isBold_Airline, isTextHighlighted_Airline: isHighlighted_Airline, isBold_Airline: isBold_Airline, isHighlighted_Airline: isHighlighted_Airline});    }
  }

  handleSelectionChange_General = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const parentElement = range.commonAncestorContainer.parentElement;
      const isBold_General = parentElement && parentElement.closest('b') !== null;
      const isHighlighted_General = document.queryCommandValue('backColor') === 'rgb(255, 255, 0)';
      this.setState({ isTextBold_General: isBold_General, isTextHighlighted_General: isHighlighted_General, isBold_General: isBold_General, isHighlighted_General: isHighlighted_General });
    }
  }
  */

  handleLogo(e) {
    try {
      let file = e.target.files[0];

      if (file.type.indexOf("image") < 0) return;

      var reader = new FileReader();

      if (reader.readAsDataURL) {
        reader.onload = function (e) {
          store_settings.dispatch({
            type: 1,
            logo: e.target.result,
            change_config: true,
          });
        };

        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log(error);
    }
  }

  resetConfig(e) {
    e.preventDefault();
    let { name } = e.target;

    let settings_state = store_settings.getState();

    let def = null;

    if (
      settings_state.current_station !== "***" &&
      settings_state.current_carrier !== "***"
    ) {
      def = settings_state.custom_list.filter(
        (c) =>
          c.station === settings_state.current_station && c.carrier === "***"
      )[0];
    } else if (
      settings_state.current_station !== "***" &&
      settings_state.current_carrier === "***"
    ) {
      def = settings_state.custom_list.filter(
        (c) => c.station === "***" && c.carrier === "***"
      )[0];
    }

    if (!def) return;

    if (name === "sales_conditions") {
      store_settings.dispatch({
        type: 1,
        sales_conditions: def.config.sales_conditions,
        change_config: true,
      });
    } else if (name === "airline_conditions") {
      store_settings.dispatch({
        type: 1,
        airline_conditions: def.config.airline_conditions,
        change_config: true,
      });
    } else if (name === "logo") {
      store_settings.dispatch({
        type: 1,
        logo: def.config.logo,
        change_config: true,
      });
    } else if (name === "color") {
      store_settings.dispatch({
        type: 1,
        quote_font_color: def.config.quote_font_color,
        quote_main_color: def.config.quote_main_color,
        change_config: true,
      });
    }

    this.setState({ key: Date.now() });
  }

  /* BOLD/HAIGHLIGHT 

  handleBoldButtonClick_Airline = () => {
    document.execCommand('bold', false, null);
    this.setState(prevState => ({ isBold_Airline: !prevState.isBold_Airline }));
  }
  
  handleHighlightButtonClick_Airline = () => {
    const currentColor = document.queryCommandValue('backColor');
    if (currentColor === 'rgb(255, 255, 0)') {
      document.execCommand('backColor', false, 'transparent');
    } else {
      document.execCommand('backColor', false, 'yellow');
    }
    this.setState(prevState => ({ isHighlighted_Airline: !prevState.isHighlighted_Airline }));
  }

  handleBoldButtonClick_General = () => {
    document.execCommand('bold', false, null);
    this.setState(prevState => ({ isBold_General: !prevState.isBold_General }));
  }
  
  handleHighlightButtonClick_General = () => {
    const currentColor = document.queryCommandValue('backColor');
    if (currentColor === 'rgb(255, 255, 0)') {
      document.execCommand('backColor', false, 'transparent');
    } else {
      document.execCommand('backColor', false, 'yellow');
    }
    this.setState(prevState => ({ isHighlighted_General: !prevState.isHighlighted_General }));
  }

  */

  render() {
    let settings_state = store_settings.getState();
    let errors_message = settings_state.errors_message? settings_state.errors_message : {};

    return (
      <div class="row settings-config" key={this.state.key}>
        <div class="col-1"></div>

        <div class="col-10">
          <ExpansionPanel
            defaultExpanded={settings_state.print_expand}
            onChange={() => expand("print_expand")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <h2>Quotation Print</h2>
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <div class="col">
                <div class="section-title">
                  <h4>
                    Airline Notes and Conditions <GavelIcon />{" "}
                  </h4>
                  <a
                    href="a"
                    name="airline_conditions"
                    onClick={this.resetConfig}
                  >
                    {settings_state.current_station !== "***" &&
                      settings_state.current_carrier !== "***" &&
                      " - reset to station default"}
                    {settings_state.current_station !== "***" &&
                      settings_state.current_carrier === "***" &&
                      " - reset to app default"}
                  </a>
                  {/* <button 
                      className={`bold-button ${this.state.isBold_Airline ? 'bold-active' : ''}`}
                      style = {{marginLeft : "10px"}} 
                      onClick={this.handleBoldButtonClick_Airline}>
                      <FormatBoldIcon/>
                    </button>
                    <button type="button" 
                      className={`highlighted-button ${this.state.isHighlighted_Airline ? 'highlighted-active' : ''}`}
                      style={{ marginLeft: "10px" }} 
                      onClick={this.handleHighlightButtonClick_Airline}>
                      <ColorizeIcon/>
                    </button> */}
                </div>

                {/* <div
                    className="editable-content"
                    aria-label="With textarea"
                    ref={this.airlineContentEditableRef}
                    contentEditable="true"
                    onChange={ (e)=>{store_settings.dispatch({type : 1, airline_conditions : e.target.value, change_config:true})}}
                    onBlur={(e) => {
                      store_settings.dispatch({ type: 1, airline_conditions: e.target.innerHTML, change_config: true });
                    }}
                    onMouseUp={this.handleSelectionChange_Airline}
                    dangerouslySetInnerHTML={{ __html: settings_state.current_config.airline_conditions }}
                    >
                  </div> */}

                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  maxlength="6000"
                  defaultValue={
                    settings_state.current_config.airline_conditions
                  }
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      airline_conditions: e.target.value,
                      change_config: true,
                    });
                  }}
                ></textarea>

                <br />
                <hr />

                <div class="section-title">
                  <h4>
                    General Notes and Conditions <GavelIcon />{" "}
                  </h4>
                  <a
                    href="a"
                    name="sales_conditions"
                    onClick={this.resetConfig}
                  >
                    {settings_state.current_station !== "***" &&
                      settings_state.current_carrier !== "***" &&
                      " - reset to station default "}
                    {settings_state.current_station !== "***" &&
                      settings_state.current_carrier === "***" &&
                      " - reset to app default "}
                  </a>

                  {/* <button 
                      className={`bold-button2 ${this.state.isBold_General ? 'bold-active' : ''}`}
                      style = {{marginLeft : "10px"}} 
                      onClick={this.handleBoldButtonClick_General}>
                      <FormatBoldIcon/>
                    </button>
                    <button type="button" 
                      className={`highlighted-button2 ${this.state.isHighlighted_General ? 'highlighted-active' : ''}`}
                      style={{ marginLeft: "10px" }} 
                      onClick={this.handleHighlightButtonClick_General}>
                      <ColorizeIcon/>
                    </button> */}

                  {settings_state.current_station !== "***" &&
                  settings_state.current_carrier === "***" ? (
                    <>
                      <FormControlLabel
                        style={{ marginLeft: "2em" }}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            color="primary"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                            defaultChecked={
                              settings_state.reset_all_sales_conditions
                            }
                            onChange={(e) => {
                              store_settings.dispatch({
                                type: 1,
                                reset_all_sales_conditions: e.target.checked,
                              });
                            }}
                          />
                        }
                      />{" "}
                      <span style={{ paddingTop: "1em", fontSize: "0.8em" }}>
                        Apply General Notes and Conditions to all airlines
                      </span>
                    </>
                  ) : null}
                </div>

                {/* <div
                    className="editable-content"
                    aria-label="With textarea"
                    contentEditable="true"
                    maxLength="5000"
                    ref={this.generalContentEditableRef}
                    onChange={(e) => {
                      store_settings.dispatch({
                        type: 1,
                        sales_conditions: e.target.textContent,
                        change_config: true
                      });
                    }}
                    onBlur={(e) => {
                      store_settings.dispatch({
                        type: 1,
                        sales_conditions: e.target.textContent,
                        change_config: true
                      });
                    }}
                    onMouseUp={this.handleSelectionChange_General}
                    dangerouslySetInnerHTML={{ __html: settings_state.current_config.sales_conditions }}
                  ></div> */}

                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  maxlength="5000"
                  defaultValue={settings_state.current_config.sales_conditions}
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      sales_conditions: e.target.value,
                      change_config: true,
                    });
                  }}
                ></textarea>

                <br />
                <hr />

                <h4>
                  User Name <Person />
                </h4>

                <FormControlLabel
                  value="bottom"
                  label="Display user name"
                  control={
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      defaultChecked={
                        settings_state.current_config.display_user_name
                      }
                      onChange={(e) => {
                        let current_config =
                          store_settings.getState().current_config;
                        let contact_email = current_config
                          ? current_config.contact_email
                          : null;

                        if (contact_email && contact_email === "user")
                          store_settings.dispatch({
                            type: 1,
                            display_user_name: true,
                            change_config: true,
                          });
                        else
                          store_settings.dispatch({
                            type: 1,
                            display_user_name: e.target.checked,
                            change_config: true,
                          });

                        this.setState({ key: Date.now() });
                      }}
                    />
                  }
                />

                <br />
                <hr />

                <h4>
                  Contact Email <EmailIcon />{" "}
                </h4>

                <FormControlLabel
                  value="bottom"
                  label="Do not display email"
                  control={
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      defaultChecked={
                        !settings_state.current_config.contact_email
                      }
                      onChange={(e) => {
                        store_settings.dispatch({
                          type: 1,
                          contact_email: null,
                          change_config: true,
                        });
                        this.setState({ key: Date.now() });
                      }}
                    />
                  }
                />

                <br />

                <FormControlLabel
                  value="bottom"
                  label="Display user email"
                  control={
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      defaultChecked={
                        settings_state.current_config.contact_email === "user"
                      }
                      onChange={(e) => {
                        store_settings.dispatch({
                          type: 1,
                          contact_email: "user",
                          display_user_name: true,
                          change_config: true,
                        });
                        this.setState({ key: Date.now() });
                      }}
                    />
                  }
                />

                <br />

                <FormControlLabel
                  value="bottom"
                  label="Display default contact email"
                  control={
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      defaultChecked={
                        settings_state.current_config.contact_email ===
                        "default"
                      }
                      onChange={(e) => {
                        store_settings.dispatch({
                          type: 1,
                          contact_email: "default",
                          change_config: true,
                        });
                        this.setState({ key: Date.now() });
                      }}
                    />
                  }
                />

                <br />

                <input
                  type="text"
                  class="col-3 form-control"
                  placeholder=" Type email address"
                  disabled={
                    settings_state.current_config.contact_email !== "default"
                  }
                  defaultValue={settings_state.current_config.default_contact}
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      default_contact: e.target.value,
                      change_config: true,
                    });
                  }}
                />
                <div class="error">
                  {errors_message && errors_message.default_contact
                    ? errors_message.default_contact
                    : ""}
                </div>

                <hr />

                <div class="section-title">
                  <h4>Logo</h4>
                  <a href="" onClick={this.resetConfig} name="logo">
                    {settings_state.current_station !== "***" &&
                      settings_state.current_carrier !== "***" &&
                      " - reset to station default "}
                    {settings_state.current_station !== "***" &&
                      settings_state.current_carrier === "***" &&
                      " - reset to app default "}
                  </a>
                </div>

                {settings_state.current_config.logo ? (
                  <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4">
                      <img
                        style={{ maxHeight: "20em", maxWidth: "30em" }}
                        src={settings_state.current_config.logo}
                        alt=" There is a problem displaying your logo"
                      />
                    </div>

                    <div class="col-4"></div>
                  </div>
                ) : (
                  <span>No logo provided</span>
                )}

                <br />

                <input
                  type="file"
                  id="hiddenfile"
                  name="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files[0])
                      document.getElementById("selectedfile").innerText =
                        e.target.files[0].name;
                    this.handleLogo(e);
                  }}
                />
                <button
                  type="button"
                  class="btn btn-success"
                  id="btn_upload"
                  onClick={(e) => {
                    document.getElementById("hiddenfile").click();
                  }}
                >
                  <img
                    style={{ marginRight: "0.5em" }}
                    src={require("../img/upload.png")}
                    alt=""
                  />{" "}
                  Upload your logo here
                </button>

                <label id="selectedfile" for="btn_upload"></label>

                <hr />

                <div class="row">
                  <div class="col-4">
                    <h4>
                      Template Theme Color <ColorLensIcon />{" "}
                    </h4>
                    <a href="" onClick={this.resetConfig} name="color">
                      {settings_state.current_station !== "***" &&
                        settings_state.current_carrier !== "***" &&
                        " - reset to station default "}
                      {settings_state.current_station !== "***" &&
                        settings_state.current_carrier === "***" &&
                        " - reset to app default "}
                    </a>

                    <SketchPicker
                      color={
                        settings_state.current_config.quote_main_color
                          ? settings_state.current_config.quote_main_color
                          : "#003457"
                      }
                      onChangeComplete={(e) => {
                        store_settings.dispatch({
                          type: 1,
                          quote_main_color: e.hex,
                          change_config: true,
                        });
                      }}
                    />
                  </div>

                  <div class="col-3">
                    <h5>
                      Font Color <FontDownloadIcon />{" "}
                    </h5>

                    <br />

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              settings_state.current_config.quote_font_color ===
                              "black"
                            }
                            name="black"
                            onChange={(e) =>
                              store_settings.dispatch({
                                type: 1,
                                quote_font_color: e.target.name,
                                change_config: true,
                              })
                            }
                          />
                        }
                        label="Black"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              settings_state.current_config.quote_font_color ===
                              "white"
                            }
                            name="white"
                            onChange={(e) =>
                              store_settings.dispatch({
                                type: 1,
                                quote_font_color: e.target.name,
                                change_config: true,
                              })
                            }
                          />
                        }
                        label="White"
                      />
                    </FormGroup>
                  </div>

                  <div class="col-3">
                    <h5>
                      Preview <VisibilityIcon />
                    </h5>
                    <br />
                    <div
                      style={{
                        padding: "1em",
                        textAlign: "center",
                        color: settings_state.current_config.quote_font_color,
                        backgroundColor:
                          settings_state.current_config.quote_main_color,
                      }}
                    >
                      Example
                    </div>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>


          <ExpansionPanel
            defaultExpanded={settings_state.pricing_expand}
            onChange={() => expand("pricing_expand")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <h2>Pricing</h2>
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <div class="col-6">
                <h4>Rate parameters</h4>
                Your rate will be automatically settled as :
                <select
                  class="form-control dropdown"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value === true || value === "true" ? true : false;
                    store_settings.dispatch({
                      type: 1,
                      all_in: value,
                      change_config: true,
                    });
                  }}
                >
                  <option
                    value={
                      !settings_state.current_config.all_in ||
                      settings_state.current_config.all_in === "false"
                        ? false
                        : true
                    }
                  >
                    {" "}
                    {!settings_state.current_config.all_in
                      ? "Net-Net"
                      : "All-in"}{" "}
                  </option>
                  {!settings_state.current_config.all_in ||
                  settings_state.current_config.all_in === "false" ? (
                    <option value={true}> {"All-in"} </option>
                  ) : (
                    <option value={false}> {"Net-Net"} </option>
                  )}
                </select>
              </div>

              <div class="col-6">
                <h4>Quotation Validity Period</h4>
                Days Validity :
                <input
                  class="form-control"
                  type="number"
                  defaultValue={
                    settings_state.current_config.quote_validity_period
                  }
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      quote_validity_period: e.target.value,
                      change_config: true,
                    });
                  }}
                />
                <div class="error">
                  {errors_message && errors_message.quote_validity_period
                    ? errors_message.quote_validity_period
                    : ""}
                </div>
              </div>

              
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            defaultExpanded={settings_state.booking_expand}
            onChange={() => expand("booking_expand")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <h2>Booking</h2>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div class="col">
                <h4>Booking Contact email</h4>
                This contact will receive FFR notifications :
                <input
                  class="form-control"
                  type="text"
                  placeholder="Type email address"
                  defaultValue={
                    settings_state.current_config.booking_contact_email
                  }
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      booking_contact_email: e.target.value,
                      change_config: true,
                    });
                  }}
                />
                <div class="error">
                  {errors_message && errors_message.booking_contact_email
                    ? errors_message.booking_contact_email
                    : ""}
                </div>
              </div>



       

              
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            defaultExpanded={settings_state.currency_expand}
            onChange={(e) => {
              store_settings.dispatch({
                type: 1,
                currency_expand: !settings_state.currency_expand,
              });
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <h2>Currency</h2>
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <div class="col">
                <h4>Default Currency</h4>

                <select
                  class="form-control dropdown"
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      default_currency: e.target.value,
                      change_config: true,
                    });
                  }}
                >
                  <option>
                    {settings_state.current_config.default_currency}{" "}
                  </option>
                  {util.CURRENCIES.map((curr) => {
                    if (curr !== settings_state.current_config.default_currency)
                      return <option>{curr}</option>;
                    else return null;
                  })}
                </select>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            defaultExpanded={settings_state.uld_expand}
            onChange={(e) => {
              store_settings.dispatch({
                type: 1,
                uld_expand: !settings_state.uld_expand,
              });
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <h2>ULD Pricing</h2>
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <div class="col">
                <ULDList />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <PortfolioPanel />

          <ModalUpdateAirline
            authorized_stations={this.props.authorized_stations}
          />
          <ModalAddAirline
            authorized_stations={this.props.authorized_stations}
          />
          <ModalConfirm />
          <ModalDelete />

          {
            settings_state.current_carrier !== "***" &&
            <GHAPanel />
          }

          <ModalUpdateGHA />

          <ModalAddGHA />

          <ModalConfirmGHA />
         
        </div>

        <div class="col-1"></div>
      </div>
    );
  }
}



class PortfolioPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    let w1 = watch(store_settings.getState, "airline_portfolio");
    store_settings.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_settings.getState, "force");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  onClickEdit(row) {
    let { carriers_list } = store_settings.getState();
    carriers_list = carriers_list ? carriers_list : [];
    let carrier = carriers_list.filter((c) => c.code === row.carrier)[0];
    let name = carrier ? carrier.name : "";
    let prefix = carrier ? carrier.prefix : "";

    let airline = {
      id: row.id ? row.id : 0,
      station: row.station,
      carrier: row.carrier,
      prefix: prefix,
      name: name,
      country: row.country,
      exception_airports: row.exception_airports
        ? row.exception_airports.join(",")
        : "",
      quantum_available: row.quantum_available ? row.quantum_available : 0,
      contact_emails: row.contact_emails ? row.contact_emails.join(";") : "",
    };

    let error = {
      id: null,
      station: "",
      carrier: "",
      prefix: "",
      name: "",
      country: "",
      exception_airports: "",
      quantum_available: 1,
      contact_emails: "",
    };
    store_settings.dispatch({
      type: 1,
      airline: airline,
      updateAirline: true,
      update_errors: error,
      updateAirlineModal: true,
      addAirline: false,
    });
  }

  onClickDelete(row) {
    let { carriers_list } = store_settings.getState();
    carriers_list = carriers_list ? carriers_list : [];
    let carrier = carriers_list.filter((c) => c.code === row.carrier)[0];
    let name = carrier ? carrier.name : "";
    let prefix = carrier ? carrier.prefix : "";

    let airline = {
      id: row.id ? row.id : 0,
      station: row.station,
      carrier: row.carrier,
      prefix: prefix,
      name: name,
      country: row.country,
      exception_airports: row.exception_airports
        ? row.exception_airports.join(",")
        : "",
      quantum_available: row.quantum_available ? row.quantum_available : 0,
      contact_emails: row.contact_emails ? row.contact_emails.join(";") : "",
    };

    store_settings.dispatch({ type: 1, deleteModal: true, airline: airline });
  }

  async onExport() {
    exportUserstoExcel();

    let logs = {
      type: "EXPORT_PORTOFOLIO",
    };

    await Routes.get("/Logger", logs, true);
  }

  render() {
    let { airline_portfolio, portfolio_expand, current_station } =
      store_settings.getState();
    const { key } = this.state;

    const airline_table = (
      <table class="table table-striped" key={key}>
        <thead>
          <tr>
            <th scope="col">
              <button
                disabled={
                  airline_portfolio && airline_portfolio.length ? false : true
                }
                type="button"
                class="btn btn-success"
                data-tip="Download"
                data-type="info"
                delay-hide={1000}
                onClick={(e) => this.onExport()}
              >
                <ReactTooltip place="top" effect="solid" delayHide={1000} />
                <GetAppIcon fontSize="small" />
              </button>{" "}
            </th>
            <th scope="col"> </th>
            <th scope="col"> </th>
            <th scope="col"> </th>
            <th scope="col"> </th>
            <th scope="col"></th>
            <th scope="col">
              {cookies.role && cookies.role === util.USER_ROLES.ADMIN && (
                <>
                  <button
                    type="button"
                    class="btn btn-success"
                    style={{ marginTop: "2em" }}
                    onClick={(e) => {
                      if (current_station === "***") return;
                      store_settings.dispatch({
                        type: 1,
                        airlines: [],
                        add_errors: [],
                        addAirlineModal: true,
                        addLineAirline: true,
                      });
                    }}
                    disabled={current_station === "***" ? true : false}
                  >
                    <AddCircleIcon fontSize="small" />
                  </button>

                  <Help
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                      marginTop: "0.8em",
                    }}
                    color="primary"
                    data-tip="Indicate which airlines should be available for e-quote request via e-booking platforms"
                    data-type="info"
                    delay-hide={1000}
                  />
                  <ReactTooltip place="top" effect="solid" delayHide={1000} />
                </>
              )}
            </th>
          </tr>

          <tr class="settings_portofolio_panel">
            <th scope="col"> Station </th>
            <th scope="col"> Country </th>
            <th scope="col"> Carrier </th>
            <th scope="col"> Exception Airports</th>
            <th scope="col"> Quantum Available </th>
            <th scope="col"> Contact Emails </th>
            <th scope="col">
              {" "}
              {cookies.role === util.USER_ROLES.ADMIN ? "Edit" : ""}
            </th>
          </tr>
        </thead>

        <tbody>
          {airline_portfolio.map((row) => {
            return (
              <tr>
                <td>{row.station}</td>
                <td>{row.country}</td>
                <td>{row.carrier}</td>
                <td>
                  {row.exception_airports
                    ? row.exception_airports.join(",")
                    : ""}
                </td>
                <td>{row.quantum_available ? "✔️" : "❌"}</td>
                <td>
                  {row.contact_emails ? row.contact_emails.join(",") : ""}
                </td>
                <td>
                  {cookies.role === util.USER_ROLES.ADMIN && (
                    <>
                      <button
                        class="btn btn-primary"
                        style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
                        onClick={(e) => {
                          this.onClickEdit(row);
                        }}
                      >
                        <Edit fontSize="small" />
                      </button>

                      <button
                        class="btn btn-danger"
                        style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
                        onClick={(e) => {
                          this.onClickDelete(row);
                        }}
                      >
                        <Delete fontSize="small" />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    if (!airline_portfolio) return null;
    else
      return (
        <ExpansionPanel
          defaultExpanded={portfolio_expand}
          onChange={() => expand("portfolio_expand")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <h2>E-quote Airline Portfolio</h2>
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>{airline_table}</ExpansionPanelDetails>
        </ExpansionPanel>
      );
  }
}

class ModalUpdateAirline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
      current_input: null,
    };
  }

  componentDidMount() {
    let w = watch(store_settings.getState, "updateAirlineModal");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_settings.getState, "change");
    store_settings.subscribe(
      w1((newVal, oldVal, objectPath) => {
        console.log("%s changed a from %s to %s", objectPath, oldVal, newVal);
        // if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_settings.getState, "update_error");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        console.log("%s changed a from %s to %s", objectPath, oldVal, newVal);
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );

    //W2 toDO
  }

  componentDidUpdate() {
    let input = document.getElementById(this.state.current_input);
    if (input) input.focus();
  }

  onChangeCarrier(e) {
    let input_carrier = e.target.value.toUpperCase().trim();
    let carrier_name = "";
    let carrier_prefix = "";

    if (input_carrier && input_carrier.length >= 2) {
      let { carriers_list } = store_settings.getState();
      let carrier = carriers_list.filter((c) => c.code === input_carrier)[0];
      if (carrier) {
        carrier_name = carrier.name;
        carrier_prefix = carrier.prefix;
      }
    } else {
      carrier_name = "";
      carrier_prefix = "";
    }

    store_settings.dispatch({
      type: 1,
      change: true,
      carrier: input_carrier,
      prefix: carrier_prefix,
      name: carrier_name,
    });
  }

  render() {
    let show = store_settings.getState().updateAirlineModal;
    let settings_state = store_settings.getState();
    let { current_station } = settings_state;
    let stations = this.props.authorized_stations
      ? this.props.authorized_stations
      : [];
    let country_code = stations.filter(
      (s) => current_station && s.station === current_station
    )[0].country_code;

    current_station = current_station ? current_station : "";
    country_code = country_code ? country_code : "";

    store_settings.dispatch({
      type: 1,
      change: true,
      station: current_station,
      country: country_code,
      country_code: country_code,
    });

    let { airline, update_errors } = store_settings.getState();

    return (
      <Modal
        show={show}
        dialogClassName="modal-m"
        onHide={() => {
          store_settings.dispatch({ type: 1, updateAirlineModal: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ width: "100%" }}>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4" style={{ textAlign: "center" }}>
                {"Update Airline"}
              </div>
              <div class="col-4"></div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-1">
              <label>Station</label>
              <input
                type="text"
                class={"form-control"}
                defaultValue={airline.station}
                disabled={true}
                id={"station_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
              <div class="error">{""}</div>
            </div>

            <div class="col-1">
              <label>Country</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.country}
                disabled={true}
                id={"country_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
              <div class="error">{""}</div>
            </div>

            <div class="col-1">
              <label>Issue carrier</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.carrier}
                maxLength={3}
                id={"carrier_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                onChange={(e) => {
                  this.onChangeCarrier(e);
                }}
              />
              <div class="error">
                {update_errors.carrier ? update_errors.carrier : ""}
              </div>
            </div>

            <div class="col-1">
              <label>AWB Prefix</label>
              <input
                type="text"
                placeholder=""
                class={"form-control"}
                defaultValue={airline.prefix ? airline.prefix : ""}
                disabled={true}
                id={"prefix_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
              <div class="error">{""}</div>
            </div>

            <div class="col-2">
              <label>Airline name</label>
              <input
                type="text"
                class={"form-control"}
                defaultValue={airline.name ? airline.name : ""}
                disabled={true}
                id={"carrier_name_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
            </div>

            <div class="col-2">
              <label>Origin airports to be excluded </label>
              <Help
                color="primary"
                data-tip='Format should be "airport code,airport code,airport code", e.g. ORY,CDG,BOD'
                data-type="info"
                delay-hide={1000}
              />

              <input
                type="text"
                class={"form-control"}
                id={"exception_airports_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={airline.exception_airports}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    change: true,
                    exception_airports: e.target.value,
                  });
                }}
              />
              <ReactTooltip place="top" effect="solid" delayHide={1000} />
              <div class="error">
                {update_errors.exception_airports
                  ? update_errors.exception_airports
                  : ""}
              </div>
            </div>

            <div class="col-3">
              <label>Contatcs</label>
              <Help
                color="primary"
                data-tip='Format should be "email1;email2;email3", e.g. example@int.domain;example@ext.domain'
                data-type="info"
                delay-hide={1000}
              />

              <input
                type="text"
                class={"form-control "}
                id={"contacts_u"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={airline.contact_emails}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    change: true,
                    contact_emails: e.target.value,
                  });
                }}
              />
              <ReactTooltip place="top" effect="solid" delayHide={1000} />
              <div class="error">
                {update_errors.contact_emails
                  ? update_errors.contact_emails
                  : ""}
              </div>
            </div>

            <div class="col-1">
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={airline.quantum_available ? true : false}
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    id={"quantum_available_u"}
                    onFocus={(e) => {
                      this.setState({ current_input: e.target.id });
                    }}
                    onBlur={(e) => {
                      this.setState({ current_input: null });
                    }}
                    onChange={(e) => {
                      store_settings.dispatch({
                        type: 1,
                        change: true,
                        quantum_available: e.target.checked,
                      });
                    }}
                  />
                }
                label={"Available"}
                labelPlacement="top"
              />
            </div>
          </div>

          <div class="row" style={{ textAlign: "center" }}>
            <div class="col-4"></div>
            <div class="col-4">
              <button
                type="button"
                class="col-2 btn btn-success"
                style={{ marginRight: "1em" }}
                id={"save_update_airline"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={async (e) => {
                  let update_error = checkUpdateFields();
                  let { airline } = store_settings.getState();

                  if (update_error) return;

                  if (!airline.station || !airline.carrier || !airline.country)
                    return;

                  store_settings.dispatch({
                    type: 1,
                    updateAirline: true,
                    addAirline: false,
                    airlines: [],
                    confirmModal: true,
                  });
                  this.setState({ key: Date.now() });
                }}
              >
                Save
              </button>

              <button
                type="button"
                class="col-2 btn btn-danger"
                style={{ marginLeft: "1em" }}
                id={"close_update_airline"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateAirlineModal: false,
                    updateAirline: false,
                    update_errors: [],
                  });
                }}
              >
                Close
              </button>
            </div>
            <div class="col-4"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalAddAirline extends React.Component {
  componentDidMount() {
    let w = watch(store_settings.getState, "addAirlineModal");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_settings.getState, "add_error");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        console.log("%s changed a from %s to %s", objectPath, oldVal, newVal);
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }
  render() {
    let show = store_settings.getState().addAirlineModal;


    return (
      <Modal
        show={show}
        dialogClassName="modal-m"
        onHide={() => {
          store_settings.dispatch({ type: 1, addAirlineModal: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ width: "100%" }}>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4" style={{ textAlign: "center" }}>
                Add new Airline
              </div>
              <div class="col-4"></div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-12">
              <AirlineList />
            </div>
          </div>

          <div class="row" style={{ textAlign: "center" }}>
            <div class="col-4"></div>

            <div class="col-4">
              <button
                type="button"
                class="col-2 btn btn-success"
                style={{ marginRight: "1em" }}
                id="save_airlines_liste"
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={async (e) => {
                  let add_error = checkAddFields();
                  if (add_error) return;
                  store_settings.dispatch({
                    type: 1,
                    addAirline: true,
                    updateAirline: false,
                    confirmModal: true,
                  });
                  this.setState({ key: Date.now() });
                }}
              >
                Save
              </button>

              <button
                type="button"
                class="col-2 btn btn-danger"
                style={{ marginLeft: "1em" }}
                id="close_airlines_liste"
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    addAirlineModal: false,
                    addAirline: false,
                    airlines: [],
                  });
                }}
              >
                Close
              </button>
            </div>

            <div class="col-4"></div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class AirlineList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
      current_input: null,
      newLine: null,
    };
  }

  componentDidMount() {
    let w = watch(store_settings.getState, "change");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        console.log(
          "%s airlines changed from %s to %s",
          objectPath,
          oldVal,
          newVal
        );
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {
    if (this.state.newLine) {
      let airlines = store_settings.getState().airlines;
      let input = document.getElementById("carrier_" + (airlines.length - 1));
      if (input) input.focus();
      this.setState({ newLine: null });
    } else {
      let input = document.getElementById(this.state.current_input);
      if (input) input.focus();
    }
  }

  onChangeCarrier(e, i) {
    let input_carrier = e.target.value.toUpperCase();
    let carrier_name = "";
    let carrier_prefix = "";

    if (input_carrier && input_carrier.length >= 2) {
      let carriers_list = store_settings.getState().carriers_list;

      let carrier = carriers_list.filter((c) => c.code === input_carrier)[0];
      if (carrier) {
        carrier_name = carrier.name;
        carrier_prefix = carrier.prefix;
      }
    } else {
      carrier_name = "";
      carrier_prefix = "";
    }
    store_settings.dispatch({
      type: 1,
      change: true,
      index: i,
      carrier: input_carrier,
      name: carrier_name,
      prefix: carrier_prefix,
    });
    this.setState({ key: Date.now() });
  }

  render() {
    let { airlines, add_errors } = store_settings.getState();
    airlines = airlines ? airlines : [];
    let nbLines = airlines.length ? airlines.length : 0;

    if (!nbLines) {
      store_settings.dispatch({ type: 1, addLineAirline: true });
      airlines = store_settings.getState().airlines;
      nbLines = airlines.length ? airlines.length : 0;
    }

    let html = [];

    for (let i = 0; i < nbLines; i++) {
      html.push(
        <div class="row">
          <div class="col-1">
            <input
              type="text"
              class="form-control "
              disabled={true}
              id={"station_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={airlines[i].station}
            />
          </div>

          <div class="col-1">
            <input
              type="text"
              class="form-control "
              disabled={true}
              id={"country_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={airlines[i].country}
            />
          </div>

          <div class="col-1">
            <input
              type="text"
              class="form-control "
              maxLength={3}
              id={"carrier_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={airlines[i].carrier}
              onChange={(e) => {
                this.onChangeCarrier(e, i);
              }}
            />
            <div class="error">
              {add_errors[i] && add_errors[i].carrier
                ? add_errors[i].carrier
                : ""}
            </div>
          </div>

          <div class="col-1">
            <input
              type="text"
              class="form-control "
              disabled={true}
              defaultValue={airlines[i].prefix}
              id={"prefix_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
            />
          </div>

          <div class="col-2">
            <input
              type="text"
              class="form-control "
              disabled={true}
              defaultValue={airlines[i].name}
              id={"carrier_name_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
            />
          </div>

          <div class="col-2">
            <input
              type="text"
              class="form-control "
              id={"exception_airports_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={airlines[i].exception_airports}
              onChange={(e) => {
                let airports = e.target.value;
                airports = airports
                  ? airports.toUpperCase().replace(";", ",").replace(" ", ",")
                  : "";
                store_settings.dispatch({
                  type: 1,
                  change: true,
                  index: i,
                  exception_airports: airports,
                });
              }}
            />
            <div class="error">
              {add_errors[i] && add_errors[i].exception_airports
                ? add_errors[i].exception_airports
                : ""}
            </div>
          </div>

          <div class="col-2">
            <input
              type="text"
              class="form-control "
              id={"contact_emails_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={airlines[i].contact_emails}
              onChange={(e) => {
                let contacts = e.target.value;
                contacts = contacts
                  ? contacts.toLowerCase().replace(",", ";")
                  : "";
                store_settings.dispatch({
                  type: 1,
                  change: true,
                  index: i,
                  contact_emails: contacts,
                });
              }}
            />

            <div class="error">
              {add_errors[i] && add_errors[i].contact_emails
                ? add_errors[i].contact_emails
                : ""}
            </div>
          </div>

          <div class="col-1">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  style={{ marginTop: "0px", paddingTop: "0px" }}
                  defaultChecked={airlines[i].quantum_available ? true : false}
                  color="primary"
                  inputProps={{ "aria-label": "checkbox with default color" }}
                  id={"quantum_available_" + i}
                  onFocus={(e) => {
                    this.setState({ current_input: e.target.id });
                  }}
                  onBlur={(e) => {
                    this.setState({ current_input: null });
                  }}
                  onChange={(e) => {
                    store_settings.dispatch({
                      type: 1,
                      change: true,
                      index: i,
                      quantum_available: e.target.checked,
                    });
                  }}
                />
              }
              labelPlacement="top"
            />
          </div>

          <div class="col-1">
            <button
              id={"btn_remove_" + i}
              type="button"
              class="btn btn-danger"
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
                let input = document.getElementById(e.target.id);
                if (input) input.setAttribute("style", "color:black");
              }}
              onBlur={(e) => {
                let input = document.getElementById(e.target.id);
                if (input) input.setAttribute("style", "color:white");
                this.setState({ current_input: null });
              }}
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  removeAirline: true,
                  index: i,
                  change: true,
                });
                this.setState({ key: Date.now(), newLine: true });
              }}
            >
              <Delete fontSize="small" />
            </button>

            {i === nbLines - 1 && (
              <button
                type="button"
                id={"bnt_add_1_" + i}
                class="btn btn-success"
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={() => {
                  store_settings.dispatch({ type: 1, addLineAirline: true });
                  this.setState({ newLine: true });
                  this.setState({ key: Date.now() });
                }}
              >
                <AddCircleIcon fontSize="small" />
              </button>
            )}
          </div>
        </div>
      );
    }

    return (
      <div style={{ paddingLeft: "0.5em" }} key={this.state.key}>
        <div class="row">
          <div class="col-1">
            <label>Station</label>
          </div>
          <div class="col-1">
            <label>Country</label>
          </div>
          <div class="col-1">
            <label>Issue Carrier</label>
          </div>
          <div class="col-1">
            <label>AWB prefix</label>
          </div>
          <div class="col-2">
            <label>Airline Name</label>
          </div>
          <div class="col-2">
            <label>Origin airports to be excluded</label>
            <Help
              color="primary"
              data-tip='Format should be "airport code,airport code,airport code", e.g. ORY,CDG,BOD'
              data-type="info"
              delay-hide={1000}
            />

            <ReactTooltip place="top" effect="solid" delayHide={1000} />
          </div>
          <div class="col-2">
            <label>Contatcs</label>
            <Help
              color="primary"
              data-tip='Format should be "email1;email2;email3", e.g. example@int.domain;example@ext.domain'
              data-type="info"
              delay-hide={1000}
            />
            <ReactTooltip place="top" effect="solid" delayHide={1000} />
          </div>
          <div class="col-1">
            <label>Available</label>
          </div>
          <div class="col-1"></div>
        </div>
        {html}
      </div>
    );
  }
}

class GHAPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    const w1 = watch(store_settings.getState, 'gha_info');
    store_settings.subscribe(
      w1((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) {
          this.setState({ key: Date.now() });
        }
      })
    );

    const w2 = watch(store_settings.getState, 'force');
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) {
          this.setState({ key: Date.now() });
        }
      })
    );

    let w3 = watch(store_settings.getState, "updateGHAObject");
    store_settings.subscribe(
      w3((newVal, oldVal, objectPath) => {
        console.log("%s changed a from %s to %s", objectPath, oldVal, newVal);
        // if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );

    let w4 = watch(store_settings.getState, "current_station");
    store_settings.subscribe(
      w4((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w5 = watch(store_settings.getState, "current_carrier");
    store_settings.subscribe(
      w5((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.gha_info !== prevProps.gha_info) {
      this.setState({ key: Date.now() });
    }
  }

  onClickEdit(row,index) {

    let gha = {
      index : index,
      station: row.station,
      carrier: row.carrier,
      country: row.country,
      oper_type : row.oper_type,
      departure_airport : row.departure_airport,
      gha_name : row.gha_name,
      gha_address : row.gha_address,
      gha_opening_hours :   row.gha_opening_hours ? row.gha_opening_hours : row.gha_hle ? row.gha_hle : "",
      additional_infos : row.additional_infos
    };

    let error = {
      id: null,
      station: "",
      carrier: "",
      country: "",
      oper_type : "",
      departure_airport : "", 
      gha_name : "", 
      gha_address : "",
      gha_opening_hours : "",
      additional_infos :  ""
    };


    store_settings.dispatch({
      type: 1,
      gha: gha,
      updateGHAObject: true,
      update_errors: error,
      updateGHAModal: true,
    });
  }


  render() {
    let settings_state = store_settings.getState();
    let { gha_info, current_station, current_carrier } =
      store_settings.getState();
    gha_info = gha_info ? gha_info : []
    
    current_station = current_station ? current_station : "";
    current_carrier = current_carrier ? current_carrier : "";

    const splitAndMap = (str) => {

      return str ? str.split(/[\n;]+/).map((item, index) => (
        <span key={index}>
          {item}
          <br />
        </span>
      )) : [];
    };

    const gha_table = (
      <table class="table table-striped" >
        <thead>
          <tr>
            <th scope="col"> </th>
            <th scope="col"> </th>
            <th scope="col"> </th>
            <th scope="col"> </th>
            <th scope="col"></th>
            <th scope="col"> </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">
              {
                <>
                 {current_carrier !== "***" && (
                    <button
                      type="button"
                      class="btn btn-success"
                      style={{ marginTop: "2em" }}
                      onClick={(e) => {
                        if (current_station === "***") return;
                        store_settings.dispatch({
                          type: 1,
                          array_modal_gha: [], 
                          add_errors: [],
                          addGHA : 1,
                          addGHAModal: true,
                          addLineGHA: true,
                        });
                      }}
                    >
                      <AddCircleIcon fontSize="small" />
                    </button>
                  )}
                </>
              }
            </th>
          </tr>

          <tr class="settings_portofolio_panel">
            <th scope="col"> Station </th>
            <th scope="col"> Country </th>
            <th scope="col"> Carrier </th>
            <th scope="col"> Departure Airport</th>
            <th scope="col"> Oper Type </th>
            <th scope="col"> GHA Name </th>
            <th scope="col"> Address </th>
            <th scope="col"> Opening Hours </th>
            <th scope="col"> Additional Information </th>
            <th scope="col">
              {" "}
              {"Edit"}
            </th>
          </tr>
        </thead>

        <tbody>
          {gha_info.map((row, index) => {

            return (
              <tr key={index}>
                <td>{row.station}</td>
                <td>{row.country}</td>
                <td>{row.carrier}</td>
                <td>{row.departure_airport}</td>
                <td>{row.oper_type ? row.oper_type  : ""}</td>
                <td>{splitAndMap(row.gha_name)}</td>
                <td>{splitAndMap(row.gha_address)}</td>
                <td>{splitAndMap(row.gha_opening_hours ? row.gha_opening_hours : row.gha_hle ? row.gha_hle : "")}</td>
                <td>{splitAndMap(row.additional_infos)}</td>
                <td>
                  {
                    <>
                      <button
                        class="btn btn-primary"
                        style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
                        onClick={(e) => {
                          this.onClickEdit(row,index);
                          store_settings.dispatch({
                            type: 1,
                            addGHA: 0,
                          });
                        }}
                      >
                        <Edit fontSize="small" />
                      </button>
                      <button
                        id={"btn_remove_" + index}
                        type="button"
                        class="btn btn-danger"
                        tabIndex="-1"
                        onFocus={(e) => {
                          this.setState({ newLine: "btn_remove_" + index });
                        }}
                        onClick={(e) => {
                          store_settings.dispatch({
                            type: 1,
                            removeLineGHA: true,
                            index: index,
                          });
                          this.setState({ key: Date.now() });
                        }}
                      >
                        <Delete fontSize="small" />
                      </button>
                    </>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );


    if (!gha_info) return null;
    else
      return (
        <ExpansionPanel
            defaultExpanded={settings_state.gha_expand}
            onChange={() => expand("gha_expand")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
             <Typography>
                <h2>GHA Info</h2>
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
             
              {gha_table}
            </ExpansionPanelDetails>
          </ExpansionPanel>
      );
  }
}
class ModalAddGHA extends React.Component {
  componentDidMount() {
    let w = watch(store_settings.getState, "addGHAModal");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_settings.getState, "add_error");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        console.log("%s changed a from %s to %s", objectPath, oldVal, newVal);
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }
  render() {
    let show = store_settings.getState().addGHAModal;
  
    return (
      <Modal
        show={show}
        size="xl"
        onHide={() => {
          store_settings.dispatch({ type: 1, addGHAModal: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ width: "100%" }}>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4" style={{ textAlign: "center" }}>
                Add new GHA
              </div>
              <div class="col-4"></div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-12">
              <GHAList />
            </div>
          </div>

          <div class="row" style={{ textAlign: "center" }}>
            <div class="col-4"></div>

            <div class="col-4">
              <button
                type="button"
                class="col-2 btn btn-success"
                style={{ marginRight: "1em" }}
                id="save_gha_list"
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={async (e) => {
                  let add_error = checkAddGHAFields();
                  if (add_error) return;
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: false,
                    addGHAconfirm: true,
                    updateGHAconfirm: false,
                    confirmModalGHA: true,
                  });
                  this.setState({ key: Date.now() });
                }}
              >
                Save
              </button>

              <button
                type="button"
                class="col-2 btn btn-danger"
                style={{ marginLeft: "1em" }}
                id="close_gha_list"
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    addGHAModal: false,
                  });
                }}
              >
                Close
              </button>
            </div>

            <div class="col-4"></div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
class GHAList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
      current_input: null,
      newLine: null,
    };
  }

  componentDidMount() {
    let w = watch(store_settings.getState, "addGHA");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        console.log(
          "%s gha changed from %s to %s",
          objectPath,
          oldVal,
          newVal
        );
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {
    if (this.state.newLine) {
      let array_modal_gha = store_settings.getState().array_modal_gha;
      let input = document.getElementById("carrier_" + (array_modal_gha.length - 1));
      if (input) input.focus();
      this.setState({ newLine: null });
    } else {
      let input = document.getElementById(this.state.current_input);
      if (input) input.focus();
    }
  }



  render() {
    let { add_errors, array_modal_gha } = store_settings.getState();
    array_modal_gha = array_modal_gha ? array_modal_gha : [];
    let nbLines = array_modal_gha.length ? array_modal_gha.length : 0;


    
    if (!nbLines) {
      store_settings.dispatch({ type: 1, addLineGHA: true });
      array_modal_gha = store_settings.getState().array_modal_gha;
      nbLines = array_modal_gha.length ? array_modal_gha.length : 0;
    }

    let html = [];

    
    for (let i = 0; i < nbLines; i++) {
      html.push(
        <div class="row" style={{ marginBottom: "10px" }}>
          {/*<div class="col-1">
            <input
              type="text"
              class="form-control "
              disabled={true}
              id={"station_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].station}
            />
          </div>

          <div class="col-1">
            <input
              type="text"
              class="form-control "
              disabled={true}
              id={"country_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].country}
            />
          </div>

          <div class="col-1">
            <input
              type="text"
              class="form-control "
              disabled={true}
              id={"carrier_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].carrier}
            />
          </div>
  */}

          <div class="col-1">
            <input
              type="text"
              class="form-control "
              id={"departureAirport_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].departure_airport}
              onChange={(e) => {
                let airport = e.target.value;
                airport = airport
                  ? airport.toUpperCase()
                  : "";
                store_settings.dispatch({
                  type: 1,
                  addGHA: true,
                  index: i,
                  departure_airport: airport,
                });
              }}
            />
            <div class="error">
              {add_errors[i] && add_errors[i].departure_airport
                ? add_errors[i].departure_airport
                : ""}
            </div>
          </div>

          <div class="col-1">
          <select
              id={"oper_type_add" + i}
              class="form-control dropdown"
              onFocus={(e) => {
                //store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
              onBlur={(e) => {
                //store_errors.dispatch({ type: 1, current_input: null });
              }}
              onChange={(e) => {
                 let oper = e.target.value
                 store_settings.dispatch({
                  type: 1,
                  addGHA: true,
                  index: i,
                  oper_type: oper,
                });
              }}
            >
               <option value={""}></option>
              {OPERS.map((t) => {
                  return (
                    <option key={t} value={t}>
                      {t}
                    </option>
                  );
                 
              })}
            </select>
          </div>

          <div class="col-2">
            
            <textarea
              className="form-control"
              id={"GHAName_" + i}
              rows = "4"
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].gha_name}
              onChange={(e) => {
                let ghaName = e.target.value;
                
                store_settings.dispatch({
                  type: 1,
                  addGHA: true,
                  index: i,
                  gha_name: ghaName,
                });
              }}
            />

            <div class="error">
              {add_errors[i] && add_errors[i].gha_name
                ? add_errors[i].gha_name
                : ""}
            </div>
          </div>

          <div class="col-2">
            <textarea
              class="form-control "
              id={"GHAAddress_" + i}
              rows = "4"
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].gha_address}
              onChange={(e) => {
                let ghaAddress = e.target.value;
                
                store_settings.dispatch({
                  type: 1,
                  addGHA: true,
                  index: i,
                  gha_address: ghaAddress,
                });
              }}
            />
            <div class="error">
              {add_errors[i] && add_errors[i].gha_address
                ? add_errors[i].gha_address
                : ""}
            </div>
          </div>

          <div class="col-2">
            <textarea
              class="form-control "
              rows = "4"
              id={"GHAHle_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].gha_opening_hours}
              onChange={(e) => {
                let ghaHle = e.target.value;
                
                store_settings.dispatch({
                  type: 1,
                  addGHA: true,
                  index: i,
                  gha_opening_hours: ghaHle,
                });
              }}
            />

            <div class="error">
              {add_errors[i] && add_errors[i].gha_opening_hours
                ? add_errors[i].gha_opening_hours
                : ""}
            </div>
          </div>

          <div class="col-2">
            <textarea
              class="form-control "
              rows = "4"
              id={"AddInfos_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={array_modal_gha[i].additional_infos}
              onChange={(e) => {
                let additionalInfos = e.target.value;
                
                store_settings.dispatch({
                  type: 1,
                  addGHA: true,
                  index: i,
                  additional_infos: additionalInfos,
                });
              }}
            />
            <div class="error">
              {add_errors[i] && add_errors[i].additional_infos
                ? add_errors[i].additional_infos
                : ""}
            </div>
          </div>

          <div class="col-1">
            <button
              id={"btn_remove_GHA_" + i}
              type="button"
              class="btn btn-danger"
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
                let input = document.getElementById(e.target.id);
                if (input) input.setAttribute("style", "color:black");
              }}
              onBlur={(e) => {
                let input = document.getElementById(e.target.id);
                if (input) input.setAttribute("style", "color:white");
                this.setState({ current_input: null });
              }}
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  removeLineGHA: true,
                  index: i,
                  change: true,
                });
                this.setState({ key: Date.now(), newLine: true });
              }}
            >
              <Delete fontSize="small" />
            </button>

            {i === nbLines - 1 && (
              <button
                type="button"
                id={"bnt_add_GHA_" + i}
                class="btn btn-success"
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={() => {
                  store_settings.dispatch({ type: 1, addLineGHA: true });
                  this.setState({ newLine: true });
                  this.setState({ key: Date.now() });
                }}
              >
                <AddCircleIcon fontSize="small" />
              </button>
            )}
          </div>
        </div>
      );
    }
    

    return (
      <div style={{ paddingLeft: "0.5em" }} key={this.state.key}>
        <div class="row">
{ /*         <div class="col-1">
            <label>Station</label>
          </div>

          <div class="col-1">
            <label>Country</label>
          </div>
          <div class="col-1">
            <label>Carrier</label>
          </div>
*/}


          <div class="col-1">
            <label>Departure Airport</label>
          </div>

          <div class="col-1">
            <label>Oper. Type</label>
          </div>

          <div class="col-2">
            <label>GHA Name</label>
          </div>
          <div class="col-2">
            <label> Address</label>
          </div>
          <div class="col-2">
            <label>Opening hours</label>
          </div>
          <div class="col-2">
            <label>Additional information</label>
          </div>
         
          
        </div>
        {html}
        
      </div>
      
    );
  }
}
class ModalUpdateGHA extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
      current_input: null,
    };
  }

  componentDidMount() {
    let w1 = watch(store_settings.getState, "updateGHAModal");
    store_settings.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );


    let w2 = watch(store_settings.getState, "updateGHAerror");
    store_settings.subscribe(
      w2((newVal, oldVal, objectPath) => {
        console.log("%s changed a from %s to %s", objectPath, oldVal, newVal);
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );

    


    //W2 toDO
  }

  componentDidUpdate() {
    let input = document.getElementById(this.state.current_input);
    if (input) input.focus();
  }

  render() {
    
    let settings_state = store_settings.getState();
    let show = settings_state.updateGHAModal;
    let { gha, updateGHAerrors } = settings_state ;


    return (
      <Modal
        show={show}
        size="xl"
        onHide={() => {
          store_settings.dispatch({ type: 1, updateGHAModal: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ width: "100%" }}>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4" style={{ textAlign: "center" }}>
                {"Update GHA"}
              </div>
              <div class="col-4"></div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">

            {/*
            <div class="col-1">
              <label>Station</label>
              <input
                type="text"
                class={"form-control"}
                defaultValue={gha.station}
                disabled={true}
                id={"station_gha"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
              <div class="error">{""}</div>
            </div>

            <div class="col-1">
              <label>Country</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={gha.country}
                disabled={true}
                id={"country_gha"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
              <div class="error">{""}</div>
            </div>

            <div class="col-1">
              <label>Carrier</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={gha.carrier}
                disabled={true}
                id={"carrier_gha"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
              />
              <div class="error">{""}</div>
            </div>

            */}

            <div class="col-1">
              <label>Departure Airport</label>

              <input
                type="text"
                class={"form-control "}
                id={"dep_airport_gha"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={gha.departure_airport ? gha.departure_airport: ""}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: true,
                    departure_airport: e.target.value,
                  });
                }}
              />
              <div class="error">
                {updateGHAerrors.departure_airport
                  ? updateGHAerrors.departure_airport
                  : ""}
              </div>
            </div>

            <div class="col-1">
              <label>Oper. Type</label>
              <select
                id={"oper_type_update"}
                class="form-control dropdown"
                onFocus={(e) => {
                  //store_errors.dispatch({ type: 1, current_input: e.target.id });
                }}
                onBlur={(e) => {
                  //store_errors.dispatch({ type: 1, current_input: null });
                }}
                onChange={(e) => {
                  let oper = e.target.value
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: true,
                    oper_type : oper
                  });
                }}
              >
            
                <option value = ""></option>
                {OPERS.map((t) => {

                  if(t === gha.oper_type){
                    return (
                      <option key={t} value={t} selected>
                        {t}
                      </option>
                    );
                  }else{
                    return (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    );
                  }

                  
                })}
              </select>
            </div>

            <div class="col-2">
              <label>GHA Name</label>

              <textarea
                class={"form-control "}
                id={"gha_name"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={gha.gha_name ? gha.gha_name : ""}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: true,
                    gha_name: e.target.value,
                  });
                }}
              />
              <div class="error">
                {updateGHAerrors.gha_name
                  ? updateGHAerrors.gha_name
                  : ""}
              </div>
            </div>

            <div class="col-2">
              <label>Address </label>

              <textarea
                class={"form-control"}
                id={"gha_address"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={gha.gha_address ? gha.gha_address : ""}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: true,
                    gha_address: e.target.value,
                  });
                }}
              />
              <div class="error">
                {updateGHAerrors.gha_address
                  ? updateGHAerrors.gha_address
                  : ""}
              </div>
            </div>

            <div class="col-2">
              <label>Opening hours</label>

              <textarea
                class={"form-control"}
                id={"gha_hle"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={gha.gha_opening_hours ? gha.gha_opening_hours : ""}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: true,
                    gha_opening_hours: e.target.value,
                  });
                }}
              />
              <div class="error">
                {updateGHAerrors.gha_opening_hours
                  ? updateGHAerrors.gha_opening_hours
                  : ""}
              </div>
            </div>

            <div class="col-2">
              <label>Additional information</label>

              <textarea
                class={"form-control"}
                id={"additional_infos"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                }}
                onBlur={(e) => {
                  this.setState({ current_input: null });
                }}
                defaultValue={gha.additional_infos ? gha.additional_infos : ""}
                onChange={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateGHAObject: true,
                    additional_infos: e.target.value,
                  });
                }}
              />
              <div class="error">
                {updateGHAerrors.additional_infos
                  ? updateGHAerrors.additional_infos
                  : ""}
              </div>
            </div>
          
          </div>

          <div class="row" style={{ textAlign: "center" }}>
            <div class="col-4"></div>
            <div class="col-4">
              <button
                type="button"
                class="col-2 btn btn-success"
                style={{ marginRight: "1em" }}
                id={"save_update_gha"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={async (e) => {
                  let updateGHAerror = checkUpdateGHAFields();

                  if (updateGHAerror) return;

                  
                  store_settings.dispatch({
                    type: 1,
                    confirmModalGHA: true,
                    addGHAconfirm: false,
                    updateGHAconfirm: true,
                  });

                  this.setState({ key: Date.now() });
                }}
              >
                Save
              </button>

              <button
                type="button"
                class="col-2 btn btn-danger"
                style={{ marginLeft: "1em" }}
                id={"close_update_gha"}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById(e.target.id);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
                onClick={(e) => {
                  store_settings.dispatch({
                    type: 1,
                    updateGHAModal: false,
                    updateGHAerrors: [],
                    
                  });
                }}
              >
                Close
              </button>
            </div>
            <div class="col-4"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalConfirmGHA extends React.Component {
  componentDidMount() {
    let w = watch(store_settings.getState, "confirmModalGHA");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onClickYes(e) {
    let { gha_info, array_modal_gha, gha, addGHAconfirm, updateGHAconfirm } = store_settings.getState();
    gha_info = gha_info ? gha_info : []
    array_modal_gha =  array_modal_gha ? array_modal_gha : []

    
    if (addGHAconfirm) {

      let concat_gha = gha_info.concat(array_modal_gha)

      store_settings.dispatch({ 
        type: 1,
        gha_info : concat_gha,
        force: true,
        loadingSpinner: true 
      });
   
    } else if(updateGHAconfirm) {

      
      let index = gha.index
      delete gha.index
      gha_info[index] = gha


      store_settings.dispatch({ 
        type: 1,
        force: true,
        gha_info: gha_info,
        updateGHAObject: true 
      });

      store_settings.dispatch({ type: 1, loadingSpinner: true });

    }

    store_settings.dispatch({
      type: 1,
      confirmModalGHA: false,
      addGHAModal: false,
      updateGHAModal: false,
      updateGHAObject: false,
      addGHAconfirm: false,
      updateGHAconfirm: false,
      force: true,
    });
    store_settings.dispatch({ type: 1, loadingSpinner: false });

    let save = document.getElementById("btn-save-main")
    save.click()

  }

  render() {
    let show = store_settings.getState().confirmModalGHA;

  

    return (
      <Modal
        show={show}
        onHide={() => {
          store_settings.dispatch({ type: 1, confirmModalGHA: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            <div>
              {store_settings.getState().addGHAconfirm
                ? "Do you want to save?"
                : "Do you want to save changes?"}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-2"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  confirmModalGHA: false,
                  addGHAModal: false,
                  gha_info: [],
                  updateGHAModal: false,
                  updateGHAObject: false,
                  addGHAconfirm: false,
                  updateGHAconfirm: false,
                });
              }}
            >
              No
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-secondary"
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  confirmModalGHA: false,
                  updateGHAObject: false,
                  addGHAconfirm: false,
                  updateGHAconfirm: false,
                });
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalConfirm extends React.Component {
  componentDidMount() {
    let w = watch(store_settings.getState, "confirmModal");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onClickYes(e) {
    let { airline, airlines, current_station, current_carrier } =
      store_settings.getState();
    let params = {};
    
    if (airlines && airlines.length > 0) {
      params.airlines = airlines;
      params.station = airlines[0].station;
      params.addAirline = true;
      params.updateAirline = false;
      store_settings.dispatch({ type: 1, loadingSpinner: true });
      
      await Routes.post("/AirlinePortfolio", params, true);
    } else if (airline.id) {
      params.airline = airline;
      params.station = airline.station;
      params.updateAirline = true;
      params.addAirline = false;
      store_settings.dispatch({ type: 1, loadingSpinner: true });
      await Routes.post("/AirlinePortfolio", params, true);
    }

    current_carrier = current_carrier !== "***" ? current_carrier : null;

    let airline_portfolio = await Routes.get(
      "/AirlinePortfolio",
      { station: current_station, carrier: current_carrier },
      true
    );

    store_settings.dispatch({
      type: 1,
      airline_portfolio: airline_portfolio ? airline_portfolio.data : [],
    });
    store_settings.dispatch({
      type: 1,
      confirmModal: false,
      addAirlineModal: false,
      updateAirlineModal: false,
      updateAirline: false,
      addAirline: false,
      force: true,
    });
    store_settings.dispatch({ type: 1, loadingSpinner: false });
  }

  render() {
    let show = store_settings.getState().confirmModal;

    return (
      <Modal
        show={show}
        onHide={() => {
          store_settings.dispatch({ type: 1, confirmModal: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            <div>
              {store_settings.getState().addAirline
                ? "Do you want to save?"
                : "Do you want to save changes?"}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-2"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  confirmModal: false,
                  addAirlineModal: false,
                  airlines: [],
                  updateAirlineModal: false,
                  addAirline: false,
                  updateAirline: false,
                });
              }}
            >
              No
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-secondary"
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  confirmModal: false,
                  addAirline: false,
                  updateAirline: false,
                });
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalDelete extends React.Component {
  componentDidMount() {
    let w = watch(store_settings.getState, "deleteModal");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  
  async onClickYes(e) {
    let { airline, current_station, current_carrier } =
      store_settings.getState();
    let params = { id: airline.id };

    let id = params.id;

    store_settings.dispatch({ type: 1, loadingSpinner: true });

    await Routes.post("/DeleteAirlinePortfolio", airline, true);

    current_carrier = current_carrier !== "***" ? current_carrier : null;

    let airline_portfolio = await Routes.get(
      "/AirlinePortfolio",
      { station: current_station, carrier: current_carrier },
      true
    );

    store_settings.dispatch({
      type: 1,
      airline_portfolio: airline_portfolio ? airline_portfolio.data : [],
    });

    store_settings.dispatch({
      type: 1,
      loadingSpinner: false,
      deleteModal: false,
    });
  }
  render() {
    let show = store_settings.getState().deleteModal;
    let airline = store_settings.getState().airline;

    return (
      <Modal
        dialogClassName="modal-m"
        show={show}
        onHide={() => {
          store_settings.dispatch({ type: 1, deleteModal: true });
        }}
      >
        <Modal.Header className="settings_modal_header">
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            <div>Do you want to delete?</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-1">
              <label>Station</label>
              <input
                type="text"
                class={"form-control"}
                defaultValue={airline.station}
                disabled={true}
              />
            </div>

            <div class="col-1">
              <label>Country</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.country}
                disabled={true}
              />
            </div>

            <div class="col-1">
              <label>Issue carrier</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.carrier}
                maxLength={3}
                disabled={true}
              />
            </div>

            <div class="col-1">
              <label>AWB Prefix</label>
              <input
                type="text"
                placeholder=""
                class={"form-control "}
                defaultValue={airline.prefix ? airline.prefix : ""}
                disabled={true}
              />
            </div>

            <div class="col-2">
              <label>Airline name</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.name ? airline.name : ""}
                disabled={true}
              />
            </div>

            <div class="col-2">
              <label>Origin airports to be excluded </label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.exception_airports}
                disabled={true}
              />
            </div>

            <div class="col-3">
              <label>Contatcs</label>
              <input
                type="text"
                class={"form-control "}
                defaultValue={airline.contact_emails}
                disabled={true}
              />
            </div>

            <div class="col-1">
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={airline.quantum_available ? true : false}
                    color="primary"
                    disabled={true}
                    inputProps={{ "aria-label": "checkbox with default color" }}
                  />
                }
                label={"Available"}
                labelPlacement="top"
              />
            </div>
          </div>

          <div class="row" style={{ textAlign: "center" }}>
            <div class="col-4"></div>

            <div class="col-4">
              <button
                type="button"
                class="col-2 btn btn-success"
                style={{ marginRight: "1em" }}
                onClick={async (e) => {
                  this.onClickYes(e);
                }}
              >
                Yes
              </button>

              <button
                type="button"
                class="col-2 btn btn-danger"
                style={{ marginLeft: "1em" }}
                onClick={(e) => {
                  store_settings.dispatch({ type: 1, deleteModal: false });
                }}
              >
                No
              </button>
            </div>

            <div class="col-4"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class PageLoadingSpinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = { key: Date.now() };
    this.style = {
      position: "absolute",
      left: "50%",
      margin: "auto",
      "margin-top": "20em",
      "z-index": "10",
      color: "white",
    };
  }

  componentDidMount() {
    let w1 = watch(store_settings.getState, "loadingSpinner");
    store_settings.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let show = store_settings.getState().loadingSpinner;

    if (!show) return null;
    else {
      return (
        <div key={this.state} style={this.style} class="" role="status">
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      );
    }
  }
}

class ULDList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
      current_input: null,
      newLine: null,
    };
  }

  componentDidMount() {
    let w = watch(store_settings.getState, "current_config.uld_pricing");
    store_settings.subscribe(
      w((newVal, oldVal, objectPath) => {
        console.log("%s uld changed from %s to %s", objectPath, oldVal, newVal);
        this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {
    if (this.state.newLine) {
      let uld_pricing = store_settings.getState().current_config.uld_pricing;
      let input = document.getElementById("deck_" + (uld_pricing.length - 1));
      if (input) input.focus();
      this.setState({ newLine: null });
    } else {
      let input = document.getElementById(this.state.current_input);
      if (input) input.focus();
    }
  }

  render() {
    let settings_state = store_settings.getState();
    let uld_pricing = settings_state.current_config.uld_pricing;
    let nbLines = uld_pricing.length
      ? settings_state.current_config.uld_pricing.length
      : 0;
    let html = [];

    for (let i = 0; i < nbLines; i++) {
      html.push(
        <tr>
          <td></td>

          <td>
            <select
              class="form-control dropdown"
              id={"deck_" + i}
              onChange={(e) => {
                store_settings.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  deck: e.target.value,
                });
              }}
              // onFocus={(e) => {this.setState({newLine : "deck_"+i})}}
            >
              <option value={uld_pricing[i].deck}>
                {" "}
                {uld_pricing[i].deck === "main"
                  ? "Main Deck"
                  : "Lower Deck"}{" "}
              </option>
              {util.DECKS.map((d) => {
                if (d !== uld_pricing[i].deck)
                  return (
                    <option value={d}>
                      {d === "main" ? "Main Deck" : "Lower Deck"}
                    </option>
                  );
                else return null;
              })}
            </select>
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              id={"position_" + i}
              //onFocus={(e) => { this.setState({newLine : "position_"+i})}}
              defaultValue={uld_pricing[i].position}
              onChange={(e) => {
                store_settings.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  position: e.target.value.toUpperCase(),
                });
              }}
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              id={"pivot_" + i}
              //onFocus={(e) => { this.setState({newLine : "pivot_"+i})}}
              defaultValue={uld_pricing[i].pivot_weight}
              onChange={(e) => {
                store_settings.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  pivot_weight: e.target.value,
                });
              }}
            />
          </td>

          <td>
            <select
              class="form-control dropdown"
              id={"unit_kg_" + i}
              onChange={(e) => {
                store_settings.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  unit_kg: e.target.value,
                });
              }}
              //onFocus={(e) => { this.setState({newLine : "unit_kg_"+i})}}
            >
              <option> {uld_pricing[i].unit_kg} </option>
              {
                <option>
                  {" "}
                  {uld_pricing[i].unit_kg === "kg" ? "lbs" : "kg"}
                </option>
              }
            </select>
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              id={"volume_" + i}
              //onFocus={(e) => {this.setState({newLine : "volume_"+i})}}
              defaultValue={uld_pricing[i].volume}
              onChange={(e) => {
                store_settings.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  volume: e.target.value,
                });
              }}
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              disabled={true}
              id={"unit_" + i}
              //onFocus={(e) => { this.setState({newLine : "unit_"+i})}}
              defaultValue={uld_pricing[i].unit}
              onChange={(e) => {
                store_settings.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  unit: e.target.value,
                });
              }}
            />
          </td>

          <td>
            <button
              id={"btn_remove_" + i}
              type="button"
              class="btn btn-danger"
              tabIndex="-1"
              onFocus={(e) => {
                this.setState({ newLine: "btn_remove_" + i });
              }}
              onClick={(e) => {
                store_settings.dispatch({
                  type: 1,
                  removeLine: true,
                  index: i,
                });
                this.setState({ key: Date.now() });
              }}
            >
              <Delete fontSize="small" />
            </button>

            {i === nbLines - 1 && (
              <button
                type="button"
                id="bnt_add_1"
                class="btn btn-success"
                onClick={() => {
                  store_settings.dispatch({ type: 1, addLine: true });
                  this.setState({ newLine: true });
                  this.setState({ key: Date.now() });
                }}
              >
                <AddCircleIcon fontSize="small" />
              </button>
            )}
          </td>
        </tr>
      );
    }

    return (
      <div>
        <table class="table table-striped" key={this.state.key}>
          <thead>
            <tr class="uld_pricing_panel">
              <th scope="col">
                <button
                  type="button"
                  class="btn btn-success edit_menu"
                  onClick={() => {
                    store_settings.dispatch({ type: 1, addLine: true });
                    this.setState({ newLine: true });
                    this.setState({ key: Date.now() });
                  }}
                >
                  <AddCircleIcon fontSize="small" />
                </button>
              </th>
              <th scope="col">Deck</th>
              <th scope="col">Position</th>
              <th scope="col">Pivot Weight</th>
              <th scope="col">Unit</th>
              <th scope="col">Volume</th>
              <th scope="col">Unit</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>{html}</tbody>
        </table>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    configuration: state.configuration,
    authorized_stations: state.authorized_stations,
    carriers_list: state.carriers_list,
    loggedIn: state.loggedIn,
    custom_list: state.custom_list,
  }),
  (dispatch) => {
    return {
      update_custom: (custom_list) =>
        dispatch({ type: 1, custom_list: custom_list }),
    };
  }
)(Main);

function expand(name) {
  let settings_state = store_settings.getState();
  let val = !settings_state[name];
  let json = { type: 1 };
  json[name] = val;
  store_settings.dispatch(json);
}

function checkUpdateFields() {
  let { airline } = store_settings.getState();

  store_settings.dispatch({
    type: 1,
    update_error: false,
    update_errors: {
      id: null,
      station: "",
      carrier: "",
      prefix: "",
      name: "",
      country: "",
      exception_airports: "",
      quantum_available: 1,
      contact_emails: "",
    },
  });

  if (!airline.station.trim()) {
    store_settings.dispatch({
      type: 1,
      update_error: true,
      station: "Missing.",
    });
  }

  if (!airline.carrier.trim()) {
    store_settings.dispatch({
      type: 1,
      update_error: true,
      carrier: "Missing.",
    });
  } else {
    if (
      !airline.carrier.match(/^[0-9]{2,3}$/) &&
      !airline.carrier.match(/^[A-Z]{2,3}$/) &&
      !airline.carrier.match(/^[0-9][A-Z]$/) &&
      !airline.carrier.match(/^[A-Z][0-9]$/) &&
      !airline.carrier.match(/^[A-Z][A-Z][0-9]$/) &&
      !airline.carrier.match(/^[0-9][A-Z][A-Z]$/) &&
      !airline.carrier.match(/^[A-Z][0-9][A-Z]$/)
    ) {
      store_settings.dispatch({
        type: 1,
        update_error: true,
        carrier: "Invalid Carrier",
      });
    }

    let carriers_list = store_settings.getState().carriers_list;
    carriers_list = carriers_list ? carriers_list : [];

    let res = carriers_list.filter((c) => c.code === airline.carrier)[0];

    if (!res) {
      store_settings.dispatch({
        type: 1,
        update_error: true,
        carrier: "Carrier does not exist.",
      });
    }
  }

  if (!airline.country) {
    store_settings.dispatch({
      type: 1,
      update_error: true,
      country: "Missing.",
    });
  }

  if (airline.exception_airports) {
    let airports = airline.exception_airports.trim();
    airports = airports ? airports.split(",") : [];
    for (let i = 0; i < airports.length; i++) {
      let airline = airports[i];
      if (airline.length !== 3) {
        store_settings.dispatch({
          type: 1,
          update_error: true,
          exception_airports: "Includes invalid airport.",
        });
      }
    }
  }

  if (airline.contact_emails) {
    let emails = airline.contact_emails.trim();
    emails = emails ? emails.split(";") : [];
    for (let i = 0; i < emails.length; i++) {
      let email = emails[i];
      let valid = util.validateEmail(email);
      if (!valid)
        store_settings.dispatch({
          type: 1,
          update_error: true,
          contact_emails: "Includes invalid email.",
        });
    }
  }

  return store_settings.getState().update_error;
}

function checkAddFields() {
  let { airlines } = store_settings.getState();
  airlines = airlines ? airlines : [];
  let errors = [];
  store_settings.dispatch({ type: 1, add_error: false, add_errors: [] });

  for (let i = 0; i < airlines.length; i++) {
    let airline = airlines[i];

    let error = {
      id: null,
      station: "",
      carrier: "",
      prefix: "",
      name: "",
      country: "",
      exception_airports: "",
      quantum_available: 1,
      contact_emails: "",
    };
    if (!airline.station.trim()) {
      error.station = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (!airline.carrier.trim()) {
      error.carrier = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    } else {
      if (
        !airline.carrier.match(/^[0-9]{2,3}$/) &&
        !airline.carrier.match(/^[A-Z]{2,3}$/) &&
        !airline.carrier.match(/^[0-9][A-Z]$/) &&
        !airline.carrier.match(/^[A-Z][0-9]$/) &&
        !airline.carrier.match(/^[A-Z][A-Z][0-9]$/) &&
        !airline.carrier.match(/^[0-9][A-Z][A-Z]$/) &&
        !airline.carrier.match(/^[A-Z][0-9][A-Z]$/)
      ) {
        error.carrier = "Invalid Carrier.";
        store_settings.dispatch({ type: 1, add_error: true });
      }

      let carriers_list = store_settings.getState().carriers_list;
      carriers_list = carriers_list ? carriers_list : [];

      let res = carriers_list.filter((c) => c.code === airline.carrier)[0];

      if (!res) {
        error.carrier = "Carrier does not exist.";
        store_settings.dispatch({ type: 1, add_error: true });
      }
    }

    if (!airline.country) {
      error.country = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (airline.exception_airports) {
      let airports = airline.exception_airports.trim();
      airports = airports ? airports.split(",") : [];
      for (let i = 0; i < airports.length; i++) {
        let airline = airports[i];
        if (airline.length !== 3) {
          error.exception_airports = "Includes invalid airport.";
          store_settings.dispatch({ type: 1, add_error: true });
        }
      }
    }

    if (airline.contact_emails) {
      let emails = airline.contact_emails.trim();
      emails = emails ? emails.split(";") : [];
      for (let i = 0; i < emails.length; i++) {
        let email = emails[i];
        let valid = util.validateEmail(email);
        if (!valid) {
          error.contact_emails = "Includes invalid email.";
          store_settings.dispatch({ type: 1, add_error: true });
        }
      }
    }

    errors.push(error);
  }

  store_settings.dispatch({ type: 1, add_errors: errors });
  return store_settings.getState().add_error;
}

function checkAddGHAFields() {
  let { array_modal_gha } = store_settings.getState();
  array_modal_gha = array_modal_gha ? array_modal_gha : [];
  let errors = [];
  store_settings.dispatch({ type: 1, add_error: false, add_errors: [] });

  for (let i = 0; i < array_modal_gha.length; i++) {
    let gha = array_modal_gha[i];

    let error = {
      id: null,
      station: "",
      carrier: "",
      country: "",
      departure_airport : "", 
      gha_name : "", 
      gha_address : "",
      gha_opening_hours : "",
    };
    if (!gha.station.trim()) {
      error.station = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (!gha.carrier.trim()) {
      error.carrier = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    } 

    if (!gha.country) {
      error.country = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (!gha.departure_airport.trim()) {
      error.departure_airport = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    } else if (gha.departure_airport.trim().length !== 3) {
      error.departure_airport = "Must be 3 chars.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (!gha.gha_name) {
      error.gha_name = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (!gha.gha_address) {
      error.gha_address = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    if (!gha.gha_opening_hours) {
      error.gha_opening_hours = "Missing.";
      store_settings.dispatch({ type: 1, add_error: true });
    }

    errors.push(error);
  }

  store_settings.dispatch({ type: 1, add_errors: errors });
  return store_settings.getState().add_error;
}

function checkUpdateGHAFields() {
  let { gha } = store_settings.getState();

store_settings.dispatch({
    type: 1,
    updateGHAerror: false,
    updateGHAerrors: {
      station: "",
      carrier: "",
      country : "", 
      departure_airport : "", 
      gha_name : "", 
      gha_address : "",
      gha_opening_hours : ""
    },
  });

  if (!gha.departure_airport) {
    store_settings.dispatch({
      type: 1,
      updateGHAerror: true,
      departure_airport: "Missing.",
    });
  } 
  else if (gha.departure_airport.trim().length !== 3) {
    store_settings.dispatch({
      type: 1,
      updateGHAerror: true,
      departure_airport: "Must be 3 chars.",
    });
  }  

  if (!gha.gha_name) {
    store_settings.dispatch({
      type: 1,
      updateGHAerror: true,
      gha_name: "Missing.",
    });
  }
  if (!gha.gha_address) {
    store_settings.dispatch({
      type: 1,
      updateGHAerror: true,
      gha_address: "Missing.",
    });
  }
  if (!gha.gha_opening_hours) {
    store_settings.dispatch({
      type: 1,
      updateGHAerror: true,
      gha_opening_hours: "Missing.",
    });
  }

  return store_settings.getState().updateGHAerror;
}

function errors() {
  let settings = store_settings.getState();
  let current_config = settings.current_config;
  let default_contact = current_config.default_contact;
  let booking_contact_email = current_config.booking_contact_email;
  let error = false;

  let errors_message = {
    default_contact: "",
    booking_contact_email: "",
  };

  // if(default_contact && !util.validateEmail(default_contact)){
  //   error = true
  //   errors_message.default_contact = "Some emails are invalids."
  // }

  // if(booking_contact_email && !util.validateEmail(booking_contact_email, true)){
  //   error = true
  //   errors_message.booking_contact_email = "Some emails are invalids."
  // }

  //store_settings.dispatch({type : 1, errors_message : errors_message, error : error, refresh : true})

  return error;
}

function exportUserstoExcel(stations = null) {
  let { airline_portfolio, current_station } = store_settings.getState();
  airline_portfolio = airline_portfolio ? airline_portfolio : [];

  let columns = [
    "Station",
    "Country",
    "Carrier",
    "Exception Airports",
    "Available",
    "Contact Emails",
  ];

  let data = [];

  airline_portfolio.forEach((a) => {
    data.push([
      a.station,
      a.country,
      a.carrier,
      a.exception_airports ? a.exception_airports.join(",") : "",
      a.quantum_available ? "Yes" : "No",
      a.contact_emails ? a.contact_emails.join(";") : "",
    ]);
  });

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Equote-scope");
  worksheet.getColumn("A").width = 15;
  worksheet.getColumn("B").width = 15;
  worksheet.getColumn("C").width = 15;
  worksheet.getColumn("D").width = 25;
  worksheet.getColumn("E").width = 15;
  worksheet.getColumn("F").width = 20;

  worksheet.addTable({
    name: "EquoteTable",
    ref: "A1",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
    },
    columns: columns.map((c) => {
      return { name: c, filterButton: true };
    }),
    rows: data,
  });

  //FORMATTAGE
  worksheet.getRow(1).eachCell((cell) => {
    cell.border = {
      top: { style: "thin", color: "€" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    cell.fill = {
      type: "pattern",
      pattern: "darkVertical",
      bgColor: { argb: "ff1e3194" },
      fgColor: { argb: "ff1e3194" },
    };
    cell.font = {
      name: "Arial Black",
      color: { argb: "FFFFFFFF" },
      family: 2,
      size: 11,
    };
  });

  // save under export.xlsx
  workbook.xlsx.writeBuffer("EquoteScope.xlsx").then(function (data) {
    var blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let filename =
      "Equote_Scope_" +
      current_station +
      "_" +
      util.convertDate(new Date(), false, ".") +
      ".xlsx";
    saveAs(blob, filename);
  });
}
