import cookies from "../Cookies" 

export default   {
 
  reducer_analytics : function(state={ 
                    
      page_loading_spinner : false,
      alert : null,
      data : null,
      default_currency : 'EUR',
      default_station : '',
      exch_rate : [],
      modalAgent : false,
      filters: {
        pending_sort : "revenue",
        only_user : false,
        delay : 7,
        dest : [],
        origin : [],
        carrier : [],
        user_carriers : cookies.user_carriers,
        agent : "",
        agent_code : "",
        commodity:[],
        follow_up : false
      },
      agent_suggestion : []

      }, action){


      if(action.page_loading_spinner !== undefined)
        state.page_loading_spinner = action.page_loading_spinner
      
      if(action.alert !== undefined)
        state.alert = action.alert
      
      if(action.data !== undefined)
        state.data = action.data

      if(action.exch_rate !== undefined)
        state.exch_rate = action.exch_rate

      if(action.default_currency !== undefined)
        state.default_currency = action.default_currency

      if(action.default_station !== undefined)
        state.default_station = action.default_station

      if(action.modalAgent !== undefined)
        state.modalAgent = action.modalAgent
      
        
      if(action.agent_city !== undefined)
          state.agent_city = action.agent_city ? action.agent_city.toUpperCase() : ""
      
      if(action.agent_suggestion !== undefined)
          state.agent_suggestion = action.agent_suggestion


      if(action.filters){
        
        if(action.follow_up !== undefined)
          state.filters.follow_up = action.follow_up

        if(action.pending_sort)
            state.filters.pending_sort = action.pending_sort

        if(action.only_user !== undefined)
          state.filters.only_user = action.only_user

        if(action.delay !== undefined)
          state.filters.delay = parseInt(action.delay)


        if(action.agent_code !== undefined)
          state.filters.agent_code = action.agent_code

        if(action.agent !== undefined && action.agent !== null){
            state.filters.agent = action.agent
          }

        if(action.dest !== undefined){
            action.dest.forEach((d) => {
            
              if(d!== "" && state.filters.dest.indexOf(d) < 0)
                state.filters.dest.push(d.toUpperCase())

            })
        }

        if(action.dest2 !== undefined){
          state.filters.dest = action.dest2
        }


        if(action.commodity !== undefined){
              action.commodity.forEach((d) => {
              
                if(d!== "" && state.filters.commodity.indexOf(d) < 0)
                state.filters.commodity.push(d)

              })
        }
          
        if(action.commodity2 !== undefined){
          state.filters.commodity = action.commodity2
        }
  


        
        if(action.origin !== undefined){
              action.origin.forEach((d) => {
              
                if(d!== "" && state.filters.origin.indexOf(d) < 0)
                state.filters.origin.push(d.toUpperCase())

              })
        }
  
        if(action.origin2 !== undefined){
          state.filters.origin = action.origin2
        }

        if(action.user_carriers !== undefined){
          state.user_carriers = action.user_carriers
        }

        if(action.carrier !== undefined){
              action.carrier.forEach((d) => {
              
                if(d!== "" && state.filters.carrier.indexOf(d) < 0)
                state.filters.carrier.push(d.toUpperCase())
              })
        }
  
        if(action.carrier2 !== undefined){
              state.filters.carrier = action.carrier2
        }

      }

      return state
    
    }



}