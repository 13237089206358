import React from "react";
import { Modal } from "react-bootstrap";
import watch from "redux-watch";
import { Backdrop, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import "../styles/List.css";
import cookies from "../Cookies";
import Routes from "../Routes.js";
import { connect } from "react-redux";
import List_State from "../states/List_State.js";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import util from "../util";
// Logger with default options
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import Clear from "@material-ui/icons/Clear";
import Interweave from "interweave";

import InfoIcon from "@material-ui/icons/Info";
// import FeedIcon from "@material-ui/icons/Edit";
import moment from "moment";
import ReactDOMServer from "react-dom/server";

// import * as Icons from '@material-ui/icons';
// const iconList = Object.keys(Icons);

// console.log("icones", iconList);

const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration: false, // print the duration of each action?
  timestamp: true, // print the timestamp with each action?

  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.

  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.

  logger: console, // implementation of the `console` API.
  logErrors: true, // should the logger catch, log, and re-throw errors?

  diff: false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
});

var store_quotes = createStore(List_State.reducer_quotes);
var store_filters = createStore(
  List_State.reducer_filters,
  applyMiddleware(logger)
);

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      export_csv: "1",
      key: Date.now(),
    };
  }

  async componentDidMount() {
    document.title = "Quantum - List";
    store_quotes.dispatch({ type: 1, page_loading_spinner: true });
    let params = store_filters.getState();
    let res = await Routes.getQuotes(params);
    if (!res) return null;
    store_quotes.dispatch({
      type: 1,
      quotes: res.quotes,
      countQuotes: res.count,
      page_loading_spinner: false,
    });
  }

  render() {
    if (!this.props.loggedIn) return null;

    return (
      <div key={this.state.key}>
        {this.state.notification}
        <div class="container-fluid background">
          <ModalUpdateState />
          <PageLoadingSpinner />
          <FilterMenu
            authorized_stations={this.props.authorized_stations}
            shc_list={this.props.shc_list}
            custom_list={this.props.custom_list}
            exch_rate={this.props.exch_rate}
          />
          <QuotesList class="row" exch_rate={this.props.exch_rate} />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  authorized_stations: state.authorized_stations,
  shc_list: state.shcList,
  loggedIn: state.loggedIn,
  exch_rate: state.exch_rate,
  custom_list: state.custom_list,
}))(Main);

class QuotesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      isPopupVisible: false,
      selectedQuote: null,
    };

    this.popupRef = React.createRef();
  }

  togglePopup = (quote) => {
    this.setState((prevState) => ({
      isPopupVisible: !prevState.isPopupVisible,
      selectedQuote: quote,
    }));
  };

  handleClosePopupOutsideClick = (e) => {
    if (this.popupRef.current && !this.popupRef.current.contains(e.target)) {
      this.setState({
        isPopupVisible: false,
        selectedQuote: null,
      });
    }
  };

  componentDidUpdate() {
    let current_input = store_quotes.getState().current_input;
    let input = document.getElementById(current_input);
    if (input) input.focus();
  }

  componentDidMount() {
    store_filters.subscribe(async () => {
      store_quotes.dispatch({ type: 1, page_loading_spinner: true });
      let params = store_filters.getState();
      params.user_follow_up = cookies.account;
      let res = await Routes.getQuotes(params);
      store_quotes.dispatch({
        type: 1,
        quotes: res.quotes,
        countQuotes: res.count,
        page_loading_spinner: false,
      });
      this.setState({ key: Date.now() });
    });

    let w1 = watch(store_quotes.getState, "quotes");
    store_quotes.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() });
      })
    );

    document.addEventListener("mousedown", this.handleClosePopupOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "mousedown",
      this.handleClosePopupOutsideClick
    );
  }

  render() {
    let html = [];
    let quotes = store_quotes.getState().quotes;
    let filters = store_filters.getState();

    const { isPopupVisible, selectedQuote } = this.state;
    if (!quotes) return;

    console.log("KaoutarHERE: ", quotes);
    quotes.forEach((q) => {
      let shipment = q.shipment;
      let pricing = q.pricing;
      let date = new Date(q.time_stamp);
      let routes = q.routing.routes;

      let firstFlightDate;
      

      if (q.routing.detailed_info) {
        firstFlightDate = "-";
        for (let i = 0; i < routes.length; i++) {
          if (routes[i].flight_type.toLowerCase() === "flight") {
            firstFlightDate = q.routing.routes[i].flight_date;
            break;
          }
        }
      } else {
        firstFlightDate = "-";
      }

      if (
        filters.currency_conversion !== "No Conversion" &&
        filters.currency_conversion !== "LOCAL" &&
        pricing.currency !== filters.currency_conversion &&
        ((pricing.per_kg && pricing.price) ||
          (!pricing.per_kg && pricing.price_total))
      ) {
        pricing.price = util.convert_currency(
          pricing.price,
          pricing.currency,
          filters.currency_conversion,
          this.props.exch_rate
        );
        pricing.price_total = util.convert_currency(
          pricing.price_total,
          pricing.currency,
          filters.currency_conversion,
          this.props.exch_rate
        );
        pricing.currency = filters.currency_conversion;
      }

      let density = isNaN(parseFloat(shipment.chargeable_weight))
        ? NaN
        : 1 +
          "/" +
          parseInt(1000 / shipment.density) +
          " | " +
          shipment.density;
      let user = q.user;
      user = user ? q.user.replace(/@.*/, "").split(".") : "";
      user =
        (user[0] ? user[0] : "") +
        (user[1] ? "." + user[1].substring(0, 2) : "");

      var html_info = [];

      html_info.push(<QuoteView quote={q} />);

      //console.log("kaoutarrr: ", util.dateToString5(date))

      html.push(
        <tr>
          <th scope="row">
            <a
              href={
                cookies.rights && cookies.rights.quote_view
                  ? "#/Editor/" + q.id
                  : "#/List"
              }
              target="_blank"
              style={{ fontWeight: "normal" }}
            >
              {" "}
              {q.id}
              {q.follow_up ? (
                <span title="followed up">
                  <br />
                  <img
                    style={{ maxWidth: "15px" }}
                    src={require("../img/target2.svg")}
                    alt=""
                  />{" "}
                </span>
              ) : null}
            </a>

            {
              <button
                key={q.id}
                type="button"
                className="button-info"
                onClick={() => this.togglePopup(q)}
                style={{
                  border: "none",
                  width : "1px",
                  height : "1px",
                  background: "transparent",
                  marginLeft: "1px",
                  color: "#2c5474",
                }}
              >
                <InfoIcon fontSize="small" />
              </button>
            }

            {isPopupVisible && selectedQuote && selectedQuote.id === q.id && (
              <div className="popup-overlay">
                <div className="popup" ref={this.popupRef}>
                  {html_info}
                  <button
                    className="close_popup"
                    onClick={() => this.togglePopup(q)}
                  >
                    X
                  </button>
                </div>
              </div>
            )}
          </th>
          {cookies.region === "WLD" && <th scope="row">{q.hq ? "HQ" : ""}</th>}
          {(cookies.region === "WLD" || cookies.region.length > 3) && (
            <td> {q.station}</td>
          )}
          <th scope="row">
            {q.adhoc_ref} {q.adhoc_ref_buy ? "*" : ""}
          </th>
          <td>{util.dateToString5(date)}</td>
          <td>{user}</td>
          <td>{shipment.origin}</td>
          <td>{shipment.dest}</td>
          <td class="extra-large">{shipment.agent}</td>
          <td>{pricing.carrier} </td>
          <td>{shipment.pieces}</td>
          <td>
            {shipment.gross_weight
              ? util.formatAmount4(shipment.gross_weight)
              : ""}
          </td>
          <td>{shipment.volume ? shipment.volume : ""}</td>
          <td>
            {shipment.chargeable_weight
              ? util.formatAmount4(shipment.chargeable_weight)
              : shipment.chargeable_weight}{" "}
          </td>
          <td>{density} </td>
          <td>{shipment.commodity_code}</td>
          <td>{pricing.currency} </td>
          <td>
            {pricing.per_kg
              ? pricing.price && parseFloat(pricing.price).toFixed(2)
              : pricing.price_total &&
                util.formatAmount3(
                  parseFloat(pricing.price_total).toFixed(2)
                )}{" "}
          </td>
          <td>
            {pricing.all_in ? "All-in" : "++"} <br />{" "}
            {pricing.per_kg ? "/kg" : "Total"}{" "}
          </td>
          <td>
            <StatusDropdown
              state={q.status}
              price={pricing.price}
              id={q.id}
              follow_up_id={q.follow_up_id}
            />
          </td>
        </tr>
      );
    });

    return (
      /* Table list */
      <div class="col quotes-list">
        <table class="table-quotes table-striped">
          <thead>
            <tr>
              <th scope="col">ID</th>
              {cookies.region === "WLD" && <th scope="col">HQ</th>}
              {(cookies.region === "WLD" || cookies.region.length > 3) && (
                <th scope="col">Station</th>
              )}
              <th scope="col">Adhoc Ref</th>
              <th scope="col">Creation Date</th>
              <th scope="col">Created By</th>
              <th scope="col">Origin</th>
              <th scope="col">Destination</th>
              <th scope="col" class="extra-large">Agent</th>
              <th scope="col">Issue Carrier</th>
              <th scope="col">Pcs</th>
              <th scope="col">Gross Weight</th>
              <th scope="col">Vol</th>
              <th scope="col">Chg. Weight</th>
              <th scope="col">Density</th>
              <th scope="col">Commodity</th>
              <th scope="col">Curr.</th>
              <th scope="col">Selling Rate</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody class="quote-list-body">{html}</tbody>
        </table>
      </div>
      /* END Table list */
    );
  }
}

class QuoteView extends React.Component {
  constructor(props) {
    super(props);

    console.log("details", this.props.quote);
  }

  render() {
    let quote = this.props ? this.props.quote : null;
    quote = quote ? quote : null;

    if (!quote) return <></>;

    let shipment = quote.shipment ? quote.shipment : null;
    let packing = shipment.packing ? shipment.packing : [];

    let pricing = quote.pricing ? quote.pricing : null;
    let ocdc = pricing.ocdc ? pricing.ocdc : null;
    let margin = quote.margin ? quote.margin : null;
    let {margin_per_kg, margin_in_total} = margin
    let margin_value = margin.margin || (margin_per_kg ? margin_per_kg.margin : 0)
        margin_value = margin_value ? margin_value : null
    let buy_all_in_rate = margin.buy_all_in_rate || (margin_per_kg ? margin_per_kg.buy_all_in_rate : 0)
    buy_all_in_rate = buy_all_in_rate ? buy_all_in_rate : null

    let routing = quote.routing ? quote.routing : null;
    let routes = routing.routes ? routing.routes : [];

    let density = shipment
      ? isNaN(parseFloat(shipment.chargeable_weight))
        ? null
        : 1 + "/" + parseInt(1000 / shipment.density) + " | " + shipment.density
      : null;

    const cellStyle = {
      maxWidth: "100px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };

    return (
      <div style={{ backgroundColor: "white", position: "relative" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 0.5fr",
            gap: "20px",
          }}
        >
          {/* Shipment section */}
          <div
            style={{
              border: "1px solid black",
              borderRadius : "10px",
              flex: "1",
              padding: "10px",
              fontSize: "12px",
            }}
          >
            <img
              alt={"Shipment Details"}
              src={require("../img/packing_pc.png")}
            />{" "}
            <h5 style={{ color: "black" }}> Shipment </h5>
            <hr
              style={{ borderColor: "#2e5676", borderTop: "1px solid #2e5676" }}
            />
            <p
              style={{
                textAlign: "center",
                // fontSize: "15px",
                color: "#2e5576",
              }}
            >
              {shipment && (
                <p style={{ textAlign: "center", color: "#2e5576" }}>
                  {shipment.origin ? shipment.origin : "Origin "} <b> ➜ </b>{" "}
                  {shipment.dest ? shipment.dest : "Destination ❌"}
                </p>
              )}
            </p>
            <ul style={{ color: "black" }}>
              <li>
                {" "}
                Quote ID :{" "}
                <span
                  style={{
                    fontWeight: "normal",
                    color: "#2e5576",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={
                      cookies.rights && cookies.rights.quote_view
                        ? "#/Editor/" + quote.id
                        : "#/List"
                    }
                    target="_blank"
                    style={{ fontWeight: "normal" }}
                  >
                    {quote.id}
                  </a>
                </span>
              </li>
              <li>
                {" "}
                Sell Adhoc Ref : {quote.adhoc_ref ? quote.adhoc_ref : "❌"} |
                Buy Adhoc Ref {quote.adhoc_ref_buy ? quote.adhoc_ref_buy : "❌"}{" "}
                | AWB : {quote.awb_number ? quote.awb_number : "❌"}{" "}
              </li>

              <li>
                Agent :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.agent : null}{" "}
                </span>
              </li>
              <li>
                Stackable :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.stackable : null}{" "}
                </span>
              </li>
              <li>
                Commodity :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.commodity : null}{" "}
                </span>
              </li>
              <li>
                Pieces :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.pieces : null} pcs{" "}
                </span>
                / Volume :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.volume : null} m<sup>3</sup>{" "}
                </span>
                / Density :{" "}
                <span style={{ fontWeight: "normal" }}> {density}</span>
              </li>
              <li>
                Gross Weight :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.gross_weight : null} kg{" "}
                </span>
                / Chg. Weight :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment ? shipment.chargeable_weight : null} kg{" "}
                </span>
              </li>
              <li>
                Available at origin :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {shipment && shipment.available_origin ? shipment.available_origin : util.getCurrentDate()} {" "}
                </span>
               
              </li>
            </ul>
            <br></br>
            {/* Dimensions Section */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt={"Dimensions"}
                src={require("../img/packing_list.png")}
              />
              <h6
                style={{
                  marginLeft: "5px",
                  marginBottom: "-5px",
                  color: "black",
                }}
              >
                {" "}
                <b> Dimensions </b>{" "}
              </h6>
            </div>
            <hr
              style={{ borderColor: "#2e5676", borderTop: "1px solid #2e5676" }}
            ></hr>
            <div className="table-container">
              <table
                className="dimensions"
                style={{ overflowY: "auto", maxHeight: "130px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">Pieces</th>
                    <th scope="col">Length (cm) </th>
                    <th scope="col">Width (cm) </th>
                    <th scope="col">Height (cm) </th>
                    <th scope="col">Total Weight (kg) </th>
                    <th scope="col">Stackable</th>
                  </tr>
                </thead>
                <tbody>
                  {packing.map((item, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {" "}
                        {item.pieces}
                      </td>
                      <td
                        style={{
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {" "}
                        {item.length}
                      </td>
                      <td
                        style={{
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.width}
                      </td>
                      <td
                        style={{
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.height}
                      </td>
                      <td
                        style={{
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.weight}
                      </td>
                      <td
                        style={{
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.stackable ? "✅" : "❌"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Pricing section */}
          <div
            style={{
              border: "1px solid black",
              borderRadius : "10px",
              flex: "1",
              padding: "10px",
              fontSize: "12px",
            }}
          >
            <img src={require("../img/discount.png")} alt={"Pricing"} />{" "}
            <h5 style={{ color: "black" }}>Pricing </h5>
            <hr
              style={{ borderColor: "#2e5676", borderTop: "1px solid #2e5676" }}
            />
            <p
              style={{
                textAlign: "center",
                // fontSize: "15px",
                color: "#2e5576",
              }}
            >
              {pricing && pricing.from_date
                ? util.dateToString3(new Date(pricing.from_date))
                : null}{" "}
              ➜{" "}
              {pricing && pricing.to_date
                ? util.dateToString3(new Date(pricing.to_date))
                : null}
            </p>
            <ul style={{ color: "black" }}>
              <li>
                Issue Carrier :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {pricing ? pricing.carrier : null}{" "}
                </span>
              </li>

              <li>
                Margin total :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {margin_value
                    ? margin_value + " " + pricing.currency + "/Kg"
                    : "❌"}
                </span>
              </li>

              <li>
                Buying all-in rate :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {buy_all_in_rate
                    ? buy_all_in_rate + " " + pricing.currency + "/Kg"
                    : "❌"}
                </span>
              </li>

              {pricing && pricing.per_kg ? (
                <>
                  <li>
                    Selling rate :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {pricing.price
                        ? pricing.price + " " + pricing.currency + "/Kg"
                        : "❌"}{" "}
                      {pricing.all_in ? "All-in" : "Net-Net"}
                    </span>
                  </li>

                  <li>
                    Min chargeable weight :
                    <span style={{ fontWeight: "normal" }}>
                      {pricing.min_chargeable_weight
                        ? pricing.min_chargeable_weight + " Kg "
                        : "❌ "}
                    </span>
                    / Min Amount :
                    <span style={{ fontWeight: "normal" }}>
                      {pricing.min_amount ? pricing.min_amount : "❌"}
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    {" "}
                    Selling Total :
                    <span style={{ fontWeight: "normal" }}>
                      {pricing && pricing.price_total
                        ? pricing.price_total + " " + pricing.currency
                        : null}
                      / Total
                    </span>
                  </li>
                </>
              )}

              {ocdc && ocdc.length > 0 ? (
                <>
                  <li>
                    OCDC: ✅
                    <br />
                    {ocdc.map((item, i) => (
                      <span style={{ fontWeight: "normal" }}>
                        {item.code} | {item.based_on} | {item.currency} |{" "}
                        {item.value} | {item.total_charges} <br />{" "}
                      </span>
                    ))}
                  </li>
                </>
              ) : (
                <li>OCDC: ❌</li>
              )}
            </ul>
            <br></br>
            {/* History section */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <img alt={"Histroy"} src={require("../img/condition.png")} />
              <h6
                style={{
                  marginLeft: "5px",
                  marginBottom: "-5px",
                  color: "black",
                }}
              >
                {" "}
                <b> History </b>{" "}
              </h6>
            </div>
            <hr
              style={{ borderColor: "#2e5676", borderTop: "1px solid #2e5676" }}
            ></hr>
            <ul>
              <li>
                {" "}
                Created by :{" "}
                <span style={{ fontWeight: "normal" }}> {quote.user} </span>
              </li>

              <li>
                {" "}
                Last Updated by :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {quote.last_updated_by}{" "}
                </span>
              </li>

              <li>
                {" "}
                Last Update:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {quote.last_update
                    ? util.dateToString(new Date(quote.last_update))
                    : null}
                </span>
              </li>

              <li>
                {" "}
                Status :{" "}
                <span style={{ fontWeight: "normal" }}> {quote.status} </span>
              </li>

              {quote.status === "REJECTED" || quote.status === "DELETED" ? (
                <li>
                  Failure reason :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    {quote.failure_reason}
                  </span>
                </li>
              ) : (
                " "
              )}
            </ul>
          </div>

          {/* Remarks section */}
          <div
            style={{
              border: "1px solid black",
              borderRadius : "10px",
              flex: "1",
              padding: "10px",
              overflowY: "auto",
              maxHeight: "450px",
              fontSize: "12px",
            }}
          >
            <img src={require("../img/condition.png")} alt={"Remarks"} />{" "}
            <h5 style={{ color: "black" }}> Remarks </h5>
            <hr
              style={{ borderColor: "#2e5676", borderTop: "1px solid #2e5676" }}
            />
            <ul>
              <li>
                Internal Remarks :
                <span style={{ fontWeight: "normal" }}>
                  {quote.int_remarks ? quote.int_remarks : "❌"}
                </span>
              </li>
              <li>
                External Remarks :
                <span style={{ fontWeight: "normal" }}>
                  {quote.ext_remarks ? quote.ext_remarks : "❌"}
                </span>
              </li>
            </ul>
          </div>

          {/* Routing section */}
          <div
            style={{
              gridColumn: "1 / -1",
              border: "1px solid black",
              borderRadius : "10px",
              flex: "1",
              padding: "5px",
              overflow: "hidden",
              fontSize: "12px",
            }}
          >
            <img src={require("../img/aeroplane.png")} alt={"Routing"} />{" "}
            <h5 style={{ color: "black" }}> Routing </h5>
            <hr
              style={{ borderColor: "#2e5676", borderTop: "1px solid #2e5676" }}
            />
            {routing.detailed_info ? (
              <>
                <div className="table-container">
                  <table
                    className="table"
                    style={{ overflowY: "auto", maxHeight: "160px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Brd Pt</th>
                        <th scope="col">Off Pt</th>
                        <th scope="col">Carrier</th>
                        <th scope="col">Flt Num</th>
                        <th scope="col">Op Type</th>
                        <th scope="col">Flt date</th>
                        <th scope="col">ETD</th>
                        <th scope="col">ETA</th>
                        <th scope="col">Split</th>
                      </tr>
                    </thead>
                    <tbody>
                      {routes.map((item, i) => (
                        <tr key={i}>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.origin}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {" "}
                            {item.dest}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.carrier}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.flight_number}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.flight_type}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.flight_date}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.etd}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.eta}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          ></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="table-container">
                  <table
                    className="table"
                    style={{ overflowY: "auto", maxHeight: "80px" }}
                  >
                    <thead>
                      <tr>
                        <th style={cellStyle}>Brd Pt</th>
                        <th style={cellStyle}>Off Pt</th>
                        <th style={cellStyle}>Carrier</th>
                        <th style={cellStyle}>Op Type</th>
                        <th style={cellStyle}>WL frequecy</th>
                        <th style={cellStyle}>Operation DOW</th>
                      </tr>
                    </thead>
                    <tbody>
                      {routes.map((item, i) => (
                        <tr key={i}>
                          <td style={cellStyle}>{item.origin}</td>
                          <td>{item.dest}</td>
                          <td style={cellStyle}>{item.carrier}</td>
                          <td style={cellStyle}>{item.flight_type}</td>
                          <td style={cellStyle}>{item.weekly_frequency}</td>
                          <td style={cellStyle}>{item.operation_dow}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

  }
}

class ExportButton extends React.Component {
  componentDidMount() {
    let w1 = watch(store_quotes.getState, "export");
    store_quotes.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() }, () => {
          document.getElementById("link_export").click();
        });
      })
    );
  }

  render() {
    return (
      <a
        id="link_export"
        download="quotes_export.xlsx"
        href={
          "data:application/octet-stream;charset=UTF-8," +
          store_quotes.getState().quotes_csv
        }
        hidden
      ></a>
    );
  }
}

class FilterButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
    this.onClickExport = this.onClickExport.bind(this);
  }

  componentDidMount() {
    let w1 = watch(store_filters.getState, "can_refresh");
    store_quotes.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  async onClickExport(e) {
    e.preventDefault();
    let params = store_filters.getState();
    if (disableExport()) return;
    let columns = [
      "Quote ID",
      "AdHoc Ref",
      "E-Quote Ext ID",
      "Station",
      "Creation Date",
      "Created By",
      "Awb Nb",
      "Agent",
      "Origin",
      "Dest",
      "Commodity Name",
      "Type",
      "Pieces",
      "Gross Weight (kg)",
      "Chargeable Weight (kg)",
      "Volume (cbm)",
      "Issue Carrier",
      "Currency",
      "Rate",
      "Type",
      "Unit",
      "All-in /kg",
      "Minimum Chg. Wgt.",
      "Minimum Amount",
      "Target All-in price",
      "All-in",
      "AdHocRef",
      "Margin /kg",
      "Margin Total",
      "Status",
      "Failure Reason",
      "Last Updated Date",
      "Last Updated By",
      "Validity From Date",
      "Validity To Date",
      "Ex Works",
      "Stackable",
      "Main Deck",
      "Density",
      "Density Ratio",
      "SHC List",
      "To Follow Up",
      "Internal Remark",
      "First Flown Origin",
    ];
    // GET QUOTES, il peut y avoir plus de quotes qur sur la page donc on fait une nouvelle requête
    let { currency_conversion } = params;
    let old_skip = params.skip;
    params.limit = 15000;
    params.skip = 0;
    params.user_follow_up = cookies.account;

    if (cookies.region !== "WLD") {
      params.hq = false;
    }

    store_quotes.dispatch({ type: 1, page_loading_spinner: true });

    let res = await Routes.getQuotes(params);

    let exports_params = {
      ...params,
      type: "EXPORT_QUOTES",
      info: res && res.quotes ? res.quotes.length : 0,
    };
    await Routes.get("/Logger", exports_params, true);

    //reset limit
    params.limit = 100;
    params.skip = old_skip;
    store_quotes.dispatch({ type: 1, page_loading_spinner: false });

    if (!res || !res.quotes) return;
    let data = [];
    res.quotes.forEach((q) => {
      let shipment = q.shipment;
      let pricing = q.pricing;
      let margin = q.margin;
      let {margin_per_kg, margin_in_total} = margin
      let routing = q.routing;
      let awb_serial = q.awb_number ? q.awb_number.split("-")[1] : null;

      let buy_all_in = margin.net_buy_edit || margin.all_in_buy_edit || margin.buy_all_in_rate;
      buy_all_in = buy_all_in ? (margin_per_kg.net_buy_edit || margin_per_kg.all_in_buy_edit || margin_per_kg.buy_all_in_rate) : ""
      buy_all_in = buy_all_in ? buy_all_in : ""

      let sell_all_in_rate = margin.sell_all_in_rate || margin_per_kg.sell_all_in_rate
      sell_all_in_rate = sell_all_in_rate ? sell_all_in_rate : 0

      let margin_value = margin.margin || margin_per_kg.margin
      margin_value = margin_value ? margin_value : 0

      let marginTotal = margin.marginTotal || margin_in_total.margin
      marginTotal = marginTotal ? marginTotal : 0

      if (
        currency_conversion !== "No Conversion" &&
        currency_conversion !== "LOCAL" &&
        pricing.currency !== currency_conversion
      ) {
        if (pricing.per_kg && pricing.price)
          pricing.price = util.convert_currency(
            pricing.price,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );
        else if (!pricing.per_kg && pricing.price_total)
          pricing.price_total = util.convert_currency(
            pricing.price_total,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );
        if (sell_all_in_rate)
          sell_all_in_rate = util.convert_currency(
            sell_all_in_rate,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );
        if (pricing.min_amount)
          pricing.min_amount = util.convert_currency(
            pricing.min_amount,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );
        if (buy_all_in)
          buy_all_in = util.convert_currency(
            buy_all_in,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );
        if (margin_value)
        margin_value = util.convert_currency(
          margin_value,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );
        if (marginTotal)
          marginTotal = util.convert_currency(
            marginTotal,
            pricing.currency,
            currency_conversion,
            this.props.exch_rate
          );

        pricing.currency = currency_conversion;
      }

      let flight_routes =
        routing && routing.routes
          ? routing.routes.filter((r) => r.fligh_type !== "Truck")
          : [];
      let first_flown_origin =
        flight_routes && flight_routes[0] ? flight_routes[0].origin : "";

      data.push([
        q.id,
        q.adhoc_ref ? parseInt(q.adhoc_ref) : "",
        q.external_id,
        q.station,
        util.convertDate(q.time_stamp),
        q.user,
        awb_serial && awb_serial.length === 8 ? q.awb_number + "" : "",
        shipment.agent ? shipment.agent : "",
        shipment.origin ? shipment.origin : "",
        shipment.dest ? shipment.dest : "",
        shipment.commodity,
        shipment.dimension_type === "uld" ? "ULD" : "Loose",
        shipment.pieces ? shipment.pieces : "",
        shipment.gross_weight ? shipment.gross_weight : "",
        shipment.chargeable_weight ? shipment.chargeable_weight : "",
        shipment.volume ? parseFloat(shipment.volume).toFixed(2) : "",
        pricing.carrier ? pricing.carrier : "",
        pricing.currency ? pricing.currency : "",
        pricing.per_kg
          ? pricing.price
            ? pricing.price
            : ""
          : pricing.price_total
          ? pricing.price_total
          : "",
        pricing.all_in ? "all-in" : "net net",
        pricing.per_kg ? "/kg" : "Total",
        sell_all_in_rate ? sell_all_in_rate : "",
        pricing.min_weight ? pricing.min_weight : 0,
        pricing.min_amount ? pricing.min_amount : 0,
        pricing.target_price ? pricing.target_price : 0,
        buy_all_in ? buy_all_in : "",
        q.adhoc_ref_buy ? parseInt(q.adhoc_ref_buy) : "",
        margin_value ? margin_value : "",
        marginTotal ? marginTotal : "",
        q.status ? q.status : "",
        (q.failure_reason ? q.failure_reason : "") +
          (q.failure_reason_exp
            ? " : " + q.failure_reason_exp.replace(/\n/g, " ")
            : ""),
        util.convertDate(q.last_update),
        q.last_updated_by ? q.last_updated_by : "",
        util.convertDate(pricing.from_date),
        util.convertDate(pricing.to_date),
        shipment.exw ? "Yes" : "No",
        shipment.stackable
          ? shipment.dimension_type === "uld"
            ? "No"
            : shipment.stackable
          : "No",
        shipment.main_deck ? "Yes" : "No",
        shipment.density ? shipment.density : "",
        shipment.density ? 1 + "/" + parseInt(1000 / shipment.density) : "",
        shipment.shc ? shipment.shc.filter((s) => s !== "").join(",") : "",
        q.follow_up ? "Yes" : "No",
        q.int_remarks
          ? q.int_remarks.replace(/\n/g, " ").replace(/#/g, "")
          : "",
        first_flown_origin ? first_flown_origin : "",
      ]);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("quotes_list");

    worksheet.getCell("A1").value = "IDENTIFICATION";
    worksheet.getCell("G1").value = "SHIPMENT DETAILS";
    worksheet.getCell("Q1").value = "SELL ADHOC";
    worksheet.getCell("Z1").value = "BUY ADHOC";
    worksheet.getCell("AB1").value = "EXPECTED MARGIN";
    worksheet.getCell("AD1").value = "OTHER INFORMATION";

    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("G1:P1");
    worksheet.mergeCells("Q1:Y1");
    worksheet.mergeCells("Z1:AA1");
    worksheet.mergeCells("AB1:AC1");
    worksheet.mergeCells("AD1:AR1");
    /////TABLE DES QUOTES & ENTETES
    worksheet.addTable({
      name: "QuotesTable",
      ref: "A2",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
      },
      columns: columns.map((c) => {
        return { name: c, filterButton: true };
      }),
      rows: data,
    });
    //formattage des en têtes
    worksheet.getRow(2).eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: "€" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "darkVertical",
        bgColor: { argb: "ff3c50b5" },
        fgColor: { argb: "ff3c50b5" },
      };
      cell.font = {
        name: "Arial Black",
        color: { argb: "FFFFFFFF" },
        family: 2,
        size: 11,
      };
    });
    //formattage  1ere ligne
    worksheet.getRow(1).eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: "€" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "darkVertical",
        bgColor: { argb: "ff1e3194" },
        fgColor: { argb: "ff1e3194" },
      };
      cell.font = {
        name: "Arial Black",
        color: { argb: "FFFFFFFF" },
        family: 2,
        size: 11,
      };
    });
    //formattage bordure des colonnes
    let columns_ = [1, 7, 17, 26, 28, 30];
    columns_.forEach((i) =>
      worksheet.getColumn(i).eachCell((cell) => {
        cell.border = {
          left: { style: "thick", color: { argb: "FF000000" } },
        };
      })
    );
    // save under export.xlsx
    workbook.xlsx.writeBuffer("export.xlsx").then(function (data) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let filename =
        "Quotes_Extract_" + util.convertDate(new Date(), false, ".") + ".XLSX";
      saveAs(blob, filename);
    });
  }

  onClickReset() {
    store_filters.dispatch({ type: 1, reset: true });
  }

  render() {
    return (
      <div key={this.state.key}>
        {cookies && cookies.rights && cookies.rights.list_open_extract ? (
          <button
            type="button"
            class="btn btn-success"
            ata-type="info"
            data-tip={
              this.props.disabled
                ? "Selected date range shall be maximum 31 days."
                : ""
            }
            delay-hide={1000}
            onClick={this.onClickExport}
          >
            <GetAppIcon fontSize="small" />
          </button>
        ) : null}

        <button
          type="button"
          class="btn btn-danger"
          data-type="info"
          data-tip={"Clear Search"}
          delay-hide={1000}
          onClick={this.onClickReset}
        >
          <Clear fontSize="small" />
        </button>
        <ReactTooltip place="top" effect="solid" delayHide={500} />

        <ExportButton />
      </div>
    );
  }
}

class PageMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {}

  async onClickFirst(e) {
    if (store_quotes.getState().quotes.length > 0)
      store_filters.dispatch({ type: 1, skip: 0 });
    window.scroll(0, 0);
  }

  async onClickNext(e) {
    if (store_quotes.getState().quotes.length > 0)
      store_filters.dispatch({ type: 1, skip_plus: true });
    window.scroll(0, 0);
  }

  async onClickPrevious(e) {
    store_filters.dispatch({ type: 1, skip_minus: true });
    window.scroll(0, 0);
  }

  async onClickLast(e) {
    let nbPages = Math.ceil(store_quotes.getState().countQuotes / 100);
    if (store_quotes.getState().quotes.length > 0)
      store_filters.dispatch({ type: 1, skip: (nbPages - 1) * 100 });

    window.scroll(0, 0);
  }

  render() {
    let nbPages = Math.ceil(store_quotes.getState().countQuotes / 100);
    let filters_state = store_filters.getState();
    let skip = filters_state.skip;
    let currentPage = 1 + skip / 100;

    return (
      <div
        class="row page-menu"
        key={this.state.key}
        style={{ margin: "0.1em" }}
      >
        <button
          type="button"
          class="btn btn-primary"
          style={{ width: "30px" }}
          disabled={currentPage === 1 ? "true" : null}
          onClick={async (e) => {
            this.onClickFirst(e);
          }}
        >
          1
        </button>

        <button
          type="button"
          class="btn btn-primary"
          style={{ width: "30px" }}
          onClick={async (e) => {
            this.onClickPrevious(e);
          }}
        >
          {" << "}
        </button>

        <button type="button" class="btn btn-primary" disabled={true}>
          {currentPage}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          style={{ width: "30px" }}
          onClick={async (e) => {
            this.onClickNext(e);
          }}
        >
          {" >> "}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          disabled={currentPage === nbPages ? "true" : null}
          onClick={async (e) => {
            this.onClickLast(e);
          }}
        >
          {nbPages}
        </button>
      </div>
    );
  }
}

class StatusDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
    this.getQuote = this.getQuote.bind(this);
  }

  componentDidMount() {
    let w1 = watch(store_quotes.getState, "force");
    store_quotes.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now(), scrolled: false });
      })
    );
  }

  getQuote() {
    //obtenir tous les champs de la quote à partir de l'id
    let { quotes } = store_quotes.getState();
    let { id } = this.props;
    let quote = quotes.filter((q) => q.id === id)[0];
    if (!quote) return null;
    return quote;
  }

  checkMandatory() {
    // on vérifie si les champs minimums sont bien remplis dans la quote pour qu'on puisse la passer en BOOKED
    let quote = this.getQuote();

    if (!quote) return false;

    try {
      let shipment = quote.shipment;
      let pricing = quote.pricing;

      if (
        !shipment.chargeable_weight ||
        !shipment.gross_weight ||
        !shipment.volume ||
        !shipment.dest ||
        !shipment.origin ||
        !shipment.agent_code ||
        !shipment.agent ||
        (pricing.per_kg && !pricing.price) ||
        (!pricing.per_kg && !pricing.price_total) ||
        !pricing.carrier ||
        shipment.external_id
      )
        return false;
      else return true;
    } catch (e) {
      return false;
    }
  }
  async handleChange(e) {
    let { id, follow_up_id } = this.props;
    let status = e.target.value;
    store_quotes.dispatch({ type: 1, status: status, id: id, force: true });
    this.setState({ key: Date.now() });

    if (status !== "BOOKED" && status !== "REJECTED" && status !== "DELETED")
      await Routes.saveQuote({
        status: status,
        id: id,
        failure_reason: status !== "REJECTED" ? " " : undefined,
        failure_reason_exp: status !== "REJECTED" ? "" : undefined,
        user: cookies.account,
      });

    let quote_history = this.getQuote();
    quote_history.changes = "status";
    store_quotes.dispatch({ type: 1, quote_history: quote_history });

    if (status !== "BOOKED" && status !== "REJECTED" && status !== "DELETED")
      await saveQuoteHistory(quote_history);
    if (status === "BOOKED" || status === "REJECTED" || status === "DELETED")
      store_quotes.dispatch({ type: 1, modalUpdateState: true });
    if (status !== "QUOTED" && follow_up_id)
      followUp({ quote_id: id, id: follow_up_id, active: false });
  }

  render() {
    const { id } = this.props;
    let { quotes } = store_quotes.getState();
    let quote = quotes.filter((q) => q.id === id)[0];

    if (!quote) return null;
    let state = quote.status;

    let bgcolor = "";
    let status_props = util.STATUS.filter((s) => s.name === state)[0];
    if (!status_props)
      status_props = util.STATUS.filter((s) => s.name === "DRAFT")[0];
    bgcolor = status_props.color;

    let style = {
      fontSize: "0.7em",
      backgroundColor: bgcolor,
      color: state === "QUOTED" ? "black" : "white",
    };

    let auth = cookies && cookies.rights && cookies.rights.list_change_status;
    return (
      <div>
        <select
          class="form-control dropdown"
          tabindex="-1"
          style={style}
          key={this.state.key}
          onChange={async (e) => {
            this.handleChange(e);
          }}
        >
          <option>{state}</option>
          {util.STATUS.filter((s) => s.name !== "QUEUED").map((s) => {
            s = s.name;

            if (s === state) return;

            if (
              !auth ||
              s === "EXPIRED" ||
              (s !== "DRAFT" && !this.checkMandatory()) ||
              state === "QUEUED"
            )
              return <option disabled="true">{s}</option>;
            else if (
              s === "DELETED" &&
              cookies.role !== "admin" &&
              cookies.role !== "station_admin"
            )
              return <option disabled="true">{s}</option>;
            else return <option> {s}</option>;
          })}
        </select>
        <ReactTooltip place="top" type="error" effect="solid" delayHide={500} />
      </div>
    );
  }
}

class CurrencyDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now(), current: { name: "No Conversion" } };
    this.choices = [
      { name: "No Conversion" },
      { name: "EUR" },
      { name: "USD" },
    ];
  }

  componentDidMount() {
    const { default_currency } = this.props;

    if (
      this.choices.filter((c) => c.name === default_currency)[0] === undefined
    ) {
      this.choices.push({ name: default_currency });
      this.setState({ key: Date.now() });
    }
  }

  async handleChange(e) {
    store_filters.dispatch({ type: 1, currency_conversion: e.target.value });
  }

  render() {
    const { currency_conversion } = store_filters.getState();

    return (
      <div>
        <label>Currency</label>
        <select
          class="form-control dropdown"
          tabindex="-1"
          key={this.state.key}
          onChange={async (e) => {
            this.handleChange(e);
          }}
        >
          <option>{currency_conversion}</option>
          {this.choices
            .filter((c) => c.name !== currency_conversion)
            .map((c) => (
              <option> {c.name} </option>
            ))}
        </select>
        <ReactTooltip place="top" type="error" effect="solid" delayHide={500} />
      </div>
    );
  }
}

class FilterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      scrolled: false,
      options: [
        { value: null, name: "All" },
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
    };
  }

  componentDidMount() {
    let w1 = watch(store_filters.getState, "reset");
    store_filters.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now(), scrolled: false });
      })
    );

    let w2 = watch(store_quotes.getState, "quotes");
    store_quotes.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now(), scrolled: false });
      })
    );

    let w3 = watch(store_filters.getState, "force");
    store_filters.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now(), scrolled: false });
      })
    );
  }

  updateForm(e) {
    let json = { type: 1 };
    json[e.target.name] = e.target.value;
    if (store_filters.getState()[e.target.name] !== e.target.value)
      store_filters.dispatch(json);
  }

  focus(e) {
    store_quotes.dispatch({ type: 1, current_input: e.target.id });
  }

  render() {
    const filters_state = store_filters.getState();
    let station_config = this.props.custom_list.filter(
      (c) => c.station === cookies.station && c.carrier === "***"
    )[0];
    station_config = station_config ? station_config.config : null;
    let default_currency = station_config
      ? station_config.default_currency
      : null;
    let hq_curr = this.state.options.filter((h) => {
      return h.value === filters_state.hq;
    })[0];
    let follow_up = this.state.options.filter((f) => {
      return f.value === filters_state.follow_up;
    })[0];

    let commodity_codes = this.props.shc_list
      ? this.props.shc_list
          .map((shc) => {
            return shc.commodity_code;
          })
          .filter((c, i, self) => self.indexOf(c) === i)
      : [];

      console.log("KAOUTARBLG : ", filters_state )
    return (
      <div key={this.state.key} class="filter-menu">
        <div class="row" style={{ marginRight: "1em" }}>
          <div class="col-2">
            <FilterButtons
              disabled={disableExport()}
              exch_rate={this.props.exch_rate}
            />
            <PageMenu authorized_stations={this.props.authorized_stations} />
          </div>

          <div class="col-10" id="filter-items">
            <div class="row row-filter">
              <div class="col-1">
                <label>ID</label>
                <input
                  id="filter_id"
                  name="id"
                  type="text"
                  class="form-control "
                  defaultValue={filters_state.id}
                  onFocus={this.focus}
                  onBlur={this.updateForm}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Adhoc Ref</label>
                <input
                  id="filter_adhoc_ref"
                  type="text"
                  class="form-control"
                  defaultValue={filters_state.adhoc_ref}
                  name="adhoc_ref"
                  onFocus={this.focus}
                  onBlur={this.updateForm}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-2">
                <label style={{ marginLeft: "0.8em" }}>AWB Number</label>
                <div
                  class="row"
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                >
                  <div class="col-4">
                    <input
                      type="text"
                      class="form-control"
                      defaultValue={filters_state.awb_prefix}
                      maxLength="3"
                      id="awb_prefix_filter"
                      name="awb_prefix"
                      onFocus={this.focus}
                      onBlur={(e) => {
                        this.updateForm(e);
                        if (e.target.value.length === 3) {
                          let next_input =
                            document.getElementById("awb_serial_filter");
                          if (next_input) next_input.focus();
                        }
                      }}
                      onKeyUp={async (e) => {
                        if (e.keyCode === 13) this.updateForm(e);
                      }}
                    />
                  </div>
                  <div class="col-8">
                    <input
                      type="text"
                      class="form-control"
                      defaultValue={filters_state.awb_serial}
                      maxLength="8"
                      id="awb_serial_filter"
                      name="awb_serial"
                      onFocus={this.focus}
                      onKeyUp={async (e) => {
                        if (e.keyCode === 13) this.updateForm(e);
                      }}
                      onBlur={this.updateForm}
                    />
                  </div>
                </div>
              </div>

              <div class="col-1">
                <label>Status</label>
                <select
                  class="form-control dropdown"
                  id="inputGroupSelect01"
                  name="status"
                  onFocus={this.focus}
                  onChange={this.updateForm}
                >
                  <option value={filters_state.status}>
                    {filters_state.status.toUpperCase()}
                  </option>
                  {filters_state.status === "All" ? null : (
                    <option value="All">ALL</option>
                  )}
                  {util.STATUS.map((s) => {
                    if (s.name === filters_state.status) return null;
                    else
                      return (
                        <option value={s.name}>{s.name.toUpperCase()}</option>
                      );
                  })}
                </select>
              </div>

              <div class="col-2">
                <label>Created by</label>
                <input
                  id="filter_user"
                  name="user"
                  type="text"
                  class="form-control"
                  defaultValue={filters_state.user}
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Issue Carrier</label>
                <input
                  id="filter_carrier"
                  type="text"
                  class="form-control"
                  maxlength="3"
                  name="carrier"
                  defaultValue={filters_state.carrier}
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-2">
                <label>Agent</label>
                <input
                  id="filter_agent"
                  type="text"
                  class="form-control"
                  defaultValue={filters_state.agent}
                  name="agent"
                  maxLength={11}
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Station</label>
                <select
                  class="form-control dropdown"
                  id="filter_station"
                  name="station"
                  onFocus={this.focus}
                  onChange={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                >
                  <option value={filters_state.station}>
                    {filters_state.station}{" "}
                  </option>
                  {filters_state.station === "All" ? null : (
                    <option value="All"> ALL </option>
                  )}
                  {this.props.authorized_stations
                    .filter((s) => s.station !== filters_state.station)
                    .map((s) => {
                      return (
                        <option value={s.station}>
                          {s.country_code} - {s.station_name} - {s.station}{" "}
                        </option>
                      );
                    })}
                </select>
              </div>

              {cookies.region === "WLD" && (
                <div class="col-1">
                  <label>HQ</label>
                  <select
                    class="form-control dropdown"
                    id="inputHQ"
                    name="hq"
                    onFocus={this.focus}
                    onChange={(e) => {
                      this.updateForm(e);
                    }}
                    onKeyUp={async (e) => {
                      if (e.keyCode === 13) this.updateForm(e);
                    }}
                  >
                    <option value={filters_state.hq}>{hq_curr.name} </option>
                    {this.state.options.map((e) => {
                      if (e.value !== filters_state.hq)
                        return <option value={e.value}> {e.name} </option>;
                      else return null;
                    })}
                  </select>
                </div>
              )}
            </div>

            <div class="row row-filter">
              <div class="col-1 smaller-col">
                <label>Origin</label>
                <input
                  id="filter_origin"
                  type="text"
                  class="form-control smaller-input"
                  name="origin"
                  defaultValue={filters_state.origin}
                  maxlength="3"
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Destination</label>
                <input
                  id="filter_dest"
                  type="text"
                  name="dest"
                  class="form-control"
                  defaultValue={filters_state.dest}
                  maxlength="3"
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Min. Chg. Weight</label>
                <input
                  id="filter_min_wgt"
                  type="text"
                  name="min_weight"
                  class="form-control"
                  defaultValue={filters_state.min_weight}
                  maxlength="6"
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Max. Chg. Weight</label>
                <input
                  id="max_wgt"
                  type="text"
                  name="max_weight"
                  class="form-control"
                  defaultValue={filters_state.max_weight}
                  maxlength="6"
                  onFocus={this.focus}
                  onBlur={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                />
              </div>

              <div class="col-1">
                <label>Decks</label>
                <select
                  class="form-control dropdown"
                  id="filter_deck"
                  name="deck"
                  onFocus={this.focus}
                  onChange={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                >
                  <option value={filters_state.deck}>
                    {filters_state.deck === "main"
                      ? "Main Deck"
                      : filters_state.deck === "lower"
                      ? "Lower Deck"
                      : "All"}{" "}
                  </option>
                  {filters_state.deck === "All" ? null : (
                    <option value="All"> All </option>
                  )}
                  {util.DECKS.filter((d) => d !== filters_state.deck).map(
                    (d) => {
                      return (
                        <option value={d}>
                          {d === "main" ? "Main Deck" : "Lower Deck"}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>

              <div class="col-1">
                <label>Loose/ULD</label>
                <select
                  class="form-control dropdown"
                  id="filter_dimension_type"
                  name="dimension_type"
                  onFocus={this.focus}
                  onChange={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                >
                  <option value={filters_state.dimension_type}>
                    {filters_state.dimension_type === "uld"
                      ? "ULD"
                      : filters_state.dimension_type === "loose"
                      ? "Loose"
                      : "All"}{" "}
                  </option>
                  {filters_state.dimension_type === "All" ? null : (
                    <option value="All"> All </option>
                  )}
                  {util.DIMENSION_TYPES.filter(
                    (t) => t !== filters_state.dimension_type
                  ).map((t) => {
                    return (
                      <option value={t}>{t === "uld" ? "ULD" : "Loose"}</option>
                    );
                  })}
                </select>
              </div>

              <div class="col-1">
                <label>Commodity</label>
                <select
                  class="form-control dropdown"
                  id="filter_commodity"
                  name="commodity_code"
                  onFocus={this.focus}
                  onChange={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                >
                  <option value={filters_state.commodity_code}>
                    {filters_state.commodity_code}{" "}
                  </option>
                  {filters_state.commodity_code === "All" ? null : (
                    <option value="All"> ALL </option>
                  )}
                  {commodity_codes
                    .filter((c) => c !== filters_state.commodity_code)
                    .map((c) => {
                      return <option value={c}>{c}</option>;
                    })}
                </select>
              </div>

              {/* <div class="row">
                <div class="col-6" style={{ paddingTop: "0.5em" }}>
                  <form noValidate>
                    <TextField
                      style={{ flex: 1, minWidth: "7em" }}
                      id="from_date_1"
                      label="Created From Date"
                      type="date"
                      defaultValue={util.dateToString3(filters_state.from_date)}
                      onFocus={this.focus}
                      onBlur={(e) => {
                        let from_date = e.target.value;
                        if (
                          from_date &&
                          new Date(from_date).getFullYear() > 2000
                        )
                          store_filters.dispatch({
                            type: 1,
                            from_date: e.target.value,
                          });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="to_date_1"
                      label="Created To Date"
                      style={{ flex: 1, minWidth: "7em", marginLeft: "0.5em" }}
                      type="date"
                      defaultValue={util.dateToString3(filters_state.to_date)}
                      onFocus={this.focus}
                      onBlur={(e) => {
                        let to_date = e.target.value;
                        if (to_date && new Date(to_date).getFullYear() > 2000)
                          store_filters.dispatch({
                            type: 1,
                            to_date: e.target.value,
                          });
                        store_quotes.dispatch({ type: 1, current_input: null });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </div>

                <div class="col-6" style={{ paddingTop: "0.5em" }}>
                  <form noValidate>
                    <TextField
                      style={{ flex: 1, minWidth: "7em" }}
                      id="flight_from_date_2"
                      label="First Flt From Date"
                      type="date"
                      defaultValue={util.dateToString3(
                        filters_state.first_flight_fromDate
                      )}
                      onFocus={this.focus}
                      onBlur={(e) => {
                        let first_flight_fromDate = e.target.value;
                        if (
                          first_flight_fromDate &&
                          new Date(first_flight_fromDate).getFullYear() > 2000
                        )
                          store_filters.dispatch({
                            type: 1,
                            first_flight_fromDate: e.target.value,
                          });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="flight_to_date_2"
                      label="First Flt To Date"
                      style={{ flex: 1, minWidth: "7em", marginLeft: "0.5em" }}
                      type="date"
                      defaultValue={util.dateToString3(
                        filters_state.first_flight_toDate
                      )}
                      onFocus={this.focus}
                      onBlur={(e) => {
                        let first_flight_toDate = e.target.value;
                        if (
                          first_flight_toDate &&
                          new Date(first_flight_toDate).getFullYear() > 2000
                        )
                          store_filters.dispatch({
                            type: 1,
                            first_flight_toDate: e.target.value,
                          });
                        store_quotes.dispatch({ type: 1, current_input: null });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </div>
              </div> */}

              <div class="col-3" style={{ paddingTop: "0.5em" }}>
                <form noValidate>
                  <TextField
                    style={{ minWidth: "10em" }}
                    id="from_date"
                    label="Created From Date"
                    type="date"
                    defaultValue={util.dateToString3(filters_state.from_date)}
                    onFocus={this.focus}
                    onBlur={(e) => {
                      let from_date = e.target.value;
                      if (from_date && new Date(from_date).getFullYear() > 2000)
                        store_filters.dispatch({
                          type: 1,
                          from_date: e.target.value,
                        });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="to_date"
                    label="Created To Date"
                    style={{ minWidth: "10em", marginLeft: "0.5em" }}
                    type="date"
                    defaultValue={util.dateToString3(filters_state.to_date)}
                    onFocus={this.focus}
                    onBlur={(e) => {
                      let to_date = e.target.value;
                      if (to_date && new Date(to_date).getFullYear() > 2000)
                        store_filters.dispatch({
                          type: 1,
                          to_date: e.target.value,
                        });
                      store_quotes.dispatch({ type: 1, current_input: null });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              </div>

              <div class="col-1">
                <label>Follow Up</label>
                <select
                  class="form-control dropdown"
                  name="follow_up"
                  onFocus={this.focus}
                  onChange={(e) => {
                    this.updateForm(e);
                  }}
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) this.updateForm(e);
                  }}
                >
                  <option value={filters_state.follow_up}>
                    {follow_up.name}{" "}
                  </option>
                  {this.state.options.map((e) => {
                    if (e.value !== filters_state.follow_up)
                      return <option value={e.value}> {e.name} </option>;
                    else return null;
                  })}
                </select>
              </div>
              <div class="col-1">
                <CurrencyDropdown
                  custom_list={this.props.custom_list}
                  default_currency={default_currency}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ModalUpdateState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      key2: Date.now(),
      prefix: null,
      serial: null,
      reason: util.REJECTED_REASONS[0],
      reason_exp: "",
      current_input: "status_prefix",
      error: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveQuit = this.saveQuit.bind(this);
    this.validate = this.validate.bind(this);
    this.init = this.init.bind(this);
    this.handleChangeReason = this.handleChangeReason.bind(this);
  }

  componentDidMount() {
    let w = watch(store_quotes.getState, "modalUpdateState");
    store_quotes.subscribe(
      w((newVal, oldVal, objectPath) => {
        console.log("%s changed from %s to %s", objectPath, oldVal, newVal);
        if (oldVal !== newVal) this.setState({ key: Date.now() });
        if (newVal) this.init();
      })
    );
  }

  async init() {
    let carrier_prefix = this.getQuoteCarrierPrefix();
    this.setState({ key2: Date.now(), prefix: carrier_prefix });
  }

  componentDidUpdate() {
    let input = document.getElementById(this.state.current_input);
    if (!input) return;
    input.focus();
    input.selectionStart = this.state.selectionStart;
    input.selectionEnd = this.state.selectionStart;
  }

  getQuoteCarrierPrefix() {
    let { quotes, quote_toUpdate } = store_quotes.getState();
    let quote = quotes.filter((q) => q.id === quote_toUpdate)[0];
    if (!quote) return null;
    try {
      return quote.pricing.carrier_prefix;
    } catch (e) {
      return null;
    }
  }

  getQuoteState() {
    let { quotes, quote_toUpdate } = store_quotes.getState();
    let quote = quotes.filter((q) => q.id === quote_toUpdate)[0];
    if (!quote) return null;
    return quote.status;
  }

  getQuoteUser() {
    let { quotes, quote_toUpdate } = store_quotes.getState();
    let quote = quotes.filter((q) => q.id === quote_toUpdate)[0];
    if (!quote) return null;
    return quote.user;
  }

  async saveQuit() {
    let { prefix, serial, reason, reason_exp } = this.state;
    let { quote_toUpdate, quote_history } = store_quotes.getState();
    let status = this.getQuoteState();

    if (!this.validate()) return;

    let params = { id: quote_toUpdate, user: cookies.account };

    if (status === "BOOKED") {
      params.status = "BOOKED";
      params.awb_number = prefix + "-" + serial;
      params.failure_reason = " "; //in case quote state was rejected before
      params.failure_reason_exp = "";
    } else if (status === "REJECTED") {
      params.status = "REJECTED";
      params.failure_reason = reason;
      params.failure_reason_exp = reason_exp ? reason_exp : "";
      params.awb_number = "";
    } else if (status === "DELETED") {
      params.status = "DELETED";
      params.failure_reason = reason ? reason : "";
      params.failure_reason_exp = "";
      params.awb_number = "";
    }

    if (quote_history && status === "BOOKED") {
      quote_history.status = status;
      quote_history.serial = serial ? serial : "";
      quote_history.changes = "status,awb_serial";
    }

    if (quote_history && (status === "REJECTED" || status === "DELETED")) {
      quote_history.status = status;
      quote_history.changes = "status";
    }

    store_quotes.dispatch({ type: 1, modalUpdateState: false });
    store_quotes.dispatch({ type: 1, page_loading_spinner: true });
    await Routes.saveQuote(params);
    //store_quotes.dispatch({type:1, page_loading_spinner : false })

    if (status === "BOOKED" || status === "REJECTED" || status === "DELETED")
      await saveQuoteHistory(quote_history);

    this.setState({ reason: "", error: "" });
    store_quotes.dispatch({ type: 1, page_loading_spinner: false });
  }

  async handleChange(e) {
    e.preventDefault();
    let json = {};
    json[e.target.name] = e.target.value;
    json.key2 = Date.now();
    this.state[e.target.name] = e.target.value;
  }

  validate() {
    let { prefix, serial, reason, reason_exp } = this.state;
    let state = this.getQuoteState();
    let user = this.getQuoteUser();
    let limit = 3;
    let currentCount = 1;
    let maxCount = 1;

    if (state === "BOOKED") {
      if (!prefix)
        return this.setState({ key2: Date.now(), error: "Please fill prefix" });
      else if (prefix.length !== 3)
        return this.setState({
          key2: Date.now(),
          error: "Prefix length must be 3 characters.",
        });
      else if (serial && serial.length !== 8)
        return this.setState({
          key2: Date.now(),
          error: "Serial length must be 8 characters.",
        });
    } else if (state === "REJECTED") {
      // if(!reason){ //DEFAULT
      //   //reason = util.REJECTED_REASONS[0] ? util.REJECTED_REASONS[0] : this.state.reason
      // }

      if (!reason) {
        return this.setState({
          key2: Date.now(),
          error: "Please provide reason",
        });
      }

      if (
        !reason_exp &&
        ((user && user.toLowerCase() === "cargoai") ||
          (reason && reason.toLowerCase() === "other"))
      )
        return this.setState({
          key2: Date.now(),
          error: "Please provide detailled reason",
        });
    } else if (state === "DELETED") {
      reason =
        this.state.reason === util.REJECTED_REASONS[0] ? "" : this.state.reason;
      for (let i = 1; i < reason.length; i++) {
        if (reason[i] === reason[i - 1]) {
          currentCount++;
        } else {
          currentCount = 1;
        }
        maxCount = Math.max(maxCount, currentCount);
      }
      if (maxCount > limit) {
        return this.setState({
          key2: Date.now(),
          error: "Consecutive characters are more than three times",
        });
      }

      if (!reason) {
        return this.setState({
          key2: Date.now(),
          error: "Please provide detailled reason",
        });
      }

      if (reason && reason.length < 30) {
        let reason_format = "The minimum number of characters to write is 30.";
        reason_format =
          reason_format + `\nYou have ${30 - reason.length} character(s) left.`;

        return this.setState({
          key2: Date.now(),
          error: <Interweave content={reason_format} />,
        });
      }
    }

    this.setState({ key2: Date.now(), error: null });
    return true;
  }

  async handleChangeReason(e) {
    this.setState({ reason: e.target.value, key: Date.now() });
  }

  onClose() {
    //this.setState({reason : "", error : ""})
    let status = store_filters.getState().status;
    store_filters.dispatch({ type: 1, status: status });
    store_quotes.dispatch({ type: 1, modalUpdateState: false });
  }

  render() {
    let { quote_toUpdate, modalUpdateState } = store_quotes.getState();
    let status = this.getQuoteState();
    let user = this.getQuoteUser();
    let show = modalUpdateState;
    let { prefix, serial, key, key2, error, reason, reason_exp } = this.state;

    if (!show) return null;
    return (
      <Modal show={show} onHide={this.onClose} key={key}>
        <Modal.Header closeButton>
          <Modal.Title> Status update for quote {quote_toUpdate} </Modal.Title>
        </Modal.Header>
        <Modal.Body key={key2}>
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {status === "BOOKED" && (
            <div class="row">
              <div class="col-3">
                <label>Prefix</label>
                <input
                  type="text"
                  class="form-control"
                  defaultValue={prefix}
                  name="prefix"
                  id="status_prefix"
                  onFocus={(e) => this.setState({ current_input: e.target.id })}
                  onChange={this.handleChange}
                  maxlength="3"
                />
              </div>

              <div class="col-5">
                <label>Serial</label>
                <input
                  type="text"
                  class="form-control"
                  defaultValue={serial}
                  name="serial"
                  id="status_serial"
                  onFocus={(e) => this.setState({ current_input: e.target.id })}
                  onChange={this.handleChange}
                  maxlength="8"
                />
              </div>

              <div class="col-2">
                <button
                  type="button"
                  class="btn btn-success small-icon  centered"
                  onClick={this.saveQuit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {status === "REJECTED" && (
            <>
              <div class="row">
                <div class="col-1"></div>

                <div class="col-10">
                  <label>Reason for rejection</label>
                  <select
                    id="status-reason"
                    class="custom-select"
                    onChange={this.handleChangeReason}
                  >
                    {<option value={reason}> {reason}</option>}
                    {util.REJECTED_REASONS.map((r) => {
                      if (r !== reason) return <option value={r}> {r} </option>;
                      else return null;
                    })}
                  </select>

                  <br />
                  <br />

                  {((user && user.toLowerCase() === "cargoai") ||
                    (reason && reason.toLowerCase() === "other")) && (
                    <textarea
                      class="form-control"
                      style={{ border: error ? "1px solid red" : "" }}
                      aria-label="With textarea"
                      name="reason_exp"
                      maxlength="300"
                      defaultValue={reason_exp}
                      onChange={this.handleChange}
                    ></textarea>
                  )}
                </div>

                <div class="col-1"></div>
              </div>

              <br />

              <div class="row">
                <div class="col-4"></div>

                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={this.saveQuit}
                  >
                    Save
                  </button>
                </div>

                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={async () => {
                      store_quotes.dispatch({
                        type: 1,
                        modalUpdateState: false,
                      });
                      let status = store_filters.getState().status;
                      store_filters.dispatch({ type: 1, status: status });
                    }}
                  >
                    Cancel
                  </button>
                </div>

                <div class="col-4"></div>
              </div>
            </>
          )}

          {status === "DELETED" && (
            <>
              <div class="row">
                <div class="col-1"></div>

                <div class="col-10">
                  <br />
                  <br />
                  <textarea
                    class="form-control"
                    style={{ border: this.state.error ? "1px solid red" : "" }}
                    aria-label="With textarea"
                    name="reason"
                    maxlength="100"
                    defaultValue={
                      this.state.reason === util.REJECTED_REASONS[0]
                        ? ""
                        : this.state.reason
                    }
                    onChange={this.handleChange}
                  ></textarea>
                </div>

                <div class="col-1"></div>
              </div>

              <br />

              <div class="row">
                <div class="col-4"></div>

                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={this.saveQuit}
                  >
                    Save
                  </button>
                </div>

                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={async () => {
                      this.setState({ reason: "", error: "" });
                      store_quotes.dispatch({
                        type: 1,
                        modalUpdateState: false,
                      });
                      let status = store_filters.getState().status;
                      store_filters.dispatch({ type: 1, status: status });
                    }}
                  >
                    Cancel
                  </button>
                </div>

                <div class="col-4"></div>
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class PageLoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
    this.style = {
      position: "absolute",
      left: "50%",
      margin: "auto",
      "margin-top": "20em",
      "z-index": "10",
      color: "white",
    };
  }

  componentDidMount() {
    let w1 = watch(store_quotes.getState, "page_loading_spinner");
    store_quotes.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let show = store_quotes.getState().page_loading_spinner;

    if (!show) return null;
    else {
      return (
        <div key={this.state} style={this.style} class="" role="status">
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      );
    }
  }
}

function dateToString(datetime) {
  return (
    ("0" + datetime.getDate()).slice(-2) +
    "/" +
    ("0" + (datetime.getMonth() + 1)).slice(-2) +
    "/" +
    datetime.getFullYear() +
    " " +
    ("0" + datetime.getHours()).slice(-2) +
    ":" +
    ("0" + datetime.getMinutes()).slice(-2)
  );
}

function disableExport() {
  let filters = store_filters.getState();
  let quotes = store_quotes.getState().quotes;

  if (!filters.from_date || !filters.to_date) {
    return true;
  } else {
    let from_date = new Date(filters.from_date).getTime();
    let to_date = new Date(filters.to_date).getTime();
    let diff_date = (to_date - from_date) / (1000 * 3600 * 24);

    if (diff_date > 31 || diff_date < 0) return true;
    else return quotes && quotes.length > 0 ? false : true;
  }
}

async function followUp(params) {
  params.user = cookies.account;
  await Routes.updateFollowUp(params);
}

async function saveQuoteHistory(quote) {
  if (!quote || !quote.id) return;

  let shipment = quote.shipment;
  let pricing = quote.pricing;
  let margin = quote.margin;
  let {margin_per_kg} = margin
  let all_in_buy_edit = margin.all_in_buy_edit || margin_per_kg.all_in_buy_edit
  let net_buy_edit = margin.net_buy_edit || margin_per_kg.net_buy_edit

  var params = {
    id: quote.id,
    changes: quote.changes,
    status: quote.status,
    adhoc_ref: quote.adhoc_ref,
    adhoc_ref_buy: quote.adhoc_ref_buy,
    awb_serial: quote.serial,
    agent: shipment.agent,
    origin: shipment.origin,
    dest: shipment.dest,
    pieces: shipment.pieces,
    gross_weight: shipment.gross_weight,
    volume: shipment.volume,
    chargeable_weight: shipment.chargeable_weight,
    shc: shipment.shc,
    target_price: pricing.target_price,
    target_currency: pricing.target_currency,
    target_per_kg: pricing.target_per_kg,
    carrier: pricing.carrier,
    currency: pricing.currency,
    selling_rate: pricing.per_kg ? pricing.price : pricing.price_total,
    all_in: pricing.all_in,
    per_kg: pricing.per_kg,
    min_weight: pricing.min_chargeable_weight,
    min_amount: pricing.min_amount,
    edit_buy_kg: pricing.all_in ? all_in_buy_edit : net_buy_edit,
    update_by: cookies.account,
    send_adhoc: quote.send_adhoc,
    send_ffr: quote.send_ffr,
    send_equote: quote.send_equote,
  };

  await Routes.post("/QuoteHistory", params, true);
}
