import React from 'react'
import { CircularProgress  } from "@material-ui/core";
import { connect } from 'react-redux';

class PageLoadingSpinner2 extends React.Component{
    constructor(props){
      super(props);
      this.state = {  key : Date.now() }
      this.style = {
        left: "50%",
        margin : "auto",
        "z-index" : "10",
        color : "black"
    }
  
    }
  
    componentDidMount(){}
  
    render(){
  
      let show = this.props.show  
      if(!show) return null
      else{
        return (
          <div 
            key={this.state} 
            style = {this.style} 
            class="" 
            role="status">
              <CircularProgress color="inherit" />
          </div>
        )
      }
    }

  }
  

  export default connect(
    (state) => ({
      show : state.page_loading_spinner2  
    })
  )(PageLoadingSpinner2) 