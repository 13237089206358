import React from "react";
import axios from 'axios';
import { createStore } from 'redux'
import watch from 'redux-watch'
import { Modal, Button } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import '../styles/Home.css'
import { connect } from 'react-redux';
import App_State from '../states/App_State.js'
import authPopup from '../JavaScriptSPA/authPopup.js'
//graph components
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField, Switch, Backdrop, CircularProgress } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
//security login
import jwt from 'jsonwebtoken'
import Interweave from 'interweave' //render html
import conf from '../config.js'
import Routes from '../Routes.js';
import Util from '../util.js'
import Main_State from '../states/Main_State.js'
import cookies from "../Cookies";

var config = conf.config ;
var back_host =   config.back_host;
var release_expiration_date =  (new Date())
release_expiration_date.setFullYear((new Date() ).getFullYear() + 1)
var session_expiration_date =  new Date()
session_expiration_date.setDate(session_expiration_date.getDate()+ config.session_expiration_delay)
var _cookies = new Cookies(cookies);
var store_modals = createStore(Main_State.reducer_modals) 

function reducer_station(state={
  authorized_stations : [],
  station : cookies.station !== undefined ? cookies.station : "",
  entity : cookies.entity !== undefined ? cookies.entity : "",
  sales_region : cookies.sales_region !== undefined ? cookies.sales_region : "",
  user : cookies.user !== undefined ? cookies.user : "" ,
  origin_zone : cookies.origin_zone !== undefined ? cookies.origin_zone : "",
  region : "",
  page_loading_spinner : false,
  alert : {title : "", type : "", msg : "", show : false}
}, action){

  if(action.page_loading_spinner !== undefined)
    state.page_loading_spinner = action.page_loading_spinner

  if(action.authorized_stations !== undefined)
    state.authorized_stations = action.authorized_stations

  if(action.region !== undefined){
      state.region = action.region
      _cookies.set('region', action.region,   { path : '/', expires : session_expiration_date})
  }

  if(action.station !== undefined){
    state.station = action.station
    _cookies.set('station', action.station,  { path : '/', expires : session_expiration_date})
  }

  if(action.entity !== undefined){
    state.entity = action.entity
    _cookies.set('entity', action.entity,  { path : '/', expires : session_expiration_date})
  }

  if(action.origin_zone !== undefined){
    state.origin_zone = action.origin_zone
    _cookies.set('origin_zone', action.origin_zone,  { path : '/', expires : session_expiration_date})
  }

  if(action.sales_region !== undefined){
    state.sales_region = action.sales_region
    _cookies.set('sales_region', action.sales_region,   { path : '/', expires : session_expiration_date})
  }

  if(action.user !== undefined)
    state.user = action.user  

  if(action.alert !== undefined)
    state.alert = action.alert 

  return state
}
 
var store_station = createStore(reducer_station)
var store_app = App_State.store_app

class Main extends React.Component {
constructor(props) {
    super(props);  
    this.state = {
      release_note : null,
      release_display : false
    };


}

async componentDidMount() {
  
  document.title = "Quantum - Home"; 
  //this.getReleaseNote()
  this.setState({key : Date.now()})

}

async  getReleaseNote(){

  let release_note_list = await Routes.get("/ReleaseNote",{}, true) // Get all the release from the database

  if(!release_note_list || release_note_list.lenght === 0 ) return //No release in the database
  
  let release_note = release_note_list[0] // Get last release from the list
  let add_day = (2*24*60*60*1000)

  if(!release_note || !release_note.timestamp) return 

  let last_login = cookies.last_login

  if(!last_login){ 
    _cookies.set('home_release_display','true', { path : '/', expires : release_expiration_date})
    _cookies.set("last_login",new Date(),  { path : '/', expires : release_expiration_date})
    _cookies.set('release_expired_time',new Date().getTime() + add_day,  { path : '/', expires : release_expiration_date}) //Add 2 Days
    this.setState({release_note : release_note})
    this.setState({release_display : true})
  }else{
       
      let release_note_time = new Date(release_note.timestamp).getTime()
      let last_login_time = new Date(last_login).getTime()
      let diff_time = release_note_time - last_login_time

      if(diff_time >= 0  ){    
        _cookies.set('last_login',new Date(), { path : '/', expires : release_expiration_date})
        _cookies.set('home_release_display','true', { path : '/', expires : release_expiration_date})
        _cookies.set('release_expired_time', new Date().getTime() + add_day,  { path : '/', expires : release_expiration_date})//Add 2 Days
        this.setState({release_note : release_note})
        this.setState({release_display : true})
      
      }else if( cookies.release_expired_time && last_login_time < cookies.release_expired_time){
        
        _cookies.set('last_login',new Date(),  { path : '/', expires : release_expiration_date})
        this.setState({release_note : release_note})
        this.setState({release_display : true})

      }else{
       
          _cookies.set('last_login',new Date(),  { path : '/', expires : release_expiration_date})
          this.setState({release_note : release_note})
          this.setState({release_display : false})
        
      }  
  } 
}

render() {

let w = watch(store_station.getState, 'alert') 
store_station.subscribe(w((newVal, oldVal, objectPath) => {
  //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  })) 

  ///// APP STATE 
let alert = store_station.getState().alert;
let gdpr = cookies.gdpr_condition
if(this.props.loggedIn && (!gdpr  || gdpr === "null" || gdpr === '') ){
 store_modals.dispatch({type : 1, modalGDPR : true})
}


  return <div class="" style = {{margin : '2em' }}>

    <PageLoadingSpinner />

    <script type="text/javascript" src="https://alcdn.msauth.net/lib/1.2.1/js/msal.js" integrity="sha384-9TV1245fz+BaI+VvCjMYL0YDMElLBwNS84v3mY57pXNOt6xcUYch2QLImaTahcOP" crossOrigin="anonymous"></script>
    
    <div class="container-fluid ">

      {alert.title !== "" ? <Alert title={ alert.title} msg={ alert.msg} type={alert.type} /> : null }

      <div class="row">
    
        <div class="col-lg-6 col-sm-12" > 
      
          <div class="row col-md-8 col-sm-12">
            <p>
              <img style = {{maxWidth : "20em"}} src={require("../img/quantum_logo.jpg")}  alt="Quantum" />
            </p>
          </div>

          <div class="row col-md-8 col-sm-12">
            <h3>Quoting smoothly</h3>
          </div>
          
          <br/>

          <div class="row col-md-8 col-sm-12">
        
            <h5> 
              {this.props.loggedIn ? "Welcome " + cookies.first_name + " " + cookies.last_name  + ", you are logged in." :
              <span> <VpnKeyIcon/> Log in  with your account. </span>  } 
            </h5>  
            <br/>
            {!this.props.loggedIn &&
            [
            <span> 
             - If your account <u>is migrated</u> to Office 365, use your Office 365 account email address.
              <br/>
              <strong>Example: j.doe@ecsgroup.aero</strong>
            <br/>
            - If your account <u>has not yet been migrated</u> to Office 365, use your temporary alias account that ends with @ecsgroup365.onmicrosoft.com
              <br/>
              <strong>Example: j.doe@ecsgroup365.onmicrosoft.com </strong></span>, 
            <br/>,
            <span class="text-danger"> ⚠️ Make sure you use Google Chrome ! </span>
            ]
          }

          </div>

          <div class="row col-md-8 col-sm-12">
            <h5>  {this.props.loggedIn ? "Your contact email is " + cookies.email_contact +"." : null }  </h5>
          </div>

          <ModalGDPR/>
          <LoginForm loggedIn={this.props.loggedIn} />

          <br />

          {!this.props.loggedIn  ? null :

              [
              <div class="row col-6">
                <h5>My Region</h5>  
              </div>
               ,
               <div class="col-10">
                  <StationInfo  loggedIn ={this.props.loggedIn} authorized_stations = {this.props.authorized_stations} />
               </div>
              ]
          }


        {
          !this.props.loggedIn 
          && navigator.userAgent.indexOf("Edge") >0 
          &&

        <div class = "alert alert-warning col-4" role="alert">
                <h4>Are you using Internet Explorer or Edge ?</h4>
                  <p>If you have trouble signing in or using Quantum, switch to Google Chrome or Mozilla Firefox.</p>
            </div>
        }

        </div>

        <div class="col-lg-6 col-sm-12" >
            <ReleaseNote loggedIn = {this.props.loggedIn} release_note = {this.state.release_note} release_display = {this.state.release_display}/>
            <br/>
        </div>

      </div>

    </div>
  

</div>


 
}

}
 
////CONNECT
export default connect(
  (state) => ({
    authorized_stations: state.authorized_stations, 
    loggedIn : state.loggedIn
  })
)(Main) 

async function logout(params){
  let url = back_host +"/logout"

  return await axios({
    method: 'post',
    url: url,
    withCredentials: true,
    params: {}
  })
  .then(function (response) {
    _cookies.remove("user")
    _cookies.remove("email_contact")
    _cookies.remove("first_name")
    _cookies.remove("last_name")
    _cookies.remove("station")
    _cookies.remove("entity")
    _cookies.remove("region")
    _cookies.remove("account")
    _cookies.remove("origin_zone")
    _cookies.remove('sales_region')
    _cookies.remove('user_carriers')
    _cookies.remove('rights')
    _cookies.remove('role')
    _cookies.remove('default_hq')
    store_station.dispatch({type:1, alert : {title : "Logout success", msg : "You successfully logout fom QUANTUM.", type:"success"}});

    return  response.data

  })
  .catch(function (err) {
    console.log(err)
    return false   
  });

 }

async function login(params){
  let url = back_host +"/login"

  return await axios({
    method: 'post',
    url: url,
    withCredentials: true,
    params: {token : params.token }
  })
  .then(function (response) {

    return  response.data

  })
  .catch(function (err) {
    console.log(err)
    return false
   
  });

}

class ModalGDPR extends React.Component{
  
  componentDidMount(){
    let w = watch(store_modals.getState, 'modalGDPR')
    store_modals.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
    
  }
  render(){
    console.log("[RENDER] ModalGDPR")

    let show = store_modals.getState().modalGDPR;

    return(


      
      <Modal dialogClassName = 'modal-m' show={show} onHide={()=>{  

       
        store_modals.dispatch({type : 1, modalGDPR : true })
      }}>

      <Modal.Body> 
      
        <div class="row" style = {{fontSize : '1.4em', padding : '1.5em'}}>

          <div class="col-12" >
            <p>
            QUITO is collecting and processing personal data (contact information) for contractual necessity. 
            Data is used to ensure the correct performance, security and availability of the system,
            and to monitor the activity of the platform. QUITO will keep a digital version of these data in a secure location
             for 3 years after the last session has expired and then will proceed to their deletion.
              GDPR recognizes a 
             litany of rights : to access, to rectification, to erasure, to restrict processing, to object to processing
              and to data portability.
               If you want to exercise any of these rights, please email to dpo@quito.aero.
               
              If you make a request, we have one month to respond to you.
              </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
      <Button  style ={{marginRight : '1em',width : "10em"} } variant="success" onClick = { async(e) =>{
   
       _cookies.set('gdpr_condition', new Date())
       let params = {first_login : true, update : true }
       await Routes.post("/Users", params, true)
       store_modals.dispatch({type : 1, modalGDPR : false })
     
      }} >Ok, I understand</Button>
      </Modal.Footer>
    </Modal>      
    )

  }

}

class LoginForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    }

  }
    


async handleLogin(){

    let account = await authPopup.signIn()

    if(!account)
    return store_station.dispatch({type:1, alert : { title : "Login Error", msg :"Authentification failed. Please contact us at quantum.support@quito.aero.", type :"error"}});

    let token_user =   jwt.sign({ userName : account.userName }, 'SkyWalker081389');

    store_station.dispatch({type:1, page_loading_spinner : true})
    let user_info = await login({token : token_user})
    store_station.dispatch({type:1, page_loading_spinner : false})

    if(!user_info)
      return store_station.dispatch({type:1, alert : { title : "Login Error", msg :"You don't have authorization to access to QUANTUM. Please contact us at quantum.support@quito.aero.", type :"error"}});
    //////// SUCCESS

    _cookies.set('region', user_info.region,   { path : '/', expires : session_expiration_date})
    _cookies.set('entity', user_info.entity,   { path : '/', expires : session_expiration_date})
    _cookies.set('user', user_info.email_contact,   { path : '/', expires : session_expiration_date})
    _cookies.set('role', user_info.role,   { path : '/', expires : session_expiration_date})
    _cookies.set('first_name', user_info.first_name,   { path : '/', expires : session_expiration_date})
    _cookies.set('last_name', user_info.last_name,   { path : '/', expires : session_expiration_date})
    _cookies.set('account', user_info.email,   { path : '/', expires : session_expiration_date})
    _cookies.set('email_contact', user_info.email_contact,   { path : '/', expires : session_expiration_date})
    _cookies.set('user_carriers', user_info.carriers,  { path : '/', expires : session_expiration_date})
    _cookies.set('user_except_carriers', user_info.except_carriers,  { path : '/', expires : session_expiration_date})
    _cookies.set('gdpr_condition', user_info.first_login,   { path : '/', expires : session_expiration_date})
    _cookies.set('rights', user_info.rights,   { path : '/', expires : session_expiration_date})

    let station, origin_zone, sales_region = null 

    /// HQ USERS
    if(user_info.region === "WLD"){

      if(!cookies.default_hq) _cookies.set('default_hq', true,   { path : '/', expires : session_expiration_date})
      
      station = "PAR"
      origin_zone = "EXCDG"
      sales_region = "FRE"

      store_app.dispatch({type :1, authorized_stations : user_info.authorized_stations })

    }else{
      
      if(cookies.default_hq !== undefined) _cookies.remove('default_hq')
      //this sould never happen but just in case
      if(!user_info.authorized_stations)
        return store_station.dispatch({type:1, alert : { title : "Login Error", msg :"You don't have authorization to access QUANTUM (bad region). Please contact us at quantum.support@quito.aero.", type :"error"}});

      station = user_info.station
      origin_zone =  user_info.origin_zone
      sales_region = user_info.sales_region
      
      store_app.dispatch({type :1, authorized_stations : user_info.authorized_stations })
                                
    }

    _cookies.set('station', station,  { path : '/', expires : session_expiration_date})
    _cookies.set('origin_zone', origin_zone,  { path : '/', expires : session_expiration_date})
    _cookies.set('sales_region', sales_region,   { path : '/', expires : session_expiration_date})
    
    store_station.dispatch({type:1, station :  station, sales_region : sales_region, origin_zone :  origin_zone })

    store_station.dispatch({type:1, alert : {title : "Login success", msg : "You are now logged. Please refresh page.", type:"success"}});
    
    let params = {last_login : true, update : true }
    await Routes.post("/Users", params)

    
     let {redirect} =  store_app.getState()

     if(redirect && redirect.indexOf("PLG") >= 0){
       
        window.location.href = redirect;
        window.location.reload();
     }else{
        window.location.reload();
     }
    
  }

async componentDidMount(){
    
}

 
render(){

return (
  <div class=" row col-6">
  {this.props.loggedIn ?

    <button 
      type="button" 
      id="signOut" 
      class="btn btn-danger"
      onClick={async()=>{
        store_station.dispatch({type:1, page_loading_spinner : true})
        let params = {last_login : true, update : true }
        await Routes.post("/Users", params)
        logout();
        authPopup.signOut();
        store_station.dispatch({type:1, page_loading_spinner : false})
        _cookies.set("last_login",new Date(),  { path : '/', expires : release_expiration_date})
                  
      }}
    > 
      <LockIcon fontSize="small"/>  Sign Out
    </button>
  :
    <button 
      type="button" 
      id="signIn" 
      class="btn btn-success" 
      onClick={async (e)=>{
        e.preventDefault(); 
        this.handleLogin()}}
    >
    <LockIcon fontSize="small"/>  Sign In
    </button>
          
  }      
  </div>
)
}

}

class StationInfo extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    }

  }

  componentDidMount(){
    store_station.subscribe(()=>{
      this.setState({key:Date.now()})
    })
  }

  render(){

    let station_state = store_station.getState();
    let app_state = this.props
    let default_hq = cookies.default_hq === "true"

    if(!app_state.loggedIn) return null

      let default_station = this.props.authorized_stations.filter((s)=> s.station === station_state.station)[0]

    if(!default_station)return null 

    return (
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <label>Default Station</label>
            <Autocomplete
              popupIcon={null}
              disableCloseOnSelect={false}
              id="checkboxes-tags-demo"
              class="form-group"
              fullWidth style = {{ width:"100%"}}
              defaultValue ={default_station  }
              options={this.props.authorized_stations}
              getOptionLabel={option =>  option.country_code + " - "+ option.station_name + " - " + option.station}
              onChange = {(e, ss)=>{
                  if(!ss) return
                  store_station.dispatch({type:1, station : ss.station, sales_region : ss.sales_region, origin_zone : ss.origin_zone});
                  window.location.reload();
              }}
              renderInput={params => (
                <TextField
                  class="form-control"
                  style={{height:"100%",marginBottom:15}}
                  {...params}
                  placeholder="Type"
                  fullWidth
                />
                    )}
            />
          </div>
      
          <div class="col-md-3 col-sm-12">
            <label>Sales Region</label>
            <input
              type="text" 
              class="form-control" 
              defaultValue={station_state.sales_region} 
              onChange={ (e)=>{store_station.dispatch({type : 1, sales_region : e.target.value})}}  
              disabled
            />
          </div>

            {cookies.region !== "WLD" ? null :
            
            <div class="col-md-3 col-sm-12">
              <label>Default to HQ</label>
              <br/>
              <Switch
                color="secondary"
                checked={default_hq}
                onChange={(e)=>{
                  _cookies.set('default_hq', !default_hq,   { path : '/', expires : session_expiration_date} )
                  window.location.reload()
                }}
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />

            </div>
            }
        </div>
    )
  }

}

class Alert extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      type : props.type,
      title : props.title,
      msg : props.msg
    }

  }

  render(){

  
  let html = [];
  let props = this.state;
   
   let classValue="warning";
   if(props.type==="warning")
     classValue = "alert alert-warning"
   else if(props.type==="success")
     classValue = "alert alert-success"
    else if(props.type==="error")
     classValue = "alert alert-danger"

  
       html = 
       <div class={classValue} role="alert">
         <h4 class="alert-heading">{props.title}</h4>
          
        <p>{props.msg}</p>
     </div>
  
      return html
}
}
 
class PageLoadingSpinner extends React.Component{
  constructor(props){
    super(props);

    this.state = {  key : Date.now() }

    this.style = {
      position: "absolute",
      left: "50%",
      margin : "auto",
      "margin-top" : "20em",
      "z-index" : "10",
      color : "white"
  }

  }

  componentDidMount(){
    let w1 = watch(store_station.getState, 'page_loading_spinner')
    store_station.subscribe(w1((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }

  render(){

    let show = store_station.getState().page_loading_spinner
    console.log("[COMPONENT] Page Loading Spinner")

 

    if(!show) return null
    else{
      return (
        <div key={this.state} 
            style = {this.style} 
            class="" 
            role="status">
          
          <Backdrop   open={true}  >
          <CircularProgress color="inherit" />
        </Backdrop>

        </div>

      )
    }
 
 
  }

}

class ReleaseNote extends React.Component{
  constructor(props){
    super(props);

    this.state = {  key : Date.now() }
  }

 async componentDidMount(){
 // this.state = {  key : Date.now() }
  }

  render(){
   
    return (
      cookies.home_release_display === 'false' || !cookies.home_release_display ? null : 
      <div id = "home_release_note" style = {{display : 'yes'}}>
         { !this.props.loggedIn || this.props.release_note === null  ? null :
           //cookies.get('release_display') === 'true' &&
           this.props.release_display  &&
            <div class="alert alert-info" role="alert" style = {{background : '#0076ad', color : 'white'}}>
              <p style = {{margin: '0.7em' }}>

                <h4>
                  <strong>
                    <img src = {require('../img/bell.png')} alt=""/>{" "}QUANTUM RELEASE #{this.props.release_note.id} -  
                    {" "} {new Date(this.props.release_note.timestamp).getDate() < 10 ? "0" + new Date(this.props.release_note.timestamp).getDate() : new Date(this.props.release_note.timestamp).getDate()}
                    { Util.MONTHS[new Date(this.props.release_note.timestamp).getMonth()]}
                    {new Date(this.props.release_note.timestamp).getFullYear().toString().substring(2,4)} - {this.props.release_note.title}{"."}
                  </strong>
                </h4>
            
                <br/>
              
                <Interweave content={this.props.release_note.content} />
              
              </p>
              <div class = "row">

                <div class="col-md-4 col-sm-12" style ={{textAlign : "left"}}>
                

                  <button type="button" id="close_release" class="btn btn-success" style = {{marginLeft : "1.5em"}} onClick ={
                    (e) => {
                     
                      _cookies.set('home_release_display','false',  { path : '/', expires : release_expiration_date})
              
                      document.getElementById('home_release_note').setAttribute("style", "display:none");
                    }
                  }>
                    
                    <img src = {require('../img/check_white.png')} alt=""/>
                    
                    {" "}Got it !
                    <br/>
                    Close this window
                    
                    </button>
                 

                </div>
                <div class="col-md-8 col-sm-12" style ={{textAlign : "right"}}>
                <p style ={{textAlign : "right", margin : "1.5em"}}>Release information is available on the <a style ={{color : 'yellow'}}href ={config.front_host + "/#/Help"}>Help page.</a></p>
        
                </div>
              </div>
            </div>
          }

      </div>
    )
  }
}
 