import { AccordionActions } from "@material-ui/core"

export default   {

  reducer_stations : function(state={
    stations: [],
    one_station : {
      id : null, 
      station : "", 
      origin_zone : "", 
      sales_region : "",
      client_id : "", 
      station_name : "", 
      country_code : "", 
      brand_code : "",
      entity_code : ""
    },
    stations_filtered : [],
    countStations : 0,
    connected : 0,
    addModal : false,
    updateModal : false,
    deleteModal : false,
    stationUpdated : false,
    confirmModal : false,
    exportModal : false,
    export_type : "csv",
    export : false,
    stations_csv : "",
    error : false,
    errors : {
      station : "", 
      origin_zone : "", 
      sales_region : "",
      client_id : "", 
      station_name : "", 
      country_code : "", 
      brand_code : "",
      entity_code : ""
    },
    page_loading_spinner : false
  }, action){

    if(action.stations !== undefined)
      state.stations = action.stations
      
    if(action.stations_filtered !== undefined)
      state.stations_filtered = action.stations_filtered
    
    if(action.countStations !== undefined)
      state.countStations = action.countStations

    if(action.connected !== undefined)
      state.connected = action.connected

    if(action.page_loading_spinner !== undefined)
      state.page_loading_spinner = action.page_loading_spinner

    if(action.addModal !== undefined)
      state.addModal = action.addModal
    
    if(action.updateModal !== undefined)
      state.updateModal = action.updateModal

    if(action.deleteModal !== undefined)
      state.deleteModal = action.deleteModal

    if(action.stationUpdated !== undefined)
      state.stationUpdated = action.stationUpdated

    if(action.confirmModal !== undefined)
      state.confirmModal = action.confirmModal

    if(action.exportModal !== undefined)
      state.exportModal = action.exportModal

    if(action.export_type !== undefined)
      state.export_type = action.export_type

    if(action.one_station !== undefined)
       state.one_station = action.one_station

    if(action.export !== undefined)
      state.export = !state.export

    if(action.stations_csv !== undefined)
      state.stations_csv = action.stations_csv
    
    if((action.addStation !== undefined && action.addStation) || (action.updateStation !== undefined && action.updateStation)){
      
      if(action.station !== undefined)
        state.one_station.station = action.station ? action.station.toUpperCase() : ""

      if(action.origin_zone !== undefined)
        state.one_station.origin_zone = action.origin_zone ? action.origin_zone.toUpperCase() : ""
      
      if(action.sales_region !== undefined)
        state.one_station.sales_region = action.sales_region

      if(action.client_id !== undefined)
        state.one_station.client_id = action.client_id ? action.client_id.trim() : ""

      if(action.station_name !== undefined)
        state.one_station.station_name = action.station_name

      if(action.country_code !== undefined)
        state.one_station.country_code = action.country_code

      if(action.brand_code !== undefined)
        state.one_station.brand_code = action.brand_code

        if(action.entity_code !== undefined)
        state.one_station.entity_code = action.entity_code

    }

    if(action.errors !== undefined)
      state.errors = action.errors

    if(action.error !== undefined ){

      state.error = action.error

      if(action.station !== undefined)
        state.errors.station = action.station

      if(action.origin_zone !== undefined)
        state.errors.origin_zone = action.origin_zone

      if(action.sales_region !== undefined)
        state.errors.sales_region = action.sales_region 

      if(action.client_id !== undefined)
        state.errors.client_id = action.client_id

      if(action.station_name !== undefined)
        state.errors.station_name = action.station_name

      if(action.country_code !== undefined)
        state.errors.country_code = action.country_code

      if(action.brand_code !== undefined)
        state.errors.brand_code = action.brand_code

      if(action.entity_code !== undefined)
        state.errors.entity_code = action.entity_code

    }

    if(action.updateStation !== undefined && action.one_station !== undefined){
      state.one_station = action.one_station
    }

    return state
      
    },
      
    reducer_filters : function (state={
      station : "", 
      origin_zone : "", 
      sales_region : "",
      client_id : "", 
      station_name : "", 
      country_code : "", 
      brand_code : "",
      entity_code : ""
    }, action){
      
      if(action.reset)
        state = {
          station : "", 
          origin_zone : "", 
          sales_region : "",
          client_id : "", 
          station_name : "", 
          country_code : "", 
          brand_code : "",
          entity_code : ""
        };
    
    if(action.station !== undefined)
      state.station = action.station

    if(action.origin_zone !== undefined)
      state.origin_zone = action.origin_zone

    if(action.sales_region !== undefined)
    state.sales_region = action.sales_region

    if(action.client_id !== undefined)
    state.client_id = action.client_id
  
    if(action.station_name !== undefined)
      state.station_name = action.station_name
  
    if(action.country_code !== undefined)
      state.country_code = action.country_code
  
    if(action.brand_code !== undefined)
      state.brand_code = action.brand_code

    if(action.entity_code !== undefined)
      state.entity_code = action.entity_code
  
    if(action.station || action.origin_zone || action.sales_region || action.client_id || action.station_name || action.country_code || action.brand_code || action.entity_code)
      state.can_refresh = true

    if(action.can_refresh !== undefined){
      state.can_refresh = action.can_refresh
    }
      
    return state
      
  }
}