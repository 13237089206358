import cookies from '../Cookies.js';
import util from '../util.js'

const default_prices = {}
const default_filters = {
  station : cookies.station, 
  sales_region : cookies.sales_region,
  from_date: util.getCurrentDate(), 
  to_date : "", 
  sell_buy : "S", 
  currency : "No Conversion", 
  origin : "", 
  dest : "", 
  carrier : "", 
  commodity : "", 
  offset : 0, 
  currentPage : 1, 
  force : 0
}

export default   {
  reducer_prices : function(state={ ...default_prices}, action){
    let keys = Object.keys(action)
    keys.forEach((key)=>{
      if(key==="force")
        state[key]++
      else if(key ==="reset")
        state = {...default_prices, force : state.force}
      else
        state[key] = action[key]
    })

    return state    
  },
      
  reducer_filters : function (state={...default_filters}, action){    
    let keys = Object.keys(action)
    keys.forEach((key)=>{
      if(key==="force")
        state[key]++
      else if(key ==="reset")
        state = {...default_filters}
      else
        state[key] = action[key]
      })
      return state  
    }
}