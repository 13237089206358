import React from 'react'
import {  Backdrop, CircularProgress  } from "@material-ui/core";
import { connect } from 'react-redux';

class PageLoadingSpinner extends React.Component{
    constructor(props){
      super(props);
      this.state = {  key : Date.now() }
      this.style = {
        position: "absolute",
        left: "50%",
        margin : "auto",
        "margin-top" : "20em",
        "z-index" : "10",
        color : "white"
    }
  
    }
  
    componentDidMount(){}
  
    render(){
  
      let show = this.props.show
     
      if(!show) return null
      else{
        return (
          <div key={this.state} 
            style = {this.style} 
            class="" 
            role="status"
          >
            <Backdrop   open={true}  >
            <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )
      }
    }
  }
  

  export default connect(
    (state) => ({
      show : state.page_loading_spinner
  
    })
  )(PageLoadingSpinner) 