import React from "react";
import { createStore, applyMiddleware } from 'redux'
import watch from 'redux-watch'
import { createLogger } from 'redux-logger'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Interweave from 'interweave' //render html
//import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Routes from '../Routes.js'
import Util from '../util.js'
import '../styles/Help.css'
import cookies from "../Cookies.js";
var expand_more = require("../img/expand_more.png")
var expand_less = require("../img/expand_less.png")

const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration : false  , // print the duration of each action?
  timestamp : true, // print the timestamp with each action?
 
  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.
 
  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.
 
  logger : console, // implementation of the `console` API.
  logErrors : true, // should the logger catch, log, and re-throw errors?
 
  diff : false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`

});

function reducer_help(state={
  error: null, 
  success : null, 
  force : false, 
  search_text : "", 
  priority_list : ["LOW", "MEDIUM", "HIGH"], 
  type_list : ["BUG", "PROPOSAL"], 
  section_list : ["Admin", "Home", "List", "Quote", "Other"], 
  ticket : {
    station : cookies.station, 
    user : cookies.user, 
    type : null, 
    urgent : false, 
    priority : null, 
    quote_id : null, 
    section : null, 
    description : null 
  }
 
}, action){

  let st = {...state}

  if(action.reset){
    st = {
            error: null, 
            success : st.success, 
            force : false, 
            search_text : "", 
            priority_list : ["LOW", "MEDIUM", "HIGH"], 
            type_list : ["BUG", "PROPOSAL"], 
            section_list : ["Admin", "Home", "List", "Quote", "Other"], 
            ticket : {
              station : cookies.station, 
              user : cookies.user, 
              type : null, 
              urgent : false, 
              priority : null, 
              quote_id : null, 
              section : null, 
              description : null 
          }
    }
  }
 
  if(action.force !== undefined)
    st.force = !st.force

  if(action.error !== undefined)
    st.error = action.error

  if(action.success !== undefined)
    st.success = action.success

  if(action.urgent !== undefined)
    st.ticket.urgent = action.urgent

  if(action.priority !== undefined)
    st.ticket.priority = action.priority

  if(action.section !== undefined)
    st.ticket.section = action.section

  if(action.type_ !== undefined)
    st.ticket.type = action.type_

  if(action.quote_id !== undefined)
    st.ticket.quote_id = action.quote_id

  if(action.description !== undefined)
    st.ticket.description = action.description

  return st
}

var store_help = createStore(reducer_help, applyMiddleware(logger))
var release_note_list = []

export default class Main extends React.Component {
constructor(props) {
    super(props);  
    this.state = {
      display_releases : false,
      key : Date.now()
    };

    this.ticket_type = ["BUG", "PROPOSAL"]
  }

async componentDidMount() {
  
  document.title = "Quantum - Help"; 
  release_note_list = await this.getReleaseNote()
  this.setState({key : Date.now()})

  let w1 = watch(store_help.getState, 'ticket.type')
  store_help.subscribe(w1((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

  let w2 = watch(store_help.getState, 'ticket.urgent')
  store_help.subscribe(w2((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

  let w3 = watch(store_help.getState, 'ticket.priority')
  store_help.subscribe(w3((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

  let w4 = watch(store_help.getState, 'success')
  store_help.subscribe(w4((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

  let w5 = watch(store_help.getState, 'error')
  store_help.subscribe(w5((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

  let w6 = watch(store_help.getState, 'force')
  store_help.subscribe(w6((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

  
}

async  getReleaseNote(){

  let release_note_list = []
  release_note_list = await Routes.get("/ReleaseNote",{}, true)

  if( !release_note_list || release_note_list.lenght === 0 ) return []
  return release_note_list 
}

displayPreviousRelease = e =>{

	if(!this.state.display_releases){
    document.getElementById("previous_releases").setAttribute("style", "display:yes;");
    document.getElementById("btn_release_text").innerHTML =  'Less releases';
    document.getElementById("release_expand").setAttribute("src", expand_less);
  
  }else{
    document.getElementById("previous_releases").setAttribute("style", "display:none;");
    document.getElementById("btn_release_text").innerHTML =  'More releases';
    document.getElementById("release_expand").setAttribute("src", expand_more);
   
  }
		
	this.setState({display_releases : !this.state.display_releases});

}

async onClickSubmit(e){

  store_help.dispatch({type :1, error : null, success : null})
  let ticket = store_help.getState().ticket;

  if(!ticket.type || !ticket.description)
    return store_help.dispatch({type :1, error : "Please fill in all mandatory fields. "})

  if(ticket.type === "BUG" && (ticket.urgent === undefined || ticket.urgent === null))
  return store_help.dispatch({type :1, error : "Please fill in all mandatory fields"})

  if(ticket.type === "PROPOSAL" && !ticket.priority)
  return store_help.dispatch({type :1, error : "Please fill in all mandatory fields"})

  let params = {}

  params.ticket = ticket
   
  let res = await Routes.post("/sendHelpRequest", params, true)

  if(!res || (res && res.error)){
    store_help.dispatch({type :1,   error : "Your ticket has not been sent."}) 
  }else{
    store_help.dispatch({type :1,   success : "Your ticket has been submitted."})
    store_help.dispatch({type :1,   reset : true, force : true})
    await Util.sleep(5000)
    store_help.dispatch({type :1,   success : ""})
  }
}

render() {

  let state_help = store_help.getState();
  let release_note = release_note_list[0]

  return (
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class = "col-1"></div>

          <div class="col-10">
            <br />
            <div class="article-login">
              <h2>  Before going further.. </h2> 
              <p>
                  Please note that a lot of problems can be solved by just refreshing the page or logging out then logging in again.
                  If that doesn't work then you might find some useful information below.
                  If you are still stuck, then please reach out to us using the form at the bottom of this page.
              </p>

              <div class="alert alert-info" role="alert">Check our  
                      <a 
                        target="_blank"  
                        rel="noopener noreferrer" 
                        href="https://ecsgroup365.sharepoint.com/sites/Discovery/SitePages/Quantum.aspx"> e-learning course
                      </a> {" "} to access more information :       
                      <br/>- Quantum Quick Start 
                      <br/>- Online course with video tutorials
                      <br/>- Quantum Detailed Functionalities
                      <br/>- Quantum User Guide - Step by Step
                    
              </div>

              {/*
              <div className="slide-container" class="col-8">
                      <Zoom scale={1}>          
                          <img alt = {'Help'} key={1} style={{maxWidth: "700px"}} src={require("../img/doc/visio1.PNG")} />
                          <img alt = {'Help'} key={2} style={{maxWidth: "700px"}} src={require("../img/doc/visio2.PNG")} />
                          <img alt = {'Help'}  key={3} style={{maxWidth: "700px"}} src={require("../img/doc/visio3.PNG")} />
                          <img alt = {'Help'}  key={4} style={{maxWidth: "700px"}} src={require("../img/doc/visio4.PNG")} />
                          <img alt = {'Help'} key={5} style={{maxWidth: "700px"}} src={require("../img/doc/visio5.PNG")} />
                      
                      </Zoom>
              </div>
              */}

              <hr/>
              <h2>  Account </h2>
                <h4> ❓ I can't access Quantum</h4>
                <p>
                    Try the following steps : 
                    <ul>
                      <li> Refresh the page, sometimes it can help</li>
                      <li> Make sure that you are using Chrome (or Mozilla) browser </li>
                      <li> Make sure that you are using right account and password </li>
                      <li> Wait a few minutes then try again. Sometimes we are implementing updates, which might disturb the app functionment. It is usually very temporary </li>
                      <li> If none of these is working, please reach us at <a href="mailto:quantum.support@quito.aero">quantum.support@quito.aero</a> with detailed information and screenshot of the bug</li>
                </ul>
                </p>
                
                <br/>

                <h4> ❓ I don't have an account</h4>
                <p>
                    Please reach us at <a href="mailto:quantum.support@quito.aero">quantum.support@quito.aero</a> to get an account.
                </p>

            </div>

            <br />
            <hr/>

            <div class="article-editor">
                <h2>  Quote screen </h2>
                <p> 
                  Use this page to insert shipment details, define your pricing conditions, send your rate to CargoSpot and your quote to the customer.
                  <br/>
                  The header shows quote status and general information (reference, creator & creation date, etc.).
                  <br/>
                  It also displays the main functionalities (Save, View Quote Template,  Create New Quote, Clone, Send to CargoSpot). 
                  <br/>
                  On the left-hand side, insert all shipment data incl. the routing. 
                  <br/>
                  On the right-hand side, you can view the selling rates set up in CargoSpot (street rates, promo, contract rates and ad-hocs, if available). 
                  <br/>
                  You can also access the previous quotes made in the tool with similar Origin-Destination criterias and calculate the margin based on the selling rate you defined for your quote.
                </p>

                <h4>  Selling & Buying rates </h4>

                <p>Selling and Buying rates are imported daily from Cargospot system.</p>

                <div class="alert alert-warning fade show" role="alert">
                  <strong>Note : </strong> You need to ensure that prices are correctly inserted in CargoSpot system if you want to use them in Quantum.
                </div>

                <h4> ❓ Rates aren't showing up in the Quote screen </h4>
                <p> Prices may not appear for different reasons :
                      <ul>
                        <li>You didn't provide correct begin & termination date in Cargospot </li>
                        <li>You didn't provide correct SHC, origin, destination values </li>

                      </ul>

                </p>

            </div>

            <br />
            <hr/>

            <div class="article-quotes-list">
              <h2> List screen </h2>
              <p>This page shows you the overview of all quotes for your station.</p>
              <h4> Search & Filter </h4>
              <p>   
                  You can use the filters to search for specific quotes.
              </p>
              <h4>  Reporting </h4>
              <p>   
                  It is possible to export the quote list into a .csv file for reporting.
              </p>
              <h4>  Data segregation </h4>
              <p>   
                It is not possible to access quotes created in other stations, unless you are granted a specific authorization.
                Reciprocally, no other station can access your quotes.
              </p>
            </div>

            <hr/>
      
            <div class="article-quotes-list" key = {this.state.key}>
              <h2>  I think there is a bug / I have a suggestion </h2>
      
              <div class="col-6" >
                <span class="text-danger"> {store_help.getState().error}</span>
                <span class="text-success"> {store_help.getState().success}</span>
                <br/>
                <label>Type*</label>
                <select id="inputState" class="form-control dropdown" tabindex="0"
                  placeholder=""
                  onChange={ e => {store_help.dispatch({type:1, type_: e.target.value}) }} >
                  <option selected>{state_help.ticket.type} </option>
              
                  {
                    state_help.type_list.map((t)=>{
                      if(t !== state_help.ticket.type)
                        return <option>{t}</option>
                      else return null
                    })
                  }
                </select>

                <br/>

                  {state_help.ticket.type === "BUG" && 
                    [
                      <label>Urgent*</label>
                      ,
                      <select id="inputState" 
                        class="form-control dropdown" 
                        tabindex="0"
                        placeholder=""
                        onChange={ e => {store_help.dispatch({type:1, urgent: e.target.value === "YES"}) }} >
                        <option selected>{state_help.ticket.urgent ? "YES" : "NO"} </option>
                        <option >{state_help.ticket.urgent ? "NO" : "YES"} </option>
                      </select>
                    ]
                  }

                  {state_help.ticket.type === "PROPOSAL" && 
                    [
                      <label>Priority*</label>
                    ,
                    <select id="inputState" 
                      class="form-control dropdown" 
                      tabindex="0"
                      placeholder=""
                      onChange={ e => {store_help.dispatch({type:1, priority: e.target.value}) }} >
                      <option selected>{state_help.ticket.priority} </option>
                      {
                        state_help.priority_list.map((t)=>{
                          if(t !== state_help.ticket.priority)
                            return <option value={t}>{t}</option>
                          else return null
                        })
                      }
                    </select>
                    ]
                  
                  }

                <br/>

                <label>Quote ID</label>
                <input  
                  type="text"
                  tabindex="0"
                  class="form-control" 
                  placeholder="" 
                  defaultValue={store_help.getState().ticket.quote_id} 
                  maxlength="6"
                  onChange={ e => store_help.dispatch({type:1, quote_id : e.target.value})} 
                />
            
                <br/>

                <label>Description*</label>
                <textarea
                  class="col"
                  defaultValue={store_help.getState().ticket.description}
                  onChange ={ e => store_help.dispatch({type:1, description : e.target.value})} 
                >   
                </textarea>
                
                <br/>

                <button class="btn btn-success" onClick={async (e)=>{this.onClickSubmit(e)}}>
                    Submit
                </button>
            </div>

            </div>

            <br/>
            <hr/>
            
            <h2 id ="releases">Release note</h2>
            {!release_note ? null :
                <div class="alert alert-info" role="alert">
                  <p>
                    <h4>
                    <strong> 
                          <img src = {require('../img/bell.png')} alt=""/>{" "}QUANTUM RELEASE #{release_note.id} -
                          {" "} {new Date(release_note.timestamp).getDate() < 10 ? "0" + new Date(release_note.timestamp).getDate() : new Date(release_note.timestamp).getDate()  }
                          { Util.MONTHS[new Date(release_note.timestamp).getMonth()]}
                          {new Date(release_note.timestamp).getFullYear().toString().substring(2,4)} - {release_note.title}{"."}
                      </strong>
                    </h4>

                    <br/>

                    <Interweave content={release_note.content} />

                  </p>
                </div>
            }

            <br/>

            <button id = "btn_releases" 
              class="btn btn-success" 
              type ="button" 
              onClick = { e => this.displayPreviousRelease()}><span id = "btn_release_text">More releases</span> <img id ="release_expand" src={expand_more} alt="" />
            </button>

            <br/>
            <br/>

            <div id="previous_releases" style = {{display : "none"}}>

              { release_note_list.length === 0 ? 
                <div class="alert alert-warning"  role ="alert"><p>No release to display.</p></div> 
              : 
                release_note_list.map( (release) =>{
                return (
                  <div class="alert alert-info" role ="alert" >
                    <ExpansionPanel class = "">
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography >
                              <h4>
                                <strong>RELEASE #{release.id} -
                                  {" "} {new Date(release.timestamp).getDate() < 10 ? "0" + new Date(release.timestamp).getDate() : new Date(release.timestamp).getDate()}
                                  { Util.MONTHS[new Date(release.timestamp).getMonth()]}
                                  {new Date(release.timestamp).getFullYear().toString().substring(2,4)} - {release.title}{"."}
                                </strong>
                              </h4>
                            </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                            <div class="col">
                              <p>
                                <Interweave content={release.content} />
                              </p>  
                            </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                )})
              
              }
              
            </div>

            <br/>

          </div>
          <div class = "col-1"></div>
        </div>
      </div> 
    </div>
  )
}
}
 

