
export default   {

  reducer_users : function(state={
    users: [],
    user : {
      id : null, 
      first_name : "", 
      last_name : "", 
      entity : "",
      region : "", 
      email : "", 
      email_contact : "", 
      role : "", 
      carriers : "ALL",
      except_carriers : "",
      discovery : false,
    },
    users_filtered : [],
    countUsers : 0,
    connected : 0,
    addModal : false,
    updateModal : false,
    deleteModal : false,
    userUpdated : false,
    confirmModal : false,
    exportModal : false,
    export_type : "csv",
    export : false,
    users_csv : "",
    error : false,
    errors : {
      first_name : "", 
      last_name : "", 
      entity : "",
      region : "", 
      email : "", 
      email_contact : "", 
      role : "", 
      carriers : "",
      except_carriers : "",
      discovery : false
    },
    page_loading_spinner : false
  }, action){

    if(action.users !== undefined)
      state.users = action.users
      
    if(action.users_filtered !== undefined)
      state.users_filtered = action.users_filtered
    
    if(action.countUsers !== undefined)
      state.countUsers = action.countUsers

    if(action.connected !== undefined)
      state.connected = action.connected

    if(action.page_loading_spinner !== undefined)
      state.page_loading_spinner = action.page_loading_spinner

    if(action.addModal !== undefined)
      state.addModal = action.addModal
    
    if(action.updateModal !== undefined)
      state.updateModal = action.updateModal

    if(action.deleteModal !== undefined)
      state.deleteModal = action.deleteModal

    if(action.userUpdated !== undefined)
      state.userUpdated = action.userUpdated

    if(action.confirmModal !== undefined)
      state.confirmModal = action.confirmModal

    if(action.exportModal !== undefined)
      state.exportModal = action.exportModal

    if(action.export_type !== undefined)
      state.export_type = action.export_type

    if(action.user !== undefined)
      state.user = action.user

    if(action.export !== undefined)
      state.export = !state.export

    if(action.users_csv !== undefined)
      state.users_csv = action.users_csv
    
    if((action.addUser !== undefined && action.addUser) || (action.updateUser !== undefined && action.updateUser)){
      
      if(action.first_name !== undefined)
      state.user.first_name = action.first_name

      if(action.last_name !== undefined)
        state.user.last_name = action.last_name ? action.last_name.toUpperCase() : ""

      if(action.region !== undefined)
        state.user.region = action.region
      
      if(action.entity !== undefined)
        state.user.entity = action.entity

      if(action.role !== undefined)
        state.user.role = action.role ? action.role.trim() : ""

      if(action.email !== undefined)
        state.user.email = action.email

      if(action.email_contact !== undefined)
        state.user.email_contact = action.email_contact

      if(action.carriers !== undefined)
        state.user.carriers = action.carriers

      if(action.except_carriers !== undefined)
        state.user.except_carriers = action.except_carriers

      if(action.discovery !== undefined)
        state.user.discovery = action.discovery

    }

    if(action.errors !== undefined)
      state.errors = action.errors

    if(action.error !== undefined ){

      state.error = action.error

      if(action.first_name !== undefined)
        state.errors.first_name = action.first_name

      if(action.last_name !== undefined)
        state.errors.last_name = action.last_name

      if(action.region !== undefined)
        state.errors.region = action.region

      if(action.entity !== undefined)
        state.errors.entity = action.entity 

      if(action.role !== undefined)
        state.errors.role = action.role

      if(action.email !== undefined)
        state.errors.email = action.email

      if(action.email_contact !== undefined)
        state.errors.email_contact = action.email_contact

      if(action.carriers !== undefined)
        state.errors.carriers = action.carriers

      if(action.except_carriers !== undefined)
        state.errors.except_carriers = action.except_carriers

      if(action.discovery !== undefined)
        state.errors.discovery = action.discovery

    }

    if(action.updateUser !== undefined && action.user !== undefined){
      state.user = action.user
    }

    return state
      
    },
      
    reducer_filters : function (state={
      hq : null,
      discovery : null,
      online : null,
      can_refresh : false,
      first_name : "", 
      last_name : "",
      entity : "All",
      region : "All",
      email : "",
      email_contact : "",
      role : "All",
      first_login : "",
      last_login : "",
      reset : false,  
    }, action){
      
      if(action.reset)
        state = {
          hq : null,
          discovery : null,
          online : null,
          can_refresh : false,
          first_name : "", 
          last_name : "", 
          entity : "All",
          region : "All",
          email : "",
          email_contact : "",
          role : "All",
          reset : false,
        };
     

    if(action.hq !== undefined){
      state.hq = action.hq && action.hq.toLowerCase() === "all" ? null : state.hq
      state.hq = action.hq === "true" ? true : state.hq
      state.hq = action.hq === "false" ? false : state.hq
    }

    if(action.discovery !== undefined){
      state.discovery = action.discovery && action.discovery.toLowerCase() === "all" ? null : state.discovery
      state.discovery = action.discovery === "true" ? true : state.discovery
      state.discovery = action.discovery === "false" ? false : state.discovery
    }

    
    if(action.online !== undefined){
      state.online = action.online && action.online.toLowerCase() === "all" ? null : action.online
      //state.online = action.online === "true" ? true : state.online
      //state.online = action.online === "false" ? false : state.online
    }
    
    if(action.first_name !== undefined)
      state.first_name = action.first_name

    if(action.last_name !== undefined)
      state.last_name = action.last_name

    if(action.region !== undefined)
    state.region = action.region

    if(action.entity !== undefined)
    state.entity = action.entity
  
    if(action.email !== undefined)
      state.email = action.email
  
    if(action.email_contact !== undefined)
      state.email_contact = action.email_contact
  
    if(action.role !== undefined)
      state.role = action.role
  
    if(action.first_login !== undefined)
      state.first_login = action.first_login
  
    if(action.last_login !== undefined)
      state.last_login = action.last_login
  
    if(action.first_name || action.last_name || action.role || action.email || action.email_contact || action.region || action.entity)
      state.can_refresh = true

    if(action.can_refresh !== undefined){
      state.can_refresh = action.can_refresh
    }
      
    return state
      
  }
}