import cookies from '../Cookies'
import Util from '../util.js';
import Routes from '../Routes.js'
import _ from 'lodash'
import util from '../util.js';

var shcList =   []
var priceClassList =  []
var serviceLevelList = []
var shc_csp =   []
 
async function init(){
  let data = await Routes.get("/PriceData", {}, true)
  if(!data) return null 
  shcList =  data.shcList
  shcList = shcList ? shcList : []
  priceClassList =  data.priceClassList
  priceClassList = priceClassList ? priceClassList : []
  serviceLevelList = data.serviceLevelList
  serviceLevelList = serviceLevelList ? serviceLevelList : []
  shc_csp =   data.shc_csp
  shc_csp = shc_csp ? shc_csp : []
  
}

var margin_init = {
          
  sell_rate : 0,
  sell_rate_all_in : 0,
  sell_net_net_rate : 0,
  sell_all_in_rate : 0,
  sell_min : 0,
  truck_sell : 0,
  handling_sell : 0,
  loc_addon_sell : 0,
  truck_sell_edit : 0,
  handling_sell_edit : 0,
  loc_addon_sell_edit : 0,

  buy_rate : 0,
  buy_rate_all_in : 0,
  buy_min : 0,
  buy_net_net_rate : 0,
  buy_all_in_rate : 0,
  all_in_buy_edit : 0,
  all_in_buy_edit2 : 0,
  net_buy_edit : 0,
  net_buy_edit2 : 0,
  truck_buy : 0,
  handling_buy : 0,
  loc_addon_buy : 0, 
  truck_buy_edit : 0,
  handling_buy_edit : 0, 

  truck_markup : 0,
  handling_markup : 0,
  loc_addon_markup : 0,

  fuel_rate : 0,
  security_rate : 0,
  commission_value : 0,
  margin: 0,
  charges : 0, 
}

var margin_state = {
  force : false,
  updateMargin : false,
  buy_rate_type : null,
  sell_rate_type : null,
  sell_total : 0,
  buy_total : 0,
  create_buy : false,
  buy_all_in : "",
  sell_all_in : "",
  buy_carrier : "",
  buy_price_class : "",
  sell_carrier : "",
  buy_currency : "",
  deal_code : null,
  buy_min : 0,
  sell_min : 0,
  commission_type : "",
  commission : null,
  fuel_type : null, 
  fuel_currency : null, 
  fuel_code : null,
  fuel_min : 0,
  fuel_max : 0,
  security_type : null, 
  security_currency : null,
  security_code : null, 
  security_min : 0,
  security_max : 0,
  per_kg : true,
  margin_in_total : {...margin_init},
  margin_per_kg : {...margin_init}
}



init();


export default   {
  
    reducer_schedule : function(state = {
      carrier_routings : [],
      schedule : [],
      from_date: (new Date()).toISOString(),
      force : 0,
      selected_routing : [],
      selected_routing_string : ""
    },
    action){
      
      let keys = Object.keys(action)
      keys.forEach((key)=>{
        if(key==="force")
        state[key]++
        else if(key ==="reset"){
          state = {
            carrier_routings : [
              {carrier:"AM" , routings:["AMS-CDG-MEX",  "AMS-MEX"] },  
              {carrier:"GF" , routings: ["AMS-MEX"]}
            ],
            schedule : [],
            from_date: (new Date()).toISOString(),
            force : 0,
            selected_routing : [],
            selected_routing_string : ""
          }
        }else
        state[key] = action[key]
      })

      return state 

    },

    reducer_calculator : function(state = {
      station : "",
      sales_region : null,
      origin_zone : null,
      origin : "",
      dest : "",
      agent : "",
      agent_code : "",
      agent_suggestion : [],
      agent_city : "",
      chargeable_weight : "",
      shc : ["", "", "", ""],
      carrier : "",
      from_date: (new Date()).toISOString(),
      to_date: (new Date()).toISOString(),
      force : 0,
      routing : [],
      detailed_info : false,
      costs : null,
      handle_cost : [],
      truck_cost : [],
      fuel_cost : [],
      security_cost : [],
      oc_cost : [],
      currency : "EUR",
      sell_buy : "S",
      per_kg : true,
      rate : 0,
      all_in : false,
      total_cost_trucking : 0, 
      total_cost_handling : 0,
      total_revenue : 0,
      total_cost : 0,
      total_contribution : 0,
      net_net : 0,
      cost_fuel : 0,
      cost_security : 0,
      calculator_errors : {},
      search : false,
      update : false,
      schedule : false,
    },
    action){
      
      let keys = Object.keys(action)

      keys.forEach((key)=>{

        if(key === "force")
          state[key]++
        else if(key === "shc" || key === "addRoute" || key === "removeRoute" || key === "index" || key === "updateRoute" || key === "detailed_info")
          return
        else if(key === "origin" || key === "dest" || key === "carrier")
          return
        else if(key ==="reset"){
          state = {
            station : "",
            sales_region : null,
            origin_zone : null,
            origin : "",
            dest : "",
            agent : "",
            agent_code : "",
            agent_suggestion : [],
            agent_city : "",
            chargeable_weight : "",
            shc : ["", "", "", ""],
            carrier : "",
            from_date: (new Date()).toISOString(),
            to_date: (new Date()).toISOString(),
            force : 0,
            routing : [],
            detailed_info : false,
            costs : null,
            handle_cost : [],
            truck_cost : [],
            fuel_cost : [],
            security_cost : [],
            oc_cost : [],
            currency : "EUR",
            sell_buy : "S",
            per_kg : true,
            rate : 0,
            all_in : false,
            total_cost_trucking : 0, 
            total_cost_handling : 0,
            total_revenue : 0,
            total_cost : 0,
            total_contribution : 0,
            net_net : 0,
            cost_fuel : 0,
            cost_security : 0,
            calculator_errors : {},
            search : false,
            update : false,
            schedule : false,
          }
        }else
          state[key] = action[key]

      })

      if(action.origin !== undefined && action.index === undefined){
        state.origin = action.origin.toUpperCase()
      }

      if(action.dest !== undefined && action.index === undefined){
        state.dest = action.dest.toUpperCase()
      }

      if(action.carrier !== undefined && action.index === undefined){
        state.carrier = action.carrier.toUpperCase()
      }

      if(action.shc !== undefined && action.index !== undefined){
        state.shc = [...state.shc]
        state.shc[action.index] = action.shc.toUpperCase() 
      }

      if(action.shc !== undefined && action.index === undefined){
        if(!action.shc) action.shc = ["","","",""]
        state.shc = action.shc
      }
      
      if(action.addRoute !== undefined){
        let new_origin = state.routing.length ? state.routing[state.routing.length-1].dest : ""
        let new_carrier = state.routing.length ? state.routing[state.routing.length-1].carrier : ""
        let length = state.routing.length ? state.routing.length : 0
        if(length < 10)
          state.routing.push({
            origin : new_origin,
            dest : "",
            carrier : new_carrier,
            flight_type : "Flight"
          });
      }

      if(action.removeRoute !== undefined && action.index !== undefined){
        state.routing.splice(action.index, 1)
      }

      if(action.updateRoute !== undefined && action.index !== undefined){

        if(action.origin !== undefined && state.routing.length > 0){
          state.routing[action.index].origin = action.origin.toUpperCase()
        }
          
        if(action.dest !== undefined){
          state.routing[action.index].dest = action.dest.toUpperCase()
          if(state.routing[action.index+1] && state.routing[action.index+1].origin !== undefined){
            state.routing[action.index+1].origin = action.dest.toUpperCase()
            state.force ++
          }
        }
  
        if(action.carrier !== undefined){
          state.routing[action.index].carrier = action.carrier.toUpperCase();
          if(state.routing[action.index+1] !== undefined && state.routing[action.index+1].carrier !== undefined)
          state.routing[action.index+1].carrier = action.carrier.toUpperCase();
        }
  
        if(action.flight_type !== undefined)
          state.routing[action.index].flight_type = action.flight_type
  
        if(action.flight_number !== undefined)
          state.routing[action.index].flight_number = action.flight_number
  
        if(action.flight_date !== undefined)
          state.routing[action.index].flight_date = action.flight_date.toUpperCase()
  
        if(action.aicraft_type !== undefined)
          state.routing[action.index].aicraft_type = action.aicraft_type
  
        if(action.etd !== undefined)
          state.routing[action.index].etd = action.etd
  
        if(action.eta !== undefined)
          state.routing[action.index].eta = action.eta
  
        if(action.shipment_split !== undefined){
          let val = !action.shipment_split  ? "" : Math.round(action.shipment_split*1000)/1000
          val = val ? val : 0
          state.routing[action.index].shipment_split  = val
        }
  
        if(action.weekly_frequency !== undefined)
          state.routing[action.index].weekly_frequency = action.weekly_frequency
  
        if(action.operation_dow !== undefined)
          state.routing[action.index].operation_dow = action.operation_dow
  
      }

      if(action.detailed_info !== undefined){
        state.detailed_info = action.detailed_info;
        if(state.detailed_info){
          state.routing = state.routing.map((r)=>{
            return {  
              origin : r.origin, 
              dest : r.dest, 
              carrier : r.carrier, 
              flight_type : r.flight_type, 
              flight_number : r.flight_number, 
              flight_date : r.flight_date,  
              etd : r.etd,  
              eta : r.eta,  
              shipment_split : r.shipment_split
            }
          })
        }else{
          state.routing = state.routing.map((r)=>{
            return {  
              origin : r.origin, 
              dest : r.dest, 
              carrier : r.carrier, 
              flight_type : r.flight_type,  
              weekly_frequency : r.weekly_frequency, 
              operation_dow : r.operation_dow
            }
          })
        }
      }


      return state 

    },

    reducer_history : function(
      state={
        last_modified : null, 
        last_active : null, 
        history : [], 
        first_save : false, 
        quote  : {
          id : "",
          changes : "all", 
          status : "DRAFT",
          adhoc_ref : "", 
          awb_serial: "", 
          agent : "", 
          origin : "", 
          dest : "",
          pieces : "",
          gross_weight : "",
          volume : "",
          chargeable_weight : "",
          shc : ["","",""],
          target_price : "",
          target_currency : "EUR",
          target_per_kg : true,
          carrier : "",
          currency : "EUR",
          selling_rate : "",
          all_in : false,
          per_kg : true,
          min_weight : 0,
          min_amount : 0,
          edit_buy_kg : null,
          edit_buy_currency : 'EUR',
          update_by : cookies.account,
          send_adhoc : false,
          send_ffr : false,
          send_equote : false,
        }
      }, action){
  
        let keys = Object.keys(action)
        keys.forEach((key)=>{
          if(key === 'send_equote' || key === 'send_adhoc' || key === 'send_ffr'){
            state.quote[key] = action[key]
          }else
            state[key] = action[key]
        })
        return state 
    },

    reducer_config : function(
      state={
        config : {
          sales_conditions : null, 
          currencies : [], 
          default_currency : "EUR"
        }
      }, action){

        let st = {...state}
        if(action.config && action.config !== null) 
          st.config = action.config
        return st  
    },

    reducer_modals : function(
      state={
        modalPrint : false, 
        modalDimensions : false,
        modalAgent : false,
        modalAgentCalculator : false,
        ModalSimilar : false, 
        ModalSimilarExact : false, 
        modalQuoted : false,
        modalFail : false,
        modalDeleteStatus : false,
        sellingTable : false,
        modalRemarkExt : false,
        modalRemarkInt : false,
        page_loading_spinner : false,
        modalConfirmationAdhoc : false,
        modalUpdatePacking : false,
        modalRefresh : false,
        quit_url : null,
        modalAllin : false,
        modalCloneAttachment : false,
        modalGDPR : false,
        quote_saved : true,
        modalBooking : false,
        modalAttachment : false,
        page_loading_spinner2 : false,
        ocdc_spinner : false,
        updateAdhoc : false,
        modalConfirmationEquote:false,
        modalQuoteHistory : false,
        modalPackingText : false,
        modalRouting : false,
        modalSchedule : false,
        modalDownload : false,
        modalRateSelect : false,
        modalCalculator : false

      }, action){
        
        let state_={...state}
        if(action.reset){
          state_ = {
            modalPrint : false, 
            modalDimensions : false,
            modalAgent : false,
            modalAgentCalculator : false,
            ModalSimilar : false,
            ModalSimilarExact : false,  
            modalQuoted : false,
            modalFail : false,
            modalDeleteStatus : false,
            sellingTable : false,
            modalRemarkExt : false,
            modalRemarkInt : false,
            page_loading_spinner : false,
            modalConfirmationAdhoc : false,
            modalConfirmationEquote : false,
            modalUpdatePacking : false,
            modalRefresh : false,
            modalAllin : false,
            modalCloneAttachment : false,
            modalGDPR : false,
            modalAttachment : false,
            quit_url : null,
            modalBooking : false,
            quote_saved : true,
            modalDownload : false,
            page_loading_spinner2:false,
            modalQuoteHistory : false,
            ocdc_spinner : false,
            modalPackingText:false ,
            modalRateSelect : false

          }
        
        }
    
        let keys = Object.keys(action)
        keys.forEach((key)=>{
            state_[key] = action[key]
        })
        return state_
    },

    reducer_prices : function(
      state={
        buy_prices : [],
        sell_prices : [],
        filtered : [], 
        carrier : true, 
        weight : true, 
        price_class : true, 
        dest : true,
        selected_rate : null,
        sell : null,
        buy : null,
      }, action){
        
        
      if(action.reset !== undefined){
        state={
          buy_prices : [],
          sell_prices : [],
          filtered : [], 
          carrier : true, 
          weight : true, 
          price_class : true, 
          dest : true,
          selected_rate : null,
          sell : null,
          buy : null,
        }
      }

      if(action.sell_prices !== undefined){
        state.sell_prices = action.sell_prices
    
        if(!_.isEqual(state.sell_prices,state.filtered))
          state.filtered = state.sell_prices
      }

      if(action.buy_prices !== undefined){
        state.buy_prices = action.buy_prices
    
        //if(!_.isEqual(state.all,state.filtered))
          //state.filtered = state.all
      }
    
      if(action.carrier !== undefined){
        state.carrier =action.carrier;
      }
    
      if(action.weight !== undefined)
        state.weight = action.weight;
     
      if(action.price_class !== undefined)
        state.price_class = action.price_class;
    
      if(action.dest !== undefined)
        state.dest = action.dest;    
    
      if(action.agent !== undefined)
        state.agent = action.agent;   
      
      if(action.selected_rate !== undefined)
        state.selected_rate = action.selected_rate

      if(action.sell !== undefined)
        state.sell = action.sell
      
      if(action.buy !== undefined)
        state.buy = action.buy
     
      return state
      
    },
    
    reducer_alerts : function(
      state={
        top :{ title:"aa", msg:"aaa", type:"error", show:"false"},
        margin  : { title:"", msg:"", type:"", show:"false"},
        force : 0
      }
      , action){
      
      if(action.alert !== undefined)
        state[action.name] = action.alert

         
      if(action.alert !== undefined)
        state.force =  state.force + 1;
        
      if(action.force !== undefined)
        state.force =  state.force + 1;
        
      return state
    },

    reducer_routes : function(state ={
      force : true,
      detailed_info : false,
      force_line : 0,
      check : false,
      expectedTime: 0,
      timeIsNegative: false,
      routes : [{
        origin : "",
        dest : "",
        carrier : "",
        flight_number : "",
        flight_date : "",
        flight_type : "Flight",
        aicraft_type : "",
        etd : "",
        eta : "",
        shipment_split : ""
      }]
      }, action){
      
        let st = {...state}

        if(action.reset !== undefined){
          if(st.routes.length > 1) {
            st.routes = st.routes.splice(0,st.routes.length-1)
          } 
        } 
      
        if(action.detailed_info !== undefined){
          st.detailed_info = action.detailed_info
          if(st.detailed_info){
            st.routes = st.routes.map((r)=>{
              return {  
                origin : r.origin, 
                dest : r.dest, 
                carrier : r.carrier, 
                flight_type : r.flight_type, 
                flight_number : "", 
                flight_date : "",  
                etd : "",  
                eta : "",  
                shipment_split : ""
              }
            })
          }else{
            st.routes = st.routes.map((r)=>{
              return {  
                origin : r.origin, 
                dest : r.dest, 
                carrier : r.carrier, 
                flight_type : r.flight_type,  
                weekly_frequency : "", 
                operation_dow : ""
              }
            })
          }
        }
      
        if(action.reset_ !== undefined){
      
          st.routes =[ {
            origin : "",
            dest : "",
            carrier : "",
            flight_number : "",
            flight_date : "",
            flight_type : "Flight",
            aicraft_type : "",
            etd : "",
            eta : "",
            shipment_split : ""} 
          ]
          st.detailed_info = false
          st.force = true
          
        } 

        if(action.expectedTime !== undefined ){

          let expectedTime = action.expectedTime ? action.expectedTime : ""
          expectedTime = expectedTime ? parseInt(expectedTime) : 0
          st.expectedTime = expectedTime > 0 ? expectedTime : ""
        }

        if(action.timeIsNegative !== undefined){
          st.timeIsNegative = action.timeIsNegative
        }
        
        if(action.removeALL)
          st.routes = [{
            origin : "",
            dest : "",
            carrier : "",
            flight_number : "",
            flight_date : "",
            flight_type : "Flight",
            aicraft_type : "",
            etd : "",
            eta : "",
            shipment_split : ""
          }]

        if(action.force)
          st.force = !st.force
        
        if(action.force_line)
          st.force_line = st.force_line + 1

        if(action.addRoute !== undefined){
          let new_origin = st.routes.length ? st.routes[st.routes.length-1].dest : ""
          let new_dest = st.routes.length ? st.routes[0].origin : ""
          let length = st.routes.length ? st.routes.length : 0


          if(length < 10){

            if(action.toTop){
              st.routes.unshift({
                origin : "",
                dest : new_dest,
                carrier : "",
                flight_type : "Flight"
              })
            }else{

              st.routes.push({
                origin : new_origin,
                dest : "",
                carrier : "",
                flight_type : "Flight"
              })

            }
           

          }
           
        }

        if(action.removeRoute !== undefined && action.index !== undefined){
          st.routes.splice(action.index, 1)
        }

        if(action.check !== undefined)
          st.check = !st.check
        
        if(action.index !== undefined){
            if(action.origin !== undefined && st.routes.length > 0){
              st.routes[action.index].origin = action.origin.toUpperCase()
            }
              
            if(action.dest !== undefined){
              st.routes[action.index].dest = action.dest.toUpperCase()
              if(st.routes[action.index+1] && st.routes[action.index+1].origin !== undefined){
                st.force_line = st.force_line + 1
                st.routes[action.index+1].origin = action.dest.toUpperCase()
              }
            }
      
            if(action.carrier !== undefined){
              st.routes[action.index].carrier = action.carrier.toUpperCase();
              if(st.routes[action.index+1] !== undefined && st.routes[action.index+1].carrier !== undefined)
                st.routes[action.index+1].carrier = action.carrier.toUpperCase();
            }
      
            if(action.flight_type !== undefined)
              st.routes[action.index].flight_type = action.flight_type
      
            if(action.flight_number !== undefined)
              st.routes[action.index].flight_number = action.flight_number
      
            if(action.flight_date !== undefined)
              st.routes[action.index].flight_date = action.flight_date.toUpperCase()
      
            if(action.aicraft_type !== undefined)
              st.routes[action.index].aicraft_type = action.aicraft_type
      
            if(action.etd !== undefined)
              st.routes[action.index].etd = action.etd
      
            if(action.eta !== undefined)
              st.routes[action.index].eta = action.eta
      
            if(action.shipment_split !== undefined){
              let val = !action.shipment_split  ? "" : Math.round(action.shipment_split*1000)/1000
              val = val ? val : 0
              st.routes[action.index].shipment_split  = val
            }
      
            if(action.weekly_frequency !== undefined)
              st.routes[action.index].weekly_frequency = action.weekly_frequency
      
            if(action.operation_dow !== undefined)
              st.routes[action.index].operation_dow = action.operation_dow
      
        }
      
        if(action.routes !== undefined){
          st.routes = action.routes.routes.length > 0 ? action.routes.routes : st.routes ;
          st.detailed_info =action.routes.routes.length > 0 ? action.routes.detailed_info : st.detailed_info; 
        }
        
        return st
    },

    reducer_station : function(state={
      authorized_stations : [], 
      sales_region : null, 
      origin_zone : null
      }, action){
      
        let keys = Object.keys(action)
        keys.forEach((key)=>{
          state[key] = action[key]
        })
        return state
    },

    reducer_shipment : function (state ={
      service_level : null,
      price_class : null,
      stackable : "Yes",
      main_deck : false,
      is_min_chargeable_weight : false,
      screened : false,
      exw : false,
      force : false,
      agent : "",
      agent_code : "",
      agent_city : "",
      agent_suggestion : [],
      origin : "",
      dest : "",
      pieces : "",
      gross_weight :"",
      volume :"",
      chargeable_weight:"",
      chargeable_weight_manual:"",
      density : "",
      available_origin: Util.getCurrentDate(),
      shc :["", "", "",""],
      commodity : "General Cargo",
      commodity_code : "GCR",
      nature_goods : "",
      showModal : false,
      packing : [],
      packing_text : [],
      uld : [],
      uld_copy : [],
      uld_change : false,
      uld_change2 : 0,
      uld_settings : [],
      dimension_type : "loose",
      packing_copy : [],
      packing_change : false,
      all_stackable : true,
      all_stackable_copy : true,
      packing_total_wgt : 0,
      packing_total_vol : 0,
      packing_total_pcs : 0,
      packing_stackable : 'Yes',
      packing_stackable_copy : 'Yes',
      reset : false,
      target_per_kg : true,
      weight_system : "metric",
      weight_system_copy : "metric",
      length_system : "metric",
      length_system_copy : "metric", 
      packing_change2 : 0, 
      ffr_sent : false, 
      email_sent : false, 
      ffr_sent_date : null,
      equote_sent : false, 
      skypallet_id : null, // id du calcul skypallet si existant, 
      ops_chargeable_weight : null, 
      uld_plan : null 
    }, action) {
        
      if(action.shipment !== undefined){
        state = action.shipment
      }

      if(action.reset !== undefined){
        return {
          reset : !state.reset,
          service_level : null,
          price_class : null,
          stackable : 'Yes',
          all_stackable : true,
          all_stackable_copy : true,
          main_deck : false,
          is_min_chargeable_weight: false,
          screened : false,
          exw : false,
          force : false,
          agent : "",
          agent_code : "",
          agent_city : "",
          agent_suggestion : [],
          origin : "",
          dest : "",
          pieces : "",
          gross_weight :"",
          volume :"",
          chargeable_weight:"",
          chargeable_weight_manual : "",
          density : "",
          available_origin: Util.getCurrentDate(),
          shc :["", "", "",""],
          commodity : "General Cargo",
          commodity_code : "GCR",
          nature_goods : "",
          showModal : false,
          packing : [ ],
          packing_text : [],
          uld : [],
          uld_copy : [],
          uld_change2 : 0,
          uld_change : false,
          dimension_type : "loose",
          uld_settings : state.uld_settings,
          packing_copy : [],
          packing_change : false,
          packing_total_wgt : 0,
          packing_total_vol : 0,
          packing_total_pcs : 0,
          packing_stackable : 'Yes',
          packing_stackable_copy : 'Yes',
          target_per_kg : true,
          weight_system : "metric",
          weight_system_copy : 'metric',
          length_system : "metric",
          length_system_copy : 'metric',
          packing_change2 : 0,
          ffr_sent : false, //FFR send to CSP true || false
          email_sent : false, //FFR send by email true || false
          ffr_sent_date : null, // Datetime FFR has been sent to CSP
          equote_sent : false
        }
      }
        
      state = {...state}
          
      if(action.ops_chargeable_weight !== undefined)
        state.ops_chargeable_weight = action.ops_chargeable_weight

      if(action.uld_plan !== undefined)
        state.uld_plan = action.uld_plan

      if(action.uld_settings !== undefined)
        state.uld_settings = action.uld_settings

      if(action.uld !== undefined)
        state.uld = action.uld

      if(action.dimension_type !== undefined)
        state.dimension_type = action.dimension_type

      if(action.ffr_sent !== undefined)
        state.ffr_sent = action.ffr_sent

      if(action.email_sent !== undefined)
        state.email_sent = action.email_sent

      if(action.ffr_sent_date !== undefined)
        state.ffr_sent_date = action.ffr_sent_date

      if(action.packing_change2 !== undefined)
        state.packing_change2 ++

      if(action.uld_change2 !== undefined)
        state.uld_change2 ++

      if(action.uld_change !== undefined)
        state.uld_change = action.uld_change

      if(action.weight_system !== undefined)
        state.weight_system = action.weight_system

      if(action.weight_system_copy !== undefined)
        state.weight_system_copy = action.weight_system_copy

      if(action.length_system_copy !== undefined)
        state.length_system_copy = action.length_system_copy

      if(action.length_system !== undefined)
        state.length_system = action.length_system

      if(action.target_per_kg !== undefined){
          state.target_per_kg = action.target_per_kg
      }

      if(action.stackable !== undefined)
        state.stackable = action.stackable

      if(action.packing_stackable !== undefined)
        state.packing_stackable = action.packing_stackable

      if(action.packing_stackable_copy !== undefined)
        state.packing_stackable_copy = action.packing_stackable_copy

      if(action.packing_copy !== undefined)
        state.packing_copy = action.packing_copy.map( function (e){ return {...e} } )

      if(action.uld_copy !== undefined)
        state.uld_copy = action.uld_copy.map( function (e){ return {...e} } )

      if(action.packing_change !== undefined){
        state.packing_change = action.packing_change
        if(action.packing_change) state.packing_change2 = state.packing_change2 ? state.packing_change2 + 1 : 1
      }

      if(action.packing !== undefined)
        state.packing = action.packing

      if(action.packing_text !== undefined){
        let texts = action.packing_text ? action.packing_text : []
        texts = texts.filter((t) => t !== "")
        state.packing_text = texts
      }
      
      if(action.main_deck !== undefined)
        state.main_deck = action.main_deck

      if(action.is_min_chargeable_weight !== undefined)
        state.is_min_chargeable_weight = action.is_min_chargeable_weight

      if(action.screened !== undefined)
        state.screened = action.screened

      if(action.exw !== undefined)
        state.exw = action.exw
      
      if(action.force !== undefined)
        state.force = !state.force
      
      if(action.agent !== undefined){
          state.agent = action.agent; // this causes bug when saving in DB
      }
      
      if(action.agent_code !== undefined)
        state.agent_code = action.agent_code
        
      if(action.agent_city !== undefined)
        state.agent_city = action.agent_city? action.agent_city.toUpperCase() : ""
      
      if(action.agent_suggestion !== undefined)
        state.agent_suggestion = action.agent_suggestion
      
      if(action.origin !== undefined)
        state.origin = action.origin ? action.origin.toUpperCase() : ""
      
      if(action.dest !== undefined)
        state.dest = action.dest ? action.dest.toUpperCase() : ""
      
      if(action.pieces !== undefined)
        state.pieces = isNaN(parseInt(action.pieces)) ? "" : action.pieces
      
      if(action.gross_weight !== undefined){
        state.gross_weight = typeof(action.gross_weight) === "string" ? Math.round(action.gross_weight*100)/100.00 : action.gross_weight  
        state.gross_weight = isNaN(parseFloat(state.gross_weight)) ? "" : parseFloat(state.gross_weight)
        state.gross_weight = state.gross_weight > 0 ? state.gross_weight : ""
        //calculate chargeable weight from gross weight & volume
        state.chargeable_weight = (state.volume * (1000/6) > state.gross_weight  ? 
        state.volume * (1000/6) : state.gross_weight );
        state.chargeable_weight = Math.round(Math.ceil(state.chargeable_weight*2.00))/2.00    
        state.chargeable_weight = state.chargeable_weight > 0 ? state.chargeable_weight : ""
        state.chargeable_weight_manual = state.chargeable_weight
        //calculate density
        state.density =  (parseInt(((state.gross_weight / (state.volume))*100)/100) )
      }
      
      if(action.density)
        state.density = action.density

      if(action.available_origin !== undefined)
        state.available_origin = action.available_origin;
      
      if(action.volume !== undefined){   
        state.volume = util.formatVolume(action.volume)
        state.volume = state.volume > 0 ? state.volume : ""
          //calculate chargeable weight from gross weight & volume
        state.chargeable_weight = (state.volume * (1000/6) > state.gross_weight  ? 
        state.volume * (1000/6) : state.gross_weight );
        state.chargeable_weight = Math.round(Math.ceil(state.chargeable_weight*2.00))/2.00    
        state.chargeable_weight = state.chargeable_weight > 0 ? state.chargeable_weight : ""
        state.chargeable_weight_manual = state.chargeable_weight
        //density
        state.density =  ( parseInt(((state.gross_weight / (state.volume))*100)/100) )
      }
      
      if(action.chargeable_weight !== undefined){    
        state.chargeable_weight = typeof(action.chargeable_weight) === "string" ? Math.round(Math.ceil(action.chargeable_weight*2.00))/2.00 : action.chargeable_weight
        state.chargeable_weight = state.chargeable_weight > 0 ? state.chargeable_weight : ""

      }
      
      if(action.shc !== undefined && action.index !== undefined){
        state.shc = [...state.shc]
        state.shc[action.index] = action.shc.toUpperCase() 
        let shc_ = shcList.filter((ele)=> ele.shc === state.shc[0] ||  ele.shc === state.shc[1] || ele.shc === state.shc[2] || ele.shc === state.shc[3] || (state.shc[4] && ele.shc === state.shc[4] ))  
        let commodity = "";
        let commodity_code = "";
        let maxPriority = 100
        shc_.forEach((ele)=>{
          if(parseInt(ele.priority) <= maxPriority){
            maxPriority = ele.priority;
            commodity = ele.commodity_name;
            commodity_code = ele.commodity_code
          }
        })
      
        if(shc_.length === 0){
          commodity = "General Cargo"
          commodity_code = "GCR"
        }
            
        state.commodity = commodity;
        state.commodity_code = commodity_code
      }
        
      if(action.price_class)
        state.price_class = action.price_class

      if(action.service_level)
        state.service_level = action.service_level
          
      if(action.calculate_price_class || action.calculate_service_level){
        //let's get all group first
        let shc_group  = shc_csp.filter((ele)=> {
          return((ele.code === state.shc[0] || ele.code === state.shc[1] || ele.code === state.shc[2] || ele.code === state.shc[3] || (state.shc[4] && ele.shc === state.shc[4] )) 
            && ele.shc_group !== null)}).map((e)=>e.shc_group).sort()[0]    
        //shc_group = shc_group ? shc_group.shc_group : undefined    
        //exact match
        let price_class  = priceClassList.filter((ele)=> {
          return (ele.sales_region === action.sales_region && (ele.for_carrier === action.carrier ) && 
            ((ele.shc === state.shc[0] || ele.shc === state.shc[1] || ele.shc === state.shc[2] || ele.shc === state.shc[3]) || (state.shc[4] && ele.shc === state.shc[4] ) ||
            (ele.shc === shc_group || ele.shc === shc_group || ele.shc === shc_group)))})[0]
          //si pas de match exact, on match à minima sur la sales_region & shc
          if(!price_class) price_class = priceClassList.filter((ele)=> {
            return(ele.sales_region === action.sales_region && Util.isDeepEmpty(ele.for_carrier)
              && ((ele.shc === state.shc[0] || ele.shc === state.shc[1] || ele.shc === state.shc[2] || ele.shc === state.shc[3] || (state.shc[4] && ele.shc === state.shc[4] )) || (ele.shc === shc_group || ele.shc === shc_group || ele.shc === shc_group)))})[0]
          //si pas de match exact, on match à minima sur la for_carrier & shc
          if(!price_class) price_class = priceClassList.filter((ele)=> {
            return(ele.for_carrier === action.carrier && Util.isDeepEmpty(ele.sales_region)
              && ((ele.shc === state.shc[0] || ele.shc === state.shc[1] || ele.shc === state.shc[2] || ele.shc === state.shc[3] || (state.shc[4] && ele.shc === state.shc[4] )) || (ele.shc === shc_group || ele.shc === shc_group || ele.shc === shc_group)))})[0]
        //sinon juste sur le shc
          if(!price_class) price_class = priceClassList.filter((ele)=> {
            return(( Util.isDeepEmpty(ele.sales_region)
              && Util.isDeepEmpty(ele.for_carrier)
              && ((ele.shc === state.shc[0] || ele.shc === state.shc[1] || ele.shc === state.shc[2] || ele.shc === state.shc[3] || (state.shc[4] && ele.shc === state.shc[4] )) || (ele.shc === shc_group || ele.shc === shc_group || ele.shc === shc_group))))} )[0]
       
        state.price_class = price_class ?  price_class.price_class : null
        state.price_class = state.nature_goods && state.nature_goods.toUpperCase().indexOf("FRESHVIC") >= 0 ?  "FRESHVIC" :  state.price_class

        //calculate product from shc list
        state.service_level  = serviceLevelList.filter((ele)=> {
          return(ele.for_carrier === action.carrier 
          && ( (ele.shc === state.shc[0] || ele.shc === state.shc[1] || ele.shc === state.shc[2] || ele.shc === state.shc[3] || (state.shc[4] && ele.shc === state.shc[4]))
              ||  (ele.shc === shc_group || ele.shc === shc_group || ele.shc === shc_group)
          ))})[0]
          
        state.service_level = state.service_level ?  state.service_level.service_level : null
      
      }
      
      if(action.shc !== undefined && action.index === undefined){
        if(!action.shc) action.shc = ["","","",""]
        state.shc = action.shc
      }

      if(action.commodity_code !== undefined){
        state.commodity_code = action.commodity_code
      }
    
          
      if(action.commodity !== undefined)
        state.commodity = action.commodity
      
      if(action.nature_goods !== undefined)
        state.nature_goods = action.nature_goods
      
      if(action.showModal !== undefined)
        state.showModal = action.showModal
      
      if(action.packingExcel) 
        state.packing = action.packingExcel

      if(action.addLine && state.packing.length < 50){
        state.packing.push({
          pieces : "", 
          length : "",
          width : "",
          height : "", 
          weight : "", 
          stackable : state.all_stackable
        })
      }
      
      if(action.removeLine && state.packing.length > 0  ){
        state.packing.splice(action.index, 1)
      }
      
      if(action.addUldLine){
        state.uld.push({
          pieces : "", 
          deck : "lower", 
          position : "", 
          weight : ""
        })
      }
      
      if(action.removeUldLine &&   state.uld.length > 0 && action.index !== undefined ){
        state.uld.splice(action.index, 1)
      }

      if(action.uld_change && action.index !== undefined){
        if(action.pieces !== undefined){      
          state.uld[action.index].pieces = typeof(action.pieces) === "string" ?
          isNaN(parseInt(action.pieces)) ? "" : parseInt(action.pieces)  : action.pieces
          state.uld_change2 = state.uld_change2 ? state.uld_change2 + 1 : 1
        }
           
        if(action.deck !== undefined){      
          state.uld[action.index].deck = action.deck
          state.uld_change2 = state.uld_change2 ? state.uld_change2 + 1 : 1
        }
           
        if(action.position !== undefined)
          state.uld[action.index].position = action.position

        if(action.weight !== undefined){      
          let weight  = typeof(action.weight) === "string" ?
          isNaN(parseFloat(action.weight.replace(",", "."))) ? 
          0 : parseFloat(action.weight.replace(",", ".")) : action.weight
          weight = util.formatNumber(weight,2)
          state.uld[action.index].weight = isNaN(parseFloat(weight)) ? "" : parseFloat(weight)
        }    
      }
      
      if(action.index !== undefined && action.updatePacking !== undefined){    
        if(action.pieces !== undefined){
          let pieces  = typeof(action.pieces) === "string" ? parseFloat(action.pieces) : action.pieces  
          state.packing[action.index].pieces = pieces
        }
           
        if(action.length !== undefined)
          state.packing[action.index].length = isNaN(parseInt(action.length)) ? 0 : parseInt(action.length) 
      
        if(action.width !== undefined)
          state.packing[action.index].width =  isNaN(parseInt(action.width)) ? 0 : parseInt(action.width) 
      
        if(action.height !== undefined)
          state.packing[action.index].height =  isNaN(parseInt(action.height)) ? 0 : parseInt(action.height) 
      
        if(action.weight !== undefined){
          let weight  = typeof(action.weight) === "string" ?
          isNaN(parseFloat(action.weight.replace(",", "."))) ? 
          0 : parseFloat(action.weight.replace(",", ".")) : action.weight
          weight = util.formatNumber(weight,2)
          state.packing[action.index].weight = parseFloat(weight) ? parseFloat(weight) : ""
        }
 
        if(action.stackable !== undefined)
          state.packing[action.index].stackable =  !state.packing[action.index].stackable
           
      }

      if(action.all_stackable !== undefined || action.all_stackable_select !== undefined)
        state.all_stackable = action.all_stackable

      if(action.all_stackable_copy !== undefined)
        state.all_stackable_copy =  action.all_stackable_copy
 
      if(action.updatePacking !== undefined && action.all_stackable !== undefined){      
          for(let i = 0; i< state.packing.length; i++){
            state.packing[i].stackable = action.all_stackable
          }
          state.all_stackable = action.all_stackable    
      }
       
      if(action.calculatePacking !== undefined){
        let weightT = 0;
        let piecesT = 0;
        let volT = 0;
        let stackable = false
        let no_stackable = false
        state.lock_weight = true
        state.packing.forEach((ele)=>{
          let pieces = ( isNaN(parseInt(ele.pieces)) ?  0  : parseInt(ele.pieces));
          piecesT = piecesT + pieces ;  
          let height = isNaN(parseFloat(ele.height)) ?  0  : parseFloat(ele.height)/100;
          let width = isNaN(parseFloat(ele.width)) ?  0  : parseFloat(ele.width)/100;
          let length = isNaN(parseFloat(ele.length)) ?  0  : parseFloat(ele.length)/100;
          let _height = isNaN(parseFloat(ele.height)) ?  0  : parseFloat(ele.height);
          let _width = isNaN(parseFloat(ele.width)) ?  0  : parseFloat(ele.width);
          let _length = isNaN(parseFloat(ele.length)) ?  0  : parseFloat(ele.length);
          let vol = height * width * length;
          let weight = isNaN(parseFloat(ele.weight)) ?  0  : parseFloat(ele.weight)   
          state.lock_weight = weight === 0 ? false : state.lock_weight   
          volT = volT + pieces*vol;
          weightT = weightT +   weight;
          //stackable = ele.stackable ? stackable   : false
          if(ele.stackable){
            stackable = true
          }else{
            no_stackable = true
          } 
          //AUTO SHC
          if(state.dimension_type === 'loose' &&
           ( (state.length_system === "metric" && (_length > 317 || _width > 317)) || (state.length_system === "imperial" && (_length > 125 || _width > 125)) )){
              if(!state.shc.includes('OHG')){
                let i = state.shc.indexOf("")
                if(i>=0) state.shc[i] = 'OHG'
              }

          }else{
            let i = state.shc.indexOf("OHG")
            if(i>=0) state.shc[i] = ''
          }

          if(state.dimension_type === 'loose' && 
          ( (state.length_system === "metric" && (_height > 160 || _length > 400 || _width > 400) ) || (state.length_system === "imperial" && _height > 63 ) )){
            if(!state.main_deck){
                state.main_deck = true
            }
          }else{
            state.main_deck = false
          }
               
        })
      
        if(state.length_system === "imperial")
          volT *=  Math.pow(2.54,3)  

        if(state.weight_system === "imperial")
          weightT *=  0.453592 

        state.volume = util.formatVolume(volT)
        state.pieces = piecesT;

        if( weightT > 0 ){
          state.gross_weight = Math.round(100*weightT)/100;
        }
           
        //keeping this result here because volume & gross weight can be override by user 
        state.packing_total_wgt = state.gross_weight
        state.packing_total_vol = parseFloat(state.volume)
        state.packing_total_pcs = piecesT
        //state.packing_stackable = stackable
        //calculate chargeable weight
        state.chargeable_weight = (state.volume * (1000/6) > state.gross_weight  ? state.volume * (1000/6) : state.gross_weight )
        state.chargeable_weight = Math.round(Math.ceil(state.chargeable_weight*2.00))/2.00  
        state.chargeable_weight_manual = state.chargeable_weight
          //state.stackable = stackable;
        if(state.packing.length === 0){
          state.packing_stackable = state.stackable
        }else if(stackable && no_stackable){
          state.stackable = 'Partially'
          state.packing_stackable = 'Partially'
        }else if(stackable && !no_stackable){
          state.stackable = 'Yes'
          state.packing_stackable = 'Yes'
        } else if(!stackable && no_stackable){
          state.stackable = 'No'
          state.packing_stackable = 'No'
        }

        state.density =  ( parseInt(((state.gross_weight / (state.volume))*100)/100) )
      
      }

      if(action.calculateUld !== undefined){
        let weightT = 0;
        let piecesT = 0;
        let volumeT = 0;
        let chargeable_weightT = 0;
        let main_deck = false
        state.lock_weight = true
        state.uld.forEach((ele)=>{
          let pieces = ( isNaN(parseInt(ele.pieces)) ?  0  : parseInt(ele.pieces));
          let weight =  (isNaN(parseFloat(ele.weight))) ? 0 : parseFloat(ele.weight)
          let position = ele.position ? ele.position : ""
          let volume = 0
          let pivot = 0  
          piecesT = piecesT + pieces ;
          weightT = weightT + (pieces * weight) ;

          for(let i = 0; i < state.uld_settings.length; i++){
            if(position && state.uld_settings[i].position === position){    
              pivot = state.uld_settings[i].pivot_weight;
              volume =  state.uld_settings[i].volume;  
              pivot = isNaN(parseFloat(pivot)) ? 0 : parseFloat(pivot);
              volume = isNaN(parseFloat(volume)) ? 0 : parseFloat(volume)                  
            }
          }

          let max_weight = weight > pivot ? weight : pivot
          chargeable_weightT = chargeable_weightT  + (pieces * max_weight)
          volumeT = volumeT + (pieces* volume)
            
          if(ele.deck === 'main') 
            main_deck = true

          if(!state.shc.includes('BUP')){
            let i = state.shc.indexOf("")
            if(i>=0) state.shc[i] = 'BUP'
          }

          if(state.dimension_type !== "uld" && state.shc.includes('BUP') ){
            let i = state.shc.indexOf("BUP")
            if(i>=0) state.shc[i] = ''
          }
           
          state.lock_weight = weight === 0 ? false : state.lock_weight  
        })
      
        if(state.length_system === "imperial")
          volumeT *=  Math.pow(2.54,3)  

        if(state.weight_system === "imperial")
          weightT *=  0.453592 
  
        state.pieces = piecesT;
        
        if(weightT && weightT > 0 ){
          state.gross_weight = Math.round(100*weightT)/100;
        }else{
          state.gross_weight = ""
        }

        state.chargeable_weight = chargeable_weightT   
        state.volume = Math.round(100*volumeT)/100
        state.density = state.gross_weight && state.volume ? ( parseInt(((state.gross_weight / (state.volume))*100)/100) ) : 0  
        state.main_deck = main_deck
        state.packing_total_wgt = state.gross_weight
        state.packing_total_vol = parseFloat(state.volume)
        state.packing_total_pcs = piecesT   
      }

      if(action.skypallet_id !==undefined)
        state.skypallet_id = action.skypallet_id

      if(action.equote_sent !==undefined)
        state.equote_sent = action.equote_sent

      return state
    },
    
    reducer_errors : function(state = {
      current_input : "",
      pieces : "",
      origin : "",
      dest : "",
      gross_weight : "",
      chargeable_weight : "",
      volume : "",
      issue_carrier : "",
      is_invalid_carrier : false,
      price : "",
      flight_date : "",
      routing_carrier : "",
      margin : null,
      agent : "",
      agent_code : "",
      validity_date :"",
      from_date : "",
      to_date : "",
      to_date_1 : "",
      weight_mismatch : "",
      volume_mismatch : "",
      pieces_mismatch : "",
      stackable_mismatch : "",
      stackable_fill : "",
      stackable_fill_invalid : [],
      available_origin: "",
      buy_min : null,
      sell_min : null, 
      min_chargeable_weight : null,
      sell_prices : null,
      flight_date_idxs : [],
      carriers_idxs : [],
      flight_number_idxs : [],
      flight_eta_idxs : [],
      flight_etd_idxs : [],
      board_points : [],
      off_points : []
    }, action){
        
      if(action.reset !== undefined){
        state = {
          current_input : "",
          pieces : "",
          origin : "",
          dest : "",
          gross_weight : "",
          chargeable_weight : "",
          volume : "",
          issue_carrier : "",
          is_invalid_carrier : false,
          price : "",
          flight_date : "",
          routing_carrier : "",
          margin : null,
          agent : "",
          agent_code : "",
          validity_date :"",
          from_date : "",
          to_date : "",
          to_date_1 : "",
          weight_mismatch : "",
          volume_mismatch : "", 
          pieces_mismatch : "", 
          stackable_mismatch : "",
          stackable_fill : "",
          stackable_fill_invalid : [],
          available_origin: "",
          buy_min : "", 
          sell_min : "", 
          min_chargeable_weight : "", 
          sell_prices : null,
          carriers_idxs : [],
          flight_date_idxs : [],
          flight_number_idxs : [],
          flight_eta_idxs : [],
          flight_etd_idxs : [],
          board_points : [],
          off_points : []
        }
      }
      
      if(action.errors !== undefined){
        state = action.errors
      }
      
      if(action.current_input !== undefined)
        state.current_input = action.current_input;

      if(action.sell_prices !== undefined)
        state.sell_prices = action.sell_prices;

      if(action.min_chargeable_weight !== undefined)
        state.min_chargeable_weight = action.min_chargeable_weight;
      
      if(action.sell_min !== undefined)
        state.sell_min = action.sell_min;

      if(action.buy_min !== undefined)
        state.buy_min = action.buy_min;

      if(action.pieces !== undefined)
        state.pieces = action.pieces;
      
      if(action.origin !== undefined)
        state.origin = action.origin;
      
      if(action.dest !== undefined)
        state.dest = action.dest;
      
      if(action.gross_weight !== undefined)
        state.gross_weight = action.gross_weight;
      
      if(action.chargeable_weight !== undefined)
        state.chargeable_weight = action.chargeable_weight;      
      
      if(action.volume !== undefined)
        state.volume = action.volume;      
      
      if(action.price !== undefined)
        state.price = action.price;
      
      if(action.issue_carrier !== undefined)
        state.issue_carrier = action.issue_carrier;    
                 
      if(action.is_invalid_carrier !== undefined)
        state.is_invalid_carrier = action.is_invalid_carrier;    
       
      if(action.flight_date !== undefined)
        state.flight_date = action.flight_date; 

      if(action.routing_carrier !== undefined)
        state.routing_carrier = action.routing_carrier;     
      
      if(action.margin !== undefined)
        state.margin = action.margin;   
      
      if(action.agent !== undefined)
        state.agent = action.agent; 

      if(action.agent_code !== undefined)
        state.agent_code = action.agent_code; 
            
      if(action.validity_date !== undefined)
        state.validity_date = action.validity_date;
      
      if(action.from_date !== undefined)
        state.from_date = action.from_date;

      if(action.to_date !== undefined)
        state.to_date = action.to_date;

      if(action.to_date_1 !== undefined)
        state.to_date_1 = action.to_date_1;


      if(action.weight_mismatch !== undefined)
        state.weight_mismatch = action.weight_mismatch;

      if(action.volume_mismatch !== undefined)
        state.volume_mismatch = action.volume_mismatch;

      if(action.pieces_mismatch !== undefined)
        state.pieces_mismatch = action.pieces_mismatch;

      if(action.stackable_mismatch !== undefined)
        state.stackable_mismatch = action.stackable_mismatch;

      if(action.stackable_fill !== undefined)
        state.stackable_fill = action.stackable_fill;

      if(action.stackable_fill_invalid !== undefined)
        state.stackable_fill_invalid = action.stackable_fill_invalid;

      if(action.available_origin !== undefined)
        state.available_origin = action.available_origin;

      if(action.flight_date_idxs !== undefined){
        state.flight_date_idxs = action.flight_date_idxs
      }

      if(action.carriers_idxs !== undefined){
        state.carriers_idxs = action.carriers_idxs
      }

      if(action.flight_number_idxs !== undefined)
        state.flight_number_idxs = action.flight_number_idxs

      if(action.flight_eta_idxs !== undefined)
        state.flight_eta_idxs = action.flight_eta_idxs

      if(action.flight_etd_idxs !== undefined)
        state.flight_etd_idxs = action.flight_etd_idxs

      if(action.board_points !== undefined){
        state.board_points = action.board_points
      }

      if(action.off_points !== undefined){
        state.off_points = action.off_points
      }

      return state
    },

    reducer_margin : function(state = margin_state, action){
      
      if(action.all !== undefined && action.all !== null){
        
        if(!action.all.margin_per_kg)
          action.all.margin_per_kg = margin_init
        
        if(!action.all.margin_in_total)
          action.all.margin_in_total = margin_init

        state = action.all

      }

      if(action.reset !== undefined){
        state = {
          force : false,
          updateMargin : false,
          buy_rate_type : null,
          sell_rate_type : null,
          sell_total : 0,
          buy_total : 0,
          create_buy : false,
          buy_all_in : "",
          sell_all_in : "",
          buy_carrier : "",
          buy_price_class : "",
          sell_carrier : "",
          buy_currency : "",
          deal_code : null,
          buy_min : 0,
          sell_min : 0,
          commission_type : "",
          commission : null,
          fuel_type : null, 
          fuel_currency : null, 
          fuel_code : null,
          fuel_min : 0,
          fuel_max : 0,
          security_type : null, 
          security_currency : null,
          security_code : null, 
          security_min : 0,
          security_max : 0,
          per_kg : true,
          margin_in_total : {
          
            sell_rate : 0,
            sell_rate_all_in : 0,
            sell_net_net_rate : 0,
            sell_all_in_rate : 0,
            sell_min : 0,
            truck_sell : 0,
            handling_sell : 0,
            loc_addon_sell : 0,
            truck_sell_edit : 0,
            handling_sell_edit : 0,
            loc_addon_sell_edit : 0,
          
            buy_rate : 0,
            buy_rate_all_in : 0,
            buy_min : 0,
            buy_net_net_rate : 0,
            buy_all_in_rate : 0,
            all_in_buy_edit : 0,
            all_in_buy_edit2 : 0,
            net_buy_edit : 0,
            net_buy_edit2 : 0,
            truck_buy : 0,
            handling_buy : 0,
            loc_addon_buy : 0, 
            truck_buy_edit : 0,
            handling_buy_edit : 0, 
          
            truck_markup : 0,
            handling_markup : 0,
            loc_addon_markup : 0,
          
            fuel_rate : 0,
            security_rate : 0,
            commission_value : 0,
            margin: 0,
            charges : 0, 
          },
          margin_per_kg : {
          
            sell_rate : 0,
            sell_rate_all_in : 0,
            sell_net_net_rate : 0,
            sell_all_in_rate : 0,
            sell_min : 0,
            truck_sell : 0,
            handling_sell : 0,
            loc_addon_sell : 0,
            truck_sell_edit : 0,
            handling_sell_edit : 0,
            loc_addon_sell_edit : 0,
          
            buy_rate : 0,
            buy_rate_all_in : 0,
            buy_min : 0,
            buy_net_net_rate : 0,
            buy_all_in_rate : 0,
            all_in_buy_edit : 0,
            all_in_buy_edit2 : 0,
            net_buy_edit : 0,
            net_buy_edit2 : 0,
            truck_buy : 0,
            handling_buy : 0,
            loc_addon_buy : 0, 
            truck_buy_edit : 0,
            handling_buy_edit : 0, 
          
            truck_markup : 0,
            handling_markup : 0,
            loc_addon_markup : 0,
          
            fuel_rate : 0,
            security_rate : 0,
            commission_value : 0,
            margin: 0,
            charges : 0, 
          }
        }
        
        return state
        
      } 
       
          
      
      if(action.marginPerkg || action.marginInTotal){

        console.log("#freePerKg || TOTAl")
        
        let keys = Object.keys(action)
        
        if(action.marginPerkg){
          
          if(!state.margin_per_kg) state.margin_per_kg = margin_init

          keys.forEach((key)=>{

            if(key === 'all_in_buy_edit' || key === 'all_in_buy_edit2' 
            || key === 'net_buy_edit2' || key === 'net_buy_edit'
            || key === 'truck_sell_edit' || 'handling_sell_edit' || 'loc_addon_sell_edit'
            || key === 'truck_buy_edit' || 'handling_buy_edit' || 'loc_addon_buy_edit'  ){

              state.margin_per_kg[key] = typeof(action[key]) === "string" ?  
              Math.round(action[key].replace(",",".")*1000)/1000.00 : action[key]
              state.margin_per_kg[key] = isNaN(parseFloat(state.margin_per_kg[key])) || state.margin_per_kg[key] === 0 ? 0  : state.margin_per_kg[key] 
            }else{
              state.margin_per_kg[key] = action[key] && typeof(action[key]) === "string" ?  
              Math.round(action[key].replace(",",".")*100)/100.00 : action[key]
              state.margin_per_kg[key] = isNaN(parseFloat(state.margin_per_kg[key])) || state.margin_per_kg[key] === 0 ? action[key]  : state.margin_per_kg[key] 
            }  
      

          })

          
        }
        if(action.marginInTotal){

          if(!state.margin_in_total) state.margin_in_total = margin_init

          keys.forEach((key)=>{
            if(key === 'all_in_buy_edit' || key === 'all_in_buy_edit2' 
            || key === 'net_buy_edit2' || key === 'net_buy_edit'
            || key === 'truck_sell_edit' || 'handling_sell_edit' || 'loc_addon_sell_edit'
            || key === 'truck_buy_edit' || 'handling_buy_edit' || 'loc_addon_buy_edit'  ){
              state.margin_in_total[key] = action[key] && typeof(action[key]) === "string" ?  
              Math.round(action[key].replace(",",".")*1000)/1000.00 : action[key]
              state.margin_in_total[key] = isNaN(parseFloat(state.margin_in_total[key])) || state.margin_in_total[key] === 0 ? 0  : state.margin_in_total[key] 
            }else{
              state.margin_in_total[key] = action[key] && typeof(action[key]) === "string" ?  
              Math.round(action[key].replace(",",".")*100)/100.00 : action[key]
              state.margin_in_total[key] = isNaN(parseFloat(state.margin_in_total[key])) || state.margin_in_total[key] === 0 ? action[key]  : state.margin_in_total[key] 
            }          
          })
        
        }

      }


      if(!action.marginPerkg && !action.marginInTotal){
        let keys = Object.keys(action)

        keys.forEach((key)=>{
            if(["reset", "all", "margin_per_kg", "margin_in_total"].indexOf(key)>0  ) return
            if(key === 'all_in_buy_edit' || key === 'all_in_buy_edit2' || key === 'net_buy_edit2' || key === 'net_buy_edit'){
              state[key] = action[key] && typeof(action[key]) === "string" ?  
              Math.round(action[key].replace(",",".")*1000)/1000.00 : action[key]
              state[key] = isNaN(parseFloat(state[key])) || state[key] === 0 ? 0  : state[key] 
            }else{
              state[key] = action[key] && typeof(action[key]) === "string" ?  
              Math.round(action[key].replace(",",".")*100)/100.00 : action[key]
              state[key] = isNaN(parseFloat(state[key])) || state[key] === 0 ? action[key]  : state[key] 
            }          
        })
  
     }

      if(action.updateMargin !== undefined)
        state.updateMargin = action.updateMargin
  

      if(action.per_kg !== undefined)
        state.per_kg = action.per_kg

      if(action.force !== undefined)
        state.force = action.force
 
      return state;
      
    },

    reducer_pricing : function(state ={
      force : 0,
      price : "",
      price_total : "",
      target_price : "",
      target_currency : "EUR",
      target_per_kg : true,
      carrier : "",
      carrier_name : "",
      carrier_prefix : "",
      from_date : Util.getCurrentDate(),
      to_date : Util.getCurrentDate(14),
      min_amount : 0,
      min_chargeable_weight : 0,
      currency : "EUR",
      all_in : false,
      price_template : 'ADHOC',
      per_kg : true,
      all_in_rate_oc_list:[],
      def_oc_list:[],
      ocdc : [],
      uld_price : [],
      uld_price_change : 0,
      ocdc_auto_calculate : false,
      display_ocdc_click : false,
      display_ocdc_total : false,
    }, action) {

    if(action.reset !== undefined){
      return {
        force :0,
        price : "",
        price_total : "",
        target_price : "",
        target_currency : "EUR",
        target_per_kg : true,
        carrier : "",
        carrier_name : "",
        carrier_prefix : "",
        from_date : Util.getCurrentDate(),
        to_date : Util.getCurrentDate(14),
        min_amount : 0,
        min_chargeable_weight : 0,
        currency : "EUR",
        all_in : false,
        price_template : 'ADHOC',
        per_kg : true,
        all_in_rate_oc_list:[],
        def_oc_list:[],
        ocdc : [],
        uld_price : [],
        uld_price_change : 0,
        ocdc_auto_calculate : false,
        display_ocdc_click : false,
        display_ocdc_total : false,
      }
     
    }
    
    if(action.ocdc && action.index === undefined){
        state.ocdc = action.ocdc
    }
    //to update
    if(action.ocdc && action.index !== undefined){
      let i = action.index;
      if(action.name !== undefined)
        state.ocdc[i].name = action.name 

      if(action.code !== undefined)
        state.ocdc[i].code = action.code 

      if(action.based_on !== undefined)
        state.ocdc[i].based_on = action.based_on 

      if(action.oc_currency !== undefined)
        state.ocdc[i].currency = action.oc_currency 
      
      if(action.value !== undefined)
        state.ocdc[i].value = action.value 
      
      if(action.total_charges !== undefined)
        state.ocdc[i].total_charges = action.total_charges 

      if(action.ocdc_removeLine)
        state.ocdc.splice(action.index, 1)

    }

    if(action.uld_price){
      state.uld_price = action.uld_price
    }

    if(action.uld_price_change){
      state.uld_price_change = state.uld_price_change + 1
    }

    if(action.update_uld_price !== undefined && action.index !== undefined){
      let i = action.index;
    
      if(action.position !== undefined)
        state.uld_price[i].position = action.position 
      
      if(action.pivot_weight !== undefined){
        state.uld_price[i].pivot_weight = typeof(action.pivot_weight) === "string" ?  
        Math.round(action.pivot_weight.replace(",",".")*100)/100.00 : action.pivot_weight
        state.uld_price[i].pivot_weight = isNaN(parseFloat(state.uld_price[i].pivot_weight)) || state.uld_price[i].pivot_weight === 0 ? ""  :  state.uld_price[i].pivot_weight.toFixed(2)
      }

      if(action.uld_rate !== undefined){
        state.uld_price[i].uld_rate = typeof(action.uld_rate) === "string" ?  
        Math.round(action.uld_rate.replace(",",".")*100)/100.00 : action.uld_rate
        state.uld_price[i].uld_rate = isNaN(parseFloat(state.uld_price[i].uld_rate)) || state.uld_price[i].uld_rate === 0 ? ""  :  state.uld_price[i].uld_rate.toFixed(2)
      }
      
      if(action.selling_rate !== undefined){
        let selling_rate = typeof(action.selling_rate) === "string" ?  
        Math.round(action.selling_rate.replace(",",".")*100)/100.00 : action.selling_rate
        selling_rate = isNaN(parseFloat(selling_rate)) || selling_rate === 0 ? ""  :  selling_rate.toFixed(2)
        state.uld_price[i].selling_rate = selling_rate        
      }

      if(action.per_kg !== undefined){
        state.uld_price[i].per_kg = action.per_kg
        // for(let j = 0; j < state.uld_price.length; j++){
        //   state.uld_price[j].per_kg = action.per_kg
        // }
      }
    
      if(action.over_pivot_rate !== undefined){
        let over_pivot_rate = typeof(action.over_pivot_rate) === "string" ?  
        Math.round(action.over_pivot_rate.replace(",",".")*100)/100.00 : action.over_pivot_rate
        over_pivot_rate = isNaN(parseFloat(over_pivot_rate)) || over_pivot_rate === 0 ? ""  : over_pivot_rate.toFixed(2)
        state.uld_price[i].over_pivot_rate = over_pivot_rate
        // for(let j = 0; j < state.uld_price.length; j++){
        //   state.uld_price[j].over_pivot_rate = over_pivot_rate
        // }
      }
    
      
      if(action.currency !== undefined){
        state.uld_price[i].currency = action.currency 
        for(let j = 0; j < state.uld_price.length; j++){
          state.uld_price[j].currency = action.currency 
        }
      }
   
    }

    if(action.get_ocdc){
      state.ocdc = action.get_ocdc  
    }

    if(action.display_ocdc_total !== undefined){
      state.display_ocdc_total = action.display_ocdc_total
    }

    if(action.display_ocdc_click !== undefined){
      state.display_ocdc_click = action.display_ocdc_click
    }

    if(action.ocdc_auto_calculate !== undefined){
      state.ocdc_auto_calculate = action.ocdc_auto_calculate
    }

    if(action.target_currency !== undefined)
      state.target_currency = action.target_currency

    if(action.target_per_kg !== undefined)
      state.target_per_kg = action.target_per_kg
      
    if(action.ocdc_add){

      let length = state.ocdc.length ? state.ocdc.length : 0

      if(length < 10)
      
        state.ocdc.push({ 
          name : "", 
          code : "", 
          based_on : "", 
          currency : state.currency, 
          value : "", 
          total_charges : ""
        })
    }

    if(action.ocdc_remove && state.ocdc.length > 0)
      state.ocdc = []
    
    if(action.price !== undefined){
      state.price = typeof(action.price) === "string" ?  
      Math.round(action.price.replace(",",".")*100)/100.00 : action.price
      state.price = isNaN(parseFloat(state.price)) || state.price ===0 ? ""  :  parseFloat(state.price).toFixed(2)
    }

    if(action.price_total !== undefined){
      state.price_total = typeof(action.price_total) === "string" ?
      Math.round(action.price_total.replace(",",".")*100)/100.0 : action.price_total
      state.price_total = isNaN(parseFloat(state.price_total)) || state.price_total ===0 ? ""  :  parseFloat(state.price_total).toFixed(2)
      state.min_amount = state.price_total
    }

    if(action.target_price !== undefined){
      state.target_price = typeof(action.target_price) === "string" ?
      Math.round(action.target_price.replace(",",".")*100)/100 : action.target_price
      state.target_price = !state.target_price || isNaN(parseFloat(state.target_price)) ? 
      ""  : parseFloat(state.target_price).toFixed(2)
    }

    if(action.carrier !== undefined ){
      state.carrier = action.carrier ? action.carrier.toUpperCase() : ""
      if(Util.CARRIER_TO_ISSUE_CARRIER[state.carrier]!== undefined)
        state.carrier = Util.CARRIER_TO_ISSUE_CARRIER[state.carrier]
    }

    if(action.carrier_name !== undefined)
      state.carrier_name = action.carrier_name 

    if(action.carrier_prefix !== undefined)
      state.carrier_prefix = action.carrier_prefix 

    if(action.all_in_rate_oc_list !== undefined)
      state.all_in_rate_oc_list = action.all_in_rate_oc_list ?  action.all_in_rate_oc_list.toUpperCase() : ""

    if(action.def_oc_list !== undefined)
      state.def_oc_list = action.def_oc_list ?  action.def_oc_list.toUpperCase() : ""

    if(action.pricing !== undefined)
      state = action.pricing

    if(action.from_date !== undefined){
      state.from_date = action.from_date
    }

    if(action.to_date !== undefined)
      state.to_date = action.to_date

    if(action.min_amount !== undefined){
      state.min_amount = typeof(action.min_amount) === "string" ? parseFloat(action.min_amount) : action.min_amount
      state.min_amount = isNaN(state.min_amount)  ? 0 : state.min_amount
    }
    
    if(action.min_chargeable_weight !== undefined)
      state.min_chargeable_weight = isNaN(parseFloat(action.min_chargeable_weight)) ? 0 : parseFloat(action.min_chargeable_weight)

    if(action.currency !== undefined)
      state.currency = action.currency

    if(action.all_in !== undefined)
      state.all_in = action.all_in

    if(action.price_template !== undefined)
      state.price_template = action.price_template

    if(action.per_kg !== undefined){
      state.per_kg = action.per_kg
      if(state.per_kg === false && !action.set) {
        state.min_chargeable_weight = 0
        state.min_amount = state.price_total
      }
    }

    if(action.force !== undefined){
      state.force = parseInt(state.force) || state.force === 0? state.force+1 : 0
    }
    
    return state
    },

    reducer_similar : function(state ={ 
      prices : [],
      similarQuotes : [],
      similar : false,
      exact : false,
      autoPopup : true
    }, action) {

      if(action.reset !== undefined){
        return{
          prices : [],
          similarQuotes : [],
          similar : false,
          exact : false,
          autoPopup : true
        }
      }
    
      if(action.prices !== undefined)
        state.prices = action.prices

      if(action.similarQuotes !== undefined)
        state.similarQuotes = action.similarQuotes

      if(action.similar !== undefined)
        state.similar = action.similar

      if(action.exact !== undefined)
        state.exact = action.exact

      if(action.autoPopup !== undefined)
        state.autoPopup = action.autoPopup

      if(action.reset !== undefined){
        return  []
      }
    
      return state
    },

    reducer_remarks : function(state ={
      int_remarks : "",
      ext_remarks : "",
      id : 0,
      attachments_copy : [],
      error : '',
      updateAttachment : false,
      attachment_id : 0,
      attachments : [
        {file_name : '', file : ''}, 
        {file_name : '', file : ''}, 
        {file_name : '', file : ''}
      ],
      force : 0,
      chat_messages:[], 
      chat_links : null   
    }, action) {
        
      if(action.reset !== undefined){
        return  {
          int_remarks : "",
          ext_remarks : "",
          id : 0,
          attachments_copy :[],
          error : '',
          updateAttachment : false,
          attachment_id : 0,
          attachments : [
            {file_name : '', file : ''}, 
            {file_name : '', file : ''}, 
            {file_name : '', file : ''}
          ],
          force : 1,
          chat_messages:[],
          chat_links : null,
        }
      }
        
      if(action.chat_links)
        state.chat_links = action.chat_links

      if(action.chat_messages)
        state.chat_messages = action.chat_messages ? action.chat_messages : []

      if(action.add_message){
        state.chat_messages.push({
          message : action.message, 
          user : action.user, 
          timestamp : action.timestamp ? action.timestamp : (Date.now()/1000), 
          new : true, 
          file : action.file ? action.file : null, 
          file_name: action.file_name ? action.file_name : null
        })
      }

      if(action.updateAttachment !== undefined){
        state.updateAttachment = action.updateAttachment
      }

      if(action.remove_message){
        let messageToRemove = state.chat_messages.filter((m)=> m.user === action.user && m.timestamp === action.timestamp )
        state.chat_messages.splice(state.chat_messages.indexOf(messageToRemove), 1)    
      }
        
      if(action.ext_remarks !== undefined){
        let ext_remarks = action.ext_remarks
        state.ext_remarks = ext_remarks    
      } 
        
      if(action.int_remarks !== undefined){
        let int_remarks = action.int_remarks
        state.int_remarks = int_remarks   
      }

      if(action.id !== undefined){
        state.id = action.id
      }

      if(action.attachment_id !== undefined){
        state.attachment_id = action.attachment_id  
      }

      if(action.attachments !== undefined){
        state.attachments = action.attachments
      }

      if(action.attachments_copy !== undefined){
        state.attachments_copy = action.attachments_copy
      }

      if(action.error !== undefined){    
        state.error = action.error
      }

      if(action.addAttachment !== undefined && action.index !== undefined){    
        if(action.file_name !== undefined && action.file !== undefined){
          let file_name = action.file_name
          let file = action.file
          state.attachments[action.index] = {file_name : file_name, file : file} 
        }    
      }
         
      if(action.force !== undefined)
        state.force = state.force + 1

      return state
        
    },
   
    reducer_header : function(state ={
      hq : cookies.default_hq === "true" ? true : false,
      status : "DRAFT",
      station : cookies.station,
      sales_region : cookies.sales_region,
      id : "",
      external_id : null,
      adhoc_ref : "",
      adhoc_ref_buy : null,
      send_adhoc_disable : false,
      user : cookies.account ,
      creation_date : new Date(),
      last_edit_date : undefined,
      last_update : new Date(),
      last_updated_by : cookies.account,
      update_history: "",
      last_follow_up : new Date(),
      remarks : "",
      int_remarks : "",
      failure_reason : "",
      failure_reason_exp : "",
      saveProgressBar : false,
      awb_number : "",
      prefix :"",
      serial : "", 
      follow_up:false, 
      follow_up_id:null
    }, action) {
    
    if(action.reset !== undefined){
      state =  {
        hq : cookies.default_hq === "true" ? true : false,
        status : "DRAFT",
        station : state.station,
        sales_region : state.sales_region,
        id : "",
        external_id : null,
        adhoc_ref :"",
        adhoc_ref_buy : null,
        send_adhoc_disable : false,
        user :  cookies.account,
        creation_date : new Date(),
        last_edit_date : undefined,
        last_update : new Date(),
        last_updated_by : cookies.account,
        update_history: "",
        last_follow_up : new Date(),
        remarks : "",
        int_remarks : "",
        failure_reason : "",
        failure_reason_exp : "",
        saveProgressBar : false,
        awb_number : "",
        prefix :"",
        serial : "",
        follow_up:false,
        follow_up_id:null
      }
  
      return state
    }
    
    if(action.header !==undefined)
      state.header = action.header

    if(action.hq !==undefined){
      state.hq = action.hq
    }
    
    if(action.station !== undefined)
      state.station = action.station

    if(action.sales_region !== undefined)
      state.sales_region = action.sales_region

    if(action.status !== undefined){
      state.status = action.status
    
    if(action.status !== "REJECTED" && action.status !== "CANCELLED")
      state.failure_reason = ""
    }
    
    if(action.failure_reason !== undefined)
      state.failure_reason = action.failure_reason.replace("&", "and")

    if(action.failure_reason_exp !== undefined)
      state.failure_reason_exp = action.failure_reason_exp ? action.failure_reason_exp.replace("&", "and") : ""
    
    if(action.id !== undefined)
      state.id = action.id

    if(action.external_id !== undefined)
      state.external_id = action.external_id
    
    if(action.adhoc_ref !== undefined)
      state.adhoc_ref = action.adhoc_ref
    
    if(action.adhoc_ref_buy !== undefined)
      state.adhoc_ref_buy = action.adhoc_ref_buy

    if(action.send_adhoc_disable !== undefined)
      state.send_adhoc_disable = action.send_adhoc_disable
    
    if(action.prefix !== undefined){
      state.prefix = action.prefix
      state.awb_number = state.prefix + "-" + state.serial
    }
    
    if(action.serial !== undefined){
      state.serial = action.serial
      state.awb_number = state.prefix + "-" + state.serial
    }

    if(action.awb_number !== undefined)
      state.awb_number = action.awb_number
    
    if(action.user !== undefined)
      state.user = action.user
    
    if(action.creation_date !== undefined)
      state.creation_date = action.creation_date

    if(action.last_updated_by !== undefined)
      state.last_updated_by = action.last_updated_by

    if(action.update_history !== undefined)
      state.update_history = action.update_history

    if(action.last_update !== undefined)
      state.last_update = action.last_update 
    
    if(action.last_follow_up !== undefined)
      state.last_follow_up = action.last_follow_up

    if(action.follow_up !== undefined)
      state.follow_up = action.follow_up

    if(action.follow_up_id !== undefined)
      state.follow_up_id = parseInt(action.follow_up_id) ? parseInt(action.follow_up_id) : null
    
    if(action.last_edit_date !== undefined)
      state.last_edit_date = action.last_edit_date
    
    if(action.saveProgressBar !== undefined)
      state.saveProgressBar = action.saveProgressBar
    
    return state
    }
}
    

    

 