import cookies from "../Cookies"

export default   {

  reducer_quotes : function(state={
    quotes: [], 
    quotes_csv : "a",
    quotes_filtered : [],
    export : false,
    countQuotes : 0,
    page_loading_spinner : false,
    force : 0,
    modalUpdateState : false,
    quote_toUpdate : null,
    current_input : null,
    quote_history : null
  }, action){

    if(action.force !== undefined)
      state.force ++
      
    if(action.modalUpdateState !== undefined)
      state.modalUpdateState = action.modalUpdateState

    if(action.quote_history !== undefined)
      state.quote_history = action.quote_history

    if(action.quotes !== undefined)
      state.quotes = action.quotes
      
    if(action.quotes_filtered !== undefined)
      state.quotes_filtered = action.quotes_filtered
      
    if(action.quotes_csv !== undefined)
      state.quotes_csv = action.quotes_csv
      
    if(action.export !== undefined)
      state.export = !state.export

    if(action.countQuotes !== undefined)
      state.countQuotes = action.countQuotes

    if(action.page_loading_spinner !== undefined)
      state.page_loading_spinner = action.page_loading_spinner

    if(action.status && action.id !== undefined){
      try{
            let quote = state.quotes.filter((q)=>q.id === action.id)[0]
            if(!quote)return
             quote.status = action.status
             state.quote_toUpdate = action.id

      }catch(e){

      }
          
    }

    if(action.current_input !== undefined)
        state.current_input = action.current_input
        return state

  },
      
  reducer_filters : function (state={
    id : "",
    external_id : null,
    hq : null,
    can_refresh : false,
    station : "All",
    adhoc_ref : "",
    user : "", 
    status : "All", 
    agent : "", 
    origin : "", 
    dest : "", 
    carrier : "", 
    weight : 0, 
    from_date : "", 
    to_date : "", 
    first_flight_fromDate : "",
    first_flight_toDate : "",
    reset : true,
    skip : 0 ,
    awb_serial : "",
    awb_prefix : "",
    max_weight : "",
    min_weight : "",
    commodity_code : 'All',
    dimension_type : 'All',
    deck : 'All',
    currency_conversion : "No Conversion", // LOCAL, EUR, USD,
    user_follow_up : "",
    follow_up : null,
    user_carriers : cookies.user_carriers
  }, action){
        
    if(action.reset)
      state = { 
        reset : !state.reset, 
        id : null,
        external_id:null,
        hq : null,
        can_refresh : false,
        station :"All",
        adhoc_ref : "",
        user : "", 
        state : "All", 
        status : "", 
        origin : "", 
        dest : "", 
        carrier : "", 
        weight : 0, 
        from_date : "", 
        to_date : "",
        first_flight_fromDate : "",
        first_flight_toDate : "",
        skip : 0,
        awb_serial : "",
        awb_prefix : "",
        max_weight : "",
        min_weight : "",
        commodity_code : 'All',
        dimension_type : 'All',
        deck : 'All',
        currency_conversion : "No Conversion", // LOCAL, EUR, USD
        user_follow_up: cookies.account,
        follow_up : null,
        user_carriers : cookies.user_carriers
      };
         
      if(action.user_follow_up)
        state.user_follow_up = action.user_follow_up

      if(action.user_carriers !== undefined)
        state.user_carriers = action.user_carriers

      if(action.station !== undefined)
        state.station = action.station

      if(action.hq !== undefined){
        state.hq = action.hq === "All" ? null : state.hq
        state.hq = action.hq === "true" ? true : state.hq
        state.hq = action.hq === "false" ? false : state.hq
      }


      if(action.follow_up !== undefined){
        state.follow_up = action.follow_up === "All" ? null : state.follow_up
        state.follow_up = action.follow_up === "true" ? true : state.follow_up
        state.follow_up = action.follow_up === "false" ? false : state.follow_up
      }
        
        
      if(action.id !== undefined){
        state.id = action.id
        state.external_id = action.id
      } 

      if(action.adhoc_ref !== undefined)
        state.adhoc_ref = action.adhoc_ref

      if(action.awb_serial !== undefined)
        state.awb_serial = action.awb_serial

      if(action.awb_prefix !== undefined)
        state.awb_prefix = action.awb_prefix
      
      if(action.user !== undefined)
          state.user = action.user
      
      if(action.status !== undefined)
          state.status = action.status
      
      if(action.agent !== undefined){
          let state_ = {...state}
          state_.agent = action.agent  
          return state_
      }
          
      if(action.origin !== undefined)
        state.origin = action.origin
      
      if(action.dest !== undefined)
        state.dest = action.dest
      
      if(action.carrier !== undefined)
        state.carrier = action.carrier
      
      if(action.weight !== undefined)
        state.weight = action.weight 

      if(action.min_weight !== undefined)
        state.min_weight = action.min_weight 

      if(action.max_weight !== undefined)
        state.max_weight = action.max_weight 

      if(action.commodity_code !== undefined)
        state.commodity_code = action.commodity_code 

      if(action.dimension_type !== undefined)
        state.dimension_type = action.dimension_type 

      if(action.deck !== undefined)
        state.deck = action.deck 

      if(action.currency_conversion !== undefined)
        state.currency_conversion = action.currency_conversion 
        
      if(action.from_date !== undefined)
        state.from_date = new Date(action.from_date)
        
      if(action.to_date !== undefined)
        state.to_date = new Date(action.to_date) 

        if(action.first_flight_fromDate !== undefined)
        state.first_flight_fromDate = new Date(action.first_flight_fromDate)
        
      if(action.first_flight_toDate !== undefined)
        state.first_flight_toDate = new Date(action.first_flight_toDate)
      
      if(action.skip_plus !== undefined)
        state.skip +=100
        
      if(action.skip_minus !== undefined){
        state.skip = state.skip-100 < 0 ? 0 : state.skip-100
      }

      if(action.skip !== undefined){
          state.skip = action.skip
      }
 
      return state
      
  }

}