import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";

var numeral = require('numeral');

Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

export default   {

  COLOR_PALETTE : {ECS_MAIN : "#003457"},

  CARRIER_TO_ISSUE_CARRIER : {
    "AV" : "QT",
    "MU" : "CK",
    "OR" : "TB",
    "BY" : "TB",
    "X3" : "TB",
    "6B" : "TB",
    "NAB" : "EA"
  },

  STREET_CARRIERS : ["DE", "TB", "2C"],

  SURCHAGE_CODES : ["MYC","FSC"],

  SECURITY_CODES : ["IRC", "SCC", "XDC"],

  CONST : {
    PER_KG : "/kg",
    PER_KG1 : "Per/kg",
    PER_KG2 : "Per kg",
    PER_ULD : "/ULD",
    TOTAL : "Total", 
    ALL_IN : "All-In", 
    NET_NET : "Net-Net", 
    KG : "kg", 
    CM : 'cm', 
    INCH : "inch",
    LBS : "lbs",
    ULD : "uld",
    LOOSE : "loose",
    IMPERIAL : "imperial",
    MAIN  : "main",
    MAIN_DECK : "Main Deck",
    LOWER_DECK : "Lower DECK",
    LOWER : "lower",
    BOOKED : "BOOKED",
    DRAFT : "DRAFT",
    QUOTED : "QUOTED",
    QUEUED : "QUEUED",
    CANCELLED : "CANCELLED",
    REJECTED : "REJECTED",
    EXPIRED : "EXPIRED",
    PARTIALLY : "Partially",
    YES : "Yes",
    NO : "No",
    PER_CM : "/CMT",
    PER_INH : "/INH",
    METRIC : "metric",
    ADHOC : "ADHOC",
    DEFAULT : "default",
    FLIGHT : "flight",
    CARGOAI : "cargoai",
    SKYPALLET : "skypallet"
  },

  COLORS : {
    BLACK : "black",
    WHITE : "white",
    RED : "red",
    RED_TOOLTIP : "#d53369"

  },

  TEXTS : {
    SECURITY_FUEL_NOT_INCLUDES : "Security and Fuel surcharges not included.",
    ADJUST_WEIGHT : "Adjusted Chargeable Weight.",
    CHARGEABLE_WEIGHT : "Chargeable Weight.",
    DRAFT_TEXT : "This document is a draft and does not engage in any agreement.",
    CHARGES_TEXT  : "Charges are based on information and items known at the time of quote. Final total charges may vary.",
    REFER_TEXT : "Please refer to the general terms and local conditions.",
    NO_PACKING_TEXT : "No packing list attached to this quote.",
    NO_ULD_TEXT : "No ULD list attached to this quote.",
    SEE_PACKING_BELOW : "See Packing List below.",
    SEE_ULD_BELOW : "See ULD List below.",
    MANDATORY_FIELDS_TEXT : "Please fill in mandatory fields",
    STACKABLES_FILL_TEXT : "Some pieces are non-stackable. Please fill in the missing fields.",
    ORIGIN_DEST_FILL_TEXT : "Please fill in shipment origin & destination.",
    PACKING_MANDATORY_FILL_TEXT : "Please fill in packing list mandatory fields to call Skypallet.",
    SEND_TO_SKYPALLET_TEXT : "Send to Skypallet",

   },

  USER_ROLES : {
    ADMIN : "admin",
    STATION_ADMIN : "station_admin",
    SALES_STD : "sales_std",
    SALES_LITE : "sales_lite",
    OPS_STD : "ops_std",
    OPS_LITE : "ops_lite"
  },

  USER_ROLES_ARRAY : ["admin", "station_admin", "sales_std", "sales_lite", "ops_lite", "ops_std"],

  STATUS : [     
    { name :"DRAFT", color : "#4f565b"}, 
    { name :"QUOTED", color : "#ffc107"}, 
    { name :"BOOKED", color : "#1a91c4"}, 
    { name :"REJECTED", color : "#dc3545"}, 
    { name :"EXPIRED", color : "#8a483f"}, 
    { name :"CANCELLED", color : "#dc3545"}, 
    { name :"QUEUED", color : "#a83283"},
    { name :"DELETED", color : "#dc3545"}, 

  ],

  DENY_ADHOC_BUY : [     
    { station :"FRA", carrier : "JU"}
  ],
  
  REJECTED_REASONS : [
    "Pricing / Rate too high",
    "Past Performance",
    "Preferred Direct Service",
    "Cargo secured by another agent",
    "Late Communication / Confirmation",
    "Schedule / transit time",
    "Sea / Land Mode",
    "No available (origin) capacity",
    "No available transit capacity",
    "No feedback from Customer or Client",
    "Cargo Not Loadable", 
    "Part Uplift",
    "GHA/Warehouse in Origin / Destination",
    "Unknown reason",
    "Other"
  ],

  OPERATION_TYPES : ["Narrow Body (bulk)", "Narrow Body (palletized)", "Wide Body", "Freighter", "Truck", "Flight/Truck"],

  MONTHS : ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],

  CURRENCIES : ["AUD", "BRL", "EUR", "GBP", "USD", "NOK", "SEK", "DKK","CHF","SGD", "CZK", "HKD", "CNY", "RUB","MYR","NZD", "CAD"].sort(),

  LETTERS : ["A", "B", "C", "D", "E", "F", "G", "H","I","J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],

  DECKS : ["main", "lower"],

  DIMENSION_TYPES : ["loose", "uld"],

  STACKABLES : ["Yes", "No", "Partially"],

  parseUrl(url_params){
      try{
 
        url_params = url_params.split("?")[1]
        let json = {}
        
        if(url_params){
          url_params = url_params.split("&")
          for(let i=0; i<url_params.length;i++){
            json[url_params[i].split("=")[0]] = url_params[i].split("=")[1]
          }
        }
        return json
    }catch(e){
      return {}
    }
  },

  deduplicate(array){
   //deduplicate array. Ex : [2,1,2] => [2,1]
   if(!array) return null
    let array_ = []
    for(let i=0; i<array.length; i++){
      if(array_.indexOf(array[i]) < 0) array_.push(array[i])
    }
    return array_
 },

  convert_currency(amount,from_currency,to_currency,exch_rate){

    let amount_converted = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount)

    if(from_currency === to_currency) 
      return amount_converted
      
    if(isNaN(amount_converted) || !exch_rate){
      return null
    }  

    let multiply_rate = null 
    let rate = exch_rate.filter((ex) => (ex.from_currency === from_currency && ex.to_currency === to_currency)
        || (ex.to_currency === from_currency && ex.from_currency === to_currency))[0]

    if(rate){
      multiply_rate = rate.multiply_rate
      //on inverse le multiplier selon le sens du taux de conversion
      if(rate.to_currency === from_currency)  multiply_rate = 1/ multiply_rate
    }else {
        //le cas où on doit faire une conversion atypique pour lequel il n'y a pas d'exchange rate défini . Ex : CHF -> NOK
        //on fait une conversion intermédiaire . Ex : CHF -> USD -> NOK
      rate = exch_rate.filter((ex) => ex.from_currency === from_currency && ex.to_currency === "USD")[0]
      let rate2= exch_rate.filter((ex) => ex.from_currency === to_currency && ex.to_currency === "USD")[0]   
      if(rate && rate2) multiply_rate = rate.multiply_rate / rate2.multiply_rate
    }

      //si on a pu trouver le taux alors on convertit les prix & on affiche la nouvelle devise/currency
    if(multiply_rate) {
        //on inverse le taux si la conversion est dans le sens inverse de la paire
      amount_converted = parseInt(1000* multiply_rate*amount_converted)/1000
    }
    
    return amount_converted

  },

  img2pdf :   async function(img, pdf=null, landscape=false){
  
    let img_height = img.img_height;
    let img_width = img.img_width;
    let ratio = img_width/img_height
               
    if(!pdf){
      var _jspdf2 = function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }(jsPDF)
      var pdf_ = null 
      if(!landscape)
        pdf_ = new _jspdf2.default();
      else
        pdf_ = new _jspdf2.default({orientation: "landscape", unit: "in", format: [4, 2]});
        pdf_.setFontSize(7);
        pdf_.pages = 1
        pdf_.addImage(img.data, 'JPEG', 10, 0, 200, 200/ratio)
        return pdf_
    }else{
      pdf.addPage()
      pdf.pages +=1
      pdf.addImage(img.data, 'JPEG', 0, 0, 200, 200/ratio)
      return pdf
    }
  },
  
  html2img : async function(html){
    return new Promise( async function(resolve){
      html2canvas(html, {scale : 2}).then(canvas => {
        resolve({
          data : canvas.toDataURL('image/png'), 
          img_height : parseFloat(canvas.style.height.replace("px")), 
          img_width : parseFloat(canvas.style.width.replace("px"))      
        })
      })      
    })
  },

  html2pdf : async function(elementHTML, opts){
    var doc = new jsPDF({ unit: 'pt', format : 'a4'});
    //doc.internal.pageSize.height = 842
    //console.log("##freePDF",  doc.internal.pageSize.getHeight(),doc.splitTextToSize(elementHTML, 250))
	  
    doc.html(elementHTML, opts);

    return doc
  },
  
  
  formatDensity : function(density, showTotal=true){
    return  (isNaN(parseInt(density)) ? "" : 1 + "/" + parseInt(1000/density)  + (showTotal ? " | " + density : ""))
  },
  
  formatAmount : function(amount, currency="EUR"){
    if(!amount) 
      return ""
    return Intl.NumberFormat('fr-FR', {currencyDisplay :"code", style: 'currency', currency: currency }).format(parseFloat(amount)).replace(",",".")
  },

  formatAmount1 : function(val, currency){   
    if(!val) return 0   
    let value = val.toString()
    
    if(!value){
      value = ""
    }else if(value.includes(',') || value.includes('.')){
      value = value.toString()
      value = value.replace(",",".")
      value = numeral(value).format('0,0.00') + ' ' + currency
    }else{
      value = numeral(value).format('0,0.00') + ' ' + currency
    } 

    return value.replace(",", " ")
        
  },
      
  formatAmount2 : function(amount, currency="EUR", decimals=2){
    if(!amount) return 0
    amount = amount.replace(",", ".")
    let pow = Math.pow(10,decimals)
    amount = Math.round(pow*parseFloat(amount))/pow
    let res = Intl.NumberFormat('us-US', {style: 'decimal'}).format(amount)
    return res.replace(",", " ")  + " " + currency
  },
          
  formatAmount3 : function(val){
    let value = val ? val.toString() : ""
    if(!value){
      value = ""
    }else if(value.includes(',') || value.includes('.')){
      value = value.replace(",",".")
      value =  numeral(value).format('0,0.00')
    }else{
      value = numeral(value).format('0,0')
    } 

    return value.replace(',', ' ')      
  },

  formatAmount4 : function(val){
    let value = val ? val.toString() : ""
    if(!value){
      value = ""
    }else if(value.includes(',') || value.includes('.')){
      value = value.replace(",",".")
      let decimal = value.split('.')
      let lenght_decimal = decimal[1] ? decimal[1].length : 0
      value = lenght_decimal === 1 ? numeral(value).format('0,0.0') : numeral(value).format('0,0.00')
    }else{
      value = numeral(value).format('0,0')
    } 

    return value.replace(',', ' ')      
  },

  formatVolume : function(val){
    let value = val ? val.toString() : ""
    if(!value){
      value = ""
    }else if(value.includes(',') || value.includes('.')){
      value = value.replace(",",".")
    }
            
    let decimal = value.split('.')

    if(decimal && decimal.length > 1){

      if(decimal[1] && decimal[1].length > 3){
        value = Math.round(parseFloat(value)*1000)/1000
        value = parseFloat(value).toFixed(3)
      }

    }

    return isNaN(parseFloat(value)) ? "" : parseFloat(value)          
  },

  formatNumber : function(val, decimal = 2){
    let value = val ? val.toString() : ""
    if(!value){
      value = ""
    }else if(value.includes(',') || value.includes('.')){
      value = value.replace(",",".")
    }
            
    let decimals = value.split('.')

    if(decimals[0]){
      if(decimal > 3){
        value = parseFloat(value).toFixed(3)
      }else{
        value = parseFloat(value).toFixed(decimal)
      } 
    }

    return isNaN(parseFloat(value)) ? "" : parseFloat(value)    
  },

  dateComparison : function(date1, date2, type='equal') {
    let res = false;
    if(type === 'equal')
      res= date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
    else if(type === 'less'){
      res= ( date1.getFullYear() < date2.getFullYear() || 
            (date1.getFullYear() === date2.getFullYear() && date1.getMonth() < date2.getMonth()) || 
            (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() <= date2.getDate())
          )      
    }else res= null

    return res
  },
  
  getCurrentDate : function(days=0){
    let today = new Date();   
    today.setDate( today.getDate() + parseInt(days) ) ;  
    let year = today.getFullYear();
    let day = parseInt(today.getDate());
    let month = parseInt(today.getMonth()+1);
    day = day < 10 ? "0"+day : day; 
    month = month < 10 ? "0"+ month : month;        
    return year +"-"+ month + "-"+ day
  },

  days_diff(date1,date2){
    date1 = new Date(date1.toISOString().split('T')[0]);
    date2 = new Date(date2.toISOString().split('T')[0]);

    let date_diff = Math.abs( Math.round((date1 - date2) / (1000*3600*24) ));
    // console.log("Date1 => ",date1)
    // console.log("Date2 => ",date2)
    return date_diff
  },

  sleep : function(milliseconds)  {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  },
  
  validateEmail : function(email, multi=false) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
    if(!multi)
      return re.test(String(email).toLowerCase())
    try{
      let emails = email ? email.split(";") : ""
      let valid = true
      for(let i=0; i < emails.length; i++){
        if(!re.test(String(emails[i]).toLowerCase())) valid = false
      }
      return valid
    }catch(e){
      return false 
    }
  },

  convertDate : function(dateString, getTime = false, separator = "/"){
    let date = "";
    let day = "";
    let month = "";
    let hour = "";
    let minute = "";
    let time = "";
      
    date = new Date(dateString);  
    day = date.getDate();  
    day = ('0' + day).slice(-2);  
    month = ('0' + (date.getMonth() +1)).slice(-2);  
    hour = date.getHours();  
    hour = ('0' + hour).slice(-2);  
    minute =  date.getMinutes() ;
    minute =  ('0' + minute).slice(-2)  ;  
    time = day + separator + month  + separator + date.getFullYear();
      
    if(getTime)
      time = time + " | " + hour + ":" + minute;
      
    return time;   
  },
        
  dateToString : function(datetime){
    if(datetime)
      return ("0" + datetime.getDate()).slice(-2) 
                  + "/" + ("0" + (datetime.getMonth()+1)).slice(-2)
                  + "/" + datetime.getFullYear() 
                  + " " + ("0" + datetime.getHours()).slice(-2) 
                  + ":" + ("0" + datetime.getMinutes()).slice(-2)
  },     
  
  dateToString2 : function(datetime){

    if(!datetime)
      return null
    if(typeof(datetime) === "string")
      datetime = new Date(datetime.split("T")[0])
            
    return  ("0" + datetime.getDate()).slice(-2) 
                 +  this.MONTHS[datetime.getMonth()]
                 +  (datetime.getFullYear() - 2000 )             
  },
      
  dateToString3 : function(datetime){
    if(!datetime)
      return ""
    if(typeof(datetime) === "string")
      datetime = new Date(datetime)
      
    return  datetime.getFullYear() 
            + "-" + ("0" + (datetime.getMonth()+1)).slice(-2)
            + "-" + ("0" + datetime.getDate()).slice(-2) 
  },
          
  dateToString4 : function(datetime){
    if(!datetime)
      datetime = new Date()
    if(typeof(datetime) === "string")
      datetime = new Date(datetime) 

    return  ("0" + datetime.getDate()).slice(-2) 
                 + "-" + this.MONTHS[datetime.getMonth()]
                 + "-" +  (datetime.getFullYear() - 2000 )
                 + " " + ("0" + datetime.getHours()).slice(-2)
                 + ":" + ("0" + datetime.getMinutes()).slice(-2)
  },
  dateToString5 : function(datetime){
    if(!datetime)
      datetime = new Date()
    if(typeof(datetime) === "string")
      datetime = new Date(datetime) 

    return  ("0" + datetime.getDate()).slice(-2) 
                 + this.MONTHS[datetime.getMonth()]
                 + (datetime.getFullYear() - 2000 )
                 + " " + ("0" + datetime.getHours()).slice(-2)
                 + ":" + ("0" + datetime.getMinutes()).slice(-2)
  }
  ,
  dateToHour : function(datetime, loc = false){
    if(!datetime)
      return ""
    if(typeof(datetime) === "string")
      datetime =  new Date(datetime)

    //let diff = loc ? datetime.getTimezoneOffset()/60 : 0  
    return ("0" + datetime.getHours()).slice(-2) + ":" + ( "0" + datetime.getMinutes()).slice(-2)    
  },
  
  firstCharToUppercase  : function(sentence){
    if(!sentence) return ""
    return sentence
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  },

  AgentFormat : function(agent, word){
    if(agent &&  word && agent.toUpperCase().includes(word.toUpperCase())){
      return agent.toUpperCase().replace(word.toUpperCase(), '<span style="color:blue" ><b>' + word.toUpperCase() + '</b></span>');
    }else{
      return agent
    }
  },

  FormatText : function(text, word, lowercase = true){
    if(text &&  word && text.toLowerCase().includes(word.toLowerCase())){
     if(lowercase) 
      return text.toLowerCase().replace(word.toLowerCase(), '<span style="color:blue" ><b>' + word.toLowerCase() + '</b></span>');
     else 
      return text.toUpperCase().replace(word.toUpperCase(), '<span style="color:blue" ><b>' + word.toUpperCase() + '</b></span>');
    }else{
      return text
    }
  },
  
  isDeepEmpty : function(value){
    return !value || (typeof(value) === "string"  && value.replace(" ", "") === "") 
  },
  
  dataURItoBlob : function(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    var ia = new Uint8Array(ab);
      // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;  
  },
  
  toLetter: function(text) {  
    let value = text ? text.toUpperCase() : ""
    let result = ""
    if(value){
      for(let i = 0 ; i < value.length; i++){
         let c = value[i]
        if(this.LETTERS.indexOf(c) >= 0 || c === " "){
          result = result + c
        }
      }
      return result
    }else{
      return ""
    }
  }
}