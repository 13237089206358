import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import * as serviceWorker from './components/serviceWorker';
import Navbar from './components/Navbar.js'
import Main from './components/Main.js'
import List from './components/List.js'
import Home from './components/Home.js'
import Help from './components/Help.js'
import Analytics from './components/Analytics.js'
import Settings from './components/Settings.js'
import Stations from './components/Stations.js'
import Users from './components/Users.js'
import Rates from './components/Rates.js'
import Profile from './components/Profile.js'
import Util from './util.js'
import './styles/bootstrap.css';
import './styles/App.css';
import './styles/index.css';
import {HashRouter, Route} from 'react-router-dom';
import cookies from './Cookies';
import conf from './config.js'
import Routes from './Routes.js'
import Favicon from 'react-favicon';
import App_State from './states/App_State.js'
import { Provider } from 'react-redux';


const config = conf.config ;
const env = config.env
let store_app = App_State.store_app

if(env !== "LOCAL")
  console.log = () => {};

async function initData(){

try{
 
  let authorized_stations = await Routes.get("/AuthorizedStations", {}, true)
      authorized_stations= authorized_stations  ? authorized_stations.auth_stations : null

  if(!authorized_stations){
      return App_State.store_app.dispatch({
        type :1, 
        redirect : window.location.href, 
      })
  }else{
    let appData = await Routes.get("/AppData", {}, true)

    if(appData && appData.error)
      return
    else      
      return App_State.store_app.dispatch({
        type :1, 
        authorized_stations : authorized_stations, 
        carriers_list : appData.carriers_list, 
        custom_list : appData.custom_list, 
        shcList : appData.shcList, 
        exch_rate : appData.exch_rate, 
        loggedIn : true, 
        station_carrier : appData.station_carrier
      })
   }

}
catch(e){
  console.log("[ERROR] Loading authorized stations : ", e.toString())
}

}

initData();


document.body.addEventListener('click', ()=>{store_app.dispatch({type:1, last_active :  Date.now()}) }, true);

async function checkConnectivity(){
  
  while(1){

    let {loggedIn} = store_app.getState()
    if(loggedIn){
      let params = {last_login : true, update : true }
      let connected = await Routes.post("/Users", params, true)
       if(!connected) window.location.replace(config.front_host)
    }
    
    await Util.sleep(270*1000) //check every 5


  }
}

checkConnectivity()

async function refreshPendingQuotes(){
  
  await Util.sleep(5*1000)
  while(1){
    
    let {loggedIn} = store_app.getState()
    if(loggedIn){
      let data = await Routes.get("/PendingQuotes", {}, true)
      if(data)
       App_State.store_app.dispatch({type :1 , queued_quotes : data.count})

    } 
 
    await Util.sleep(300*1000) //check every 60s
 
  }
}

refreshPendingQuotes()


ReactDOM.render(

<Provider store={App_State.store_app}>

  <Favicon url={require("./img/quantum_favicon.PNG")} />
  
  <Navbar/>

  <HashRouter>
    <div>
      <Route exact path="/" component={Home}></Route>
    </div>
    <div>
      <Route path="/List" component={List}></Route>
    </div>
    <div>
      <Route exact path="/Editor" component={Main}></Route>
    </div>
    <div>
      <Route exact path="/Editor/:id" component={Main}></Route>
    </div>
    <div>
      <Route exact path="/Editor/QuoteInfo" component={Main}></Route>
    </div>
    <div>
      <Route exact path="/Editor/QuoteInfo/:id" component={Main}></Route>
    </div>
    <div>
      <Route exact path="/Analytics" component={Analytics}></Route>
    </div>
    <div>
      <Route exact path="/Help" component={Help}></Route>
    </div>
    <div>
      <Route exact path="/Settings" component={Settings}></Route>
    </div>

    <div>
      <Route exact path="/Stations" component={Stations}></Route>
    </div>


    <div>
      <Route exact path="/Users" component={Users}></Route>
    </div>

    <div>
      <Route exact path="/Rates" component={Rates}></Route>
    </div>

    <div>
      <Route exact path="/Profile" component={Profile}></Route>
    </div>

 </HashRouter > 

 
 </Provider> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


