import React from "react";
import watch from 'redux-watch'
import { Backdrop, CircularProgress} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import cookies from "../Cookies";
import Routes from '../Routes.js'
import * as Excel from "exceljs";
import {saveAs} from 'file-saver'
import { connect } from 'react-redux';
import Rates_State from '../states/Rates_State.js'
import conf from '../config.js'
import util from "../util";
import GetAppIcon from '@material-ui/icons/GetApp';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import ReactTooltip from "react-tooltip";


import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
 
const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration : false  , // print the duration of each action?
  timestamp : true, // print the timestamp with each action?
 
  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.
 
  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.
 
  logger : console, // implementation of the `console` API.
  logErrors : true, // should the logger catch, log, and re-throw errors?
 
  diff : false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`

});

//CONFIG
var config = conf.config ;
var store_prices = createStore(Rates_State.reducer_prices)
var store_filters = createStore(Rates_State.reducer_filters, applyMiddleware(logger))

class Main extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
      export_csv : "1",
      key : Date.now()
    }
  }

  async componentDidMount() {
    document.title = "Quantum - Rates";        
  }
  
  async componentDidUpdate(){

    const {authorized_stations} = this.props
    let station =  authorized_stations.filter((s)=>s.station === cookies.station)[0]

    if(!station) return 

    store_filters.dispatch({
      type:1, 
      station : station.station, 
      sales_region : station.sales_region, 
      origin_zone : station.origin_zone
    })
  }

render() {

  if(!this.props.loggedIn || !cookies.rights || (cookies.rights && !cookies.rights.rates_view)) {
    return window.location.replace(config.front_host)
  }

  return (
    <div key={this.state.key}>
      {this.state.notification}
      <div class="container-fluid background">  
        <PageLoadingSpinner />
        <FilterMenu authorized_stations = {this.props.authorized_stations} shcList ={this.props.shcList} exch_rate = {this.props.exch_rate}/>
        <PriceList class="row" exch_rate = {this.props.exch_rate} />
      </div> 
    </div>
  )
}

}
 
export default connect(
  (state) => ({
    authorized_stations: state.authorized_stations,
    shcList : state.shcList, 
    loggedIn : state.loggedIn, 
    exch_rate : state.exch_rate,
    custom_list : state.custom_list
  })
)(Main) 

class PriceList extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      key : Date.now()
    }
  }

componentDidMount(){
  let w1 = watch(store_prices.getState, 'prices')
  store_prices.subscribe(w1((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    this.setState({key : Date.now()})
  }))

}


render(){

  let html = []
  let prices = store_prices.getState().prices;
  let {offset} = store_filters.getState();

  if(!prices) return null   

  prices.forEach((u,i)=>{

    let ratesHTML = [<td></td>,<td></td>,<td></td>,<td></td>,<td></td>,<td></td>,<td></td>,<td></td>]// 8 items to fill
    let rates =  parseRate(u.rates, u.currency, this.props.exch_rate)
    
    //exclude bogus lines
    if(rates[0] === 0.01 || parseFloat(u.min_amount) === 0.01) return 
  
    //build html columns
    ratesHTML = rates.map((r)=><td>{r ? r.toFixed(2) : "-"}</td>)

    let surcharges = [];

    if(u.surcharges)  
      u.surcharges.split("|").forEach((s)=>{
        surcharges.push( <span>{s}</span>)
        surcharges.push(<br/>)
      })

    html.push(      
    <tr>
      <td style = {{width : "6em"}}>{offset + (html.length + 1)}</td>
      <td>{u.seq}</td>
      <td>{u.origin} {u.origin_zone ? " " + u.origin_zone + " (zone)" : ""}</td>
      <td>{u.dest} {u.dest_zone ? " " + u.dest_zone + " (zone)" : ""}</td>
      <td>{u.for_carrier}</td>
      <td>{u.selling_type} </td>
      <td>{(u.service_level ? u.service_level + ' / ' : '') + (u.price_class ? u.price_class : '') + (u.transit_airport ? ' via:' + u.transit_airport : '')} </td>
      <td>{u.agent}</td>
      <td>{util.dateToString2(u.from_date)}</td>
      <td>{util.dateToString2(u.until_date)}</td>
      <td>{u.currency}</td>
       {ratesHTML}
      <td>{parseFloat(u.min_amount)}</td>
      <td>{u.uld_type}</td>
      <td>{surcharges}</td>
      <td> 
        {!u.shc_addons  && ""}
        {u.shc_addons && u.shc_addons.split(",").length > 1 ? u.shc_addons.replace(/,/g, "\n") : ( u.shc_addons_details && u.shc_addons_details.trim().length > 4 ? u.shc_addons_details : "") }
      </td>
      <td>{u.loc_airports ? u.loc_airports.replace(/,/g, "\n") : ""}</td>
           
    </tr>)  

  })

 
  return (
      <div class="col users-list prices-list" key={this.state.key}>
        <h2>{html.length} results</h2>
        <table class="table-users table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Seq</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Carrier</th>
              <th>Rate Type</th>
              <th>Setup Details</th>
              <th>Agent</th>
              <th>From</th>
              <th>To</th>
              <th>Currency</th>
              <th>+1</th>
              <th>+45</th>
              <th>+100</th>
              <th>+250</th>
              <th>+300</th>
              <th>+500</th>
              <th>+1000</th>
              <th>+3000</th>
              <th>Min Amount</th>
              <th>ULD Type</th>
              <th>Freight Surchages</th>
              <th>SHC addon</th>
              <th>Loc addon</th>
            </tr> 
          </thead>
          <tbody class="us-list-body">
            {html}
          </tbody>
        </table>
      </div> 
  )   
  }
}


class FilterMenu extends React.Component{
  constructor(props){
    super(props)

    this.state = { 
      key : Date.now(), 
      scrolled : false, 
      hq_options :[ {value : null , name : "All"  }, {value : true , name : "Yes" }, {value : false , name : "No" }]        
    }

    this.choices = [{name : "No Conversion"},{name : "EUR"},{name : "USD"}]     
    this.rate_types = ["STREET", "PROMO", "CONTRACT", "DYNAMIC"]
    this.handleChange = this.handleChange.bind(this)
    this.handleStation = this.handleStation.bind(this)
    this.goToEnd = this.goToEnd.bind(this)
    this.goToNext = this.goToNext.bind(this)
    this.goToPrevious = this.goToPrevious.bind(this)
    this.pricesPerPage = 100
  }

  componentDidMount(){
    let w1 = watch(store_filters.getState, 'reset')
    store_filters.subscribe(w1((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now(), scrolled : false})
    }))

    let w2 = watch(store_prices.getState, 'users')
    store_prices.subscribe(w2((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now(), scrolled : false})
    }))

    let w3 = watch(store_filters.getState, 'currentPage')
    store_filters.subscribe(w3((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      getPrices()    
    }))


    let w4 = watch(store_prices.getState, 'prices')
    store_prices.subscribe(w4((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now(), scrolled : false})
    }))

   
    let w5 = watch(store_filters.getState, 'force')
    store_filters.subscribe(w5((newVal, oldVal, objectPath) => {
    //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now(), scrolled : false})
    }))    
  }

  handleStation(e){ 
    let code = e.target.value 
    let station = this.props.authorized_stations.filter((s)=> s.station === code)[0]
  
    if(station)
      store_filters.dispatch({type:1, station:code, sales_region:station.sales_region, origin_zone:station.origin_zone})
  }

  handleChange(e){
    
    const {shcList} = this.props
    e.preventDefault()
    let json = {type:1}
    json[e.target.name] = e.target.value
 
    let shc; 

    if(e.target.name === "commodity" && shcList){
      shc = shcList.filter((s)=>s.commodity_code === e.target.value ).map((s)=>s.shc) 
      json.shc = shc
    }
    
    //remove offset 
    json.offset=0
    json.currentPage = 1

    if(store_filters.getState()[e.target.name] !== e.target.value.toUpperCase() &&
    store_filters.getState()[e.target.name] !== e.target.value )
    store_filters.dispatch(json)
   
  }

  async handleSearch(){
    await getPrices() 
  }

  handleReset(){
    store_filters.dispatch({type:1, reset:true, force :true})
    store_prices.dispatch({type:1, prices  : []})
  }

  async goToFirst(){

    let { offset} = store_filters.getState();
    
    if(offset === 0) return 

    store_filters.dispatch({type:1, offset:0, currentPage:1})
    window.scroll(0,0)
                
  }
  async goToPrevious(){

    let { offset} = store_filters.getState();
    let {pricesPerPage} = this

    if(offset === 0 ) return 

    let newOffset = offset - pricesPerPage
    store_filters.dispatch({type:1, offset: newOffset, currentPage : parseInt(newOffset/pricesPerPage) +1 })
    window.scroll(0,0)           
              
  }

  async goToNext(){

    let { offset} = store_filters.getState();
    let {prices} = store_prices.getState();
    let {pricesPerPage} = this

    if(!prices.length || prices.length < pricesPerPage ) return 
  
    let newOffset = offset+pricesPerPage
    store_filters.dispatch({type:1, offset:newOffset, currentPage : parseInt(newOffset/pricesPerPage)+1})
    window.scroll(0,0)
     
  }

  async goToEnd(){
    let {nbPages} = store_filters.getState();
    let {prices} = store_prices.getState();

    if(!prices.length) return

    store_filters.dispatch({type:1, offset: (nbPages-1)*100})
    window.scroll(0,0)
  }

  render(){  

    let {origin, dest, carrier, commodity, agent, from_date, to_date, station, currency, rate_type, currentPage, nbPages} = store_filters.getState();
    const {prices} = store_prices.getState()
    const {pricesPerPage} = this
    let commodity_codes = this.props.shcList ? 

    this.props.shcList.map((shc) => { return shc.commodity_code}).filter((c , i, self) => self.indexOf(c) === i) : []
    commodity_codes.push("")
 
    return (
      <div key={this.state.key} class= { "filter-menu "} >
        < div class="row">
          <div class="col-2">
            <div class="row">
            
              <button 
                type="button" 
                class="btn btn-danger"
                data-tip = "Clear Search"
                data-type = 'info' 
                style = {{marginTop : '2em'}}
                onClick={this.handleReset}
              >
                <Clear fontSize="small"/>
              </button>
    
              <button 
                type="button" 
                class="btn btn-success" 
                data-tip = "Download Rate List"
                data-type = 'info' 
                style = {{marginTop : '2em'}}
                onClick={(e)=>onClickExport(e, this.props.exch_rate)} 
                > 
                  <GetAppIcon fontSize="small"/>
              </button>

              <button 
                data-tip = "Search"
                data-type = 'info'
                type="button" 
                class="btn btn-primary" 
                style = {{marginTop : '2em'}}
                onClick={this.handleSearch}
              >
                 <Search fontSize="small"/>
              </button>

              <ReactTooltip place="top" effect="solid" delayHide={500} />

            </div>

            <div class="row">   
            { prices &&
              <button type="button" class="btn btn-primary" 
                  style={{width:"30px"}}
                  disabled={currentPage === 1 ? "true" : null}
                  onClick={this.goToFirst}> 
                  1
              </button>
              }

              {prices &&
              <button 
                type="button" 
                class="btn btn-primary" 
                style={{width:"30px"}}
                onClick={this.goToPrevious}
                disabled={!prices || currentPage === 1}
              > 
              <strong>{" << "}</strong>
              </button>
              }

              {prices &&              
              <button 
                type="button" 
                class="btn btn-primary" 
                disabled={true}> 
              {currentPage}
              </button>
              }

              {prices &&              
              <button 
                type="button" 
                class="btn btn-primary" 
                disabled={!prices || prices.length < pricesPerPage}
                onClick={this.goToNext}> 
                  {" >> "}
              </button>
              }

              {prices && nbPages &&
              <button 
                type="button" 
                class="btn btn-primary" 
                disabled={currentPage === nbPages ? "true" : null}
                onClick={this.goToEnd}> 
               {nbPages}
              </button>
              }
            </div>

          </div>

          <div class="col-10">
            <div class="row row-filter">
              
              <div class="col-1">
                  <label >Origin</label>
                  <input type="text" class="form-control"
                    defaultValue={origin}   
                    name="origin"
                    onBlur={this.handleChange}  
                  />
              </div>

              <div class="col-1">
                  <label >Destination</label>
                  <input type="text" class="form-control" 
                    defaultValue={ dest} 
                    name="dest"
                    onBlur={this.handleChange}                      
                  />
              </div>

              <div class="col-1">
                  <label >Carrier</label>
                  <input type="text" 
                    class="form-control" 
                    defaultValue={carrier}
                    name="carrier"
                    onBlur={this.handleChange}                        
                  />
              </div>

              <div class="col-1">
                <label >Rate Type</label>
                <select class="form-control dropdown" id="inputHQ"  
                  name="rate_type"
                  onBlur={this.handleChange}
                  onKeyUp = {async (e)=>{ if( e.keyCode === 13) getPrices() } }>
                    <option value={ rate_type}>{rate_type}</option>
                    {this.rate_types.filter((c)=> c !==  rate_type ).map((c)=>{
                      return (
                          <option value={c}>{c}</option>
                      )
                      })
                    }
                </select>
              </div>
               
              <div class="col-1">
                  <label >Commodity</label>
                  <select class="form-control dropdown" id="inputHQ"  
                  name="commodity"
                  onBlur={this.handleChange}
                  onKeyUp = {async (e)=>{ if( e.keyCode === 13) getPrices() } }>
                    <option value={ commodity}>{commodity}</option>
                    {commodity_codes.filter((c)=> c !==  commodity ).map((c)=>{
                      return (
                        <option value={c}>{c}</option>
                        )
                      })
                    }
                  </select>
              </div>

              <div class="col-1">
                  <label >Agent</label>
                  <input type="text" class="form-control" 
                  defaultValue={agent} 
                  name="agent"
                  onBlur={this.handleChange} />
              </div>

              <div class="col-2">
                <form  style={{marginTop : '0.5em'}} noValidate>
                  <TextField
                      id="from_date"
                      label="Validity Start"
                      type="date"
                      name="from_date"
                      defaultValue= {util.dateToString3( from_date) }
                      onFocus={this.focus}
                      onBlur={this.handleChange}
                          InputLabelProps={{
                          shrink: true,
                          }}
                    />
                </form>
              </div>

              <div class="col-2">
                <form   style={{marginTop : '0.5em'}} noValidate>
                  <TextField
                    id="to_date"
                    label="Validity End"
                    type="date"
                    defaultValue= {util.dateToString3(to_date) }
                    onFocus={this.focus}
                    name="to_date"
                    onBlur={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    />
                </form>
              </div>

              <div class="col-2">

                  <label >Station</label>
                      <select class="form-control dropdown" 
                        id="inputHQ"  
                        onBlur={this.handleStation}
                        onKeyUp = {async (e)=>{ if( e.keyCode === 13) getPrices() } }
                        name="station">
                          <option value={station}>
                            {station}
                          </option>

                        {
                          this.props.authorized_stations.filter((s)=> s.station !== station ).map((s)=>{
                            return (
                              <option value={s.station}>{s.country_code} - {s.station_name} - {s.station} </option>
                            )
                          })

                        }

                      </select>
              </div>

              <div class="col-1">
                <label >Currency</label>

                  <select 
                    class="form-control dropdown" 
                    id="inputHQ"  
                    name="currency"
                    onBlur={this.handleChange}>
                    <option value={currency}> { currency} </option>

                    { this.choices.filter((c)=>c.name !== currency).map((c)=> <option> {c.name} </option>)  }

                  </select>
              </div>

            </div>  
          </div>

        </div>
      </div>
    )

  }

}


class PageLoadingSpinner extends React.Component{
  constructor(props){
    super(props);
    this.state = {  key : Date.now() }
    this.style = {
      position: "absolute",
      left: "50%",
      margin : "auto",
      "margin-top" : "20em",
      "z-index" : "10",
      color : "white"
    }

  }

  componentDidMount(){
    let w1 = watch(store_prices.getState, 'page_loading_spinner')
    store_prices.subscribe(w1((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }

  render(){

    let show = store_prices.getState().page_loading_spinner
    if(!show) return null
    else{
      return (
        <div key={this.state} style = {this.style} class="" role="status">
          <Backdrop   open={true}  >
          <CircularProgress color="inherit" />
          </Backdrop>         
        </div>
      )
    }

  }
}


function checkCommodity(shc, code){
  
  shc = shc ? shc : []
  code = code ? code : ""
  let result = false
  
  if(!shc || !code)
   result = false
  else  
  shc.forEach((s) => {
    if( s && (s.toUpperCase().includes(code) || code.toUpperCase().includes(s.toUpperCase())) )
    result = true
  })

  return result

}

async function getPrices(top=100){
 
  let params = {}
  params = {...store_filters.getState()}
  params.until_date = params.to_date
  params.origin = params.origin ? params.origin.toUpperCase() : null 
  params.dest = params.dest ? params.dest.toUpperCase() : null
  params.onlycarrier = params.carrier ? params.carrier.toUpperCase() : null
  params.carrier = params.carrier ? params.carrier.toUpperCase() : null
  params.agent = params.agent ? params.agent : 'all' 
  params.shipper = 'all'
  params.price_class = params.shc;  // will use shc string for filtering price_class directly inside store procedure
  params.service_level = 'all'

  if(params.from_date)
    params.from_date = new Date(params.from_date)
   
  if(params.until_date)
    params.until_date = new Date(params.until_date)

  store_prices.dispatch({type:1, page_loading_spinner : true})  
  params.top = top

  let prices =  await Routes.get("/Rates", params, true)

  prices = prices ? prices : []

  let shc = params.shc ? params.shc : []

  if(params.commodity){
    prices = prices.filter((p) => (!p.price_class && !p.service_level)  ||
    (!p.price_class && p.service_level && checkCommodity(shc, p.service_level)) ||
    (!p.service_level && p.price_class && checkCommodity(shc, p.price_class))  ||
    (p.service_level && p.price_class && (checkCommodity(shc, p.price_class) || checkCommodity(shc, p.service_level)) ))

  }

  store_prices.dispatch({type : 1, prices : prices, page_loading_spinner : false  }) 

  if(!prices) return null
 
}


async function  onClickExport(e, exch_rate){
  e.preventDefault()

  let columns = [ 
    "Origin", 
    "Dest",  
    "Carrier Code", 
    "Rate Type", 
    "Product/Price class",   
    "Sale/Premium", 
    "Shipper/Cnee",
    "Agent Code", 
    "Agent Name", 
    "Validity Start", 
    "Validity End", 
    "All-In/Net-Net", 
    "Currency", 
    "Min Loose", 
    "+1", 
    "+45", 
    "+100", 
    "+250", 
    "+300", 
    "+500", 
    "+1000", 
    "+3000",  
    "ULD Type", 
    "Freight Surcharges", 
    "SHC Add-On", 
    "Location Add-On", 
    "Prepaid/Collect",
    "Transit / Routing ", 
    "Flight Group / Alloment ", 
    "DoW", 
    "Sequence"
  ]

  let data = []
  let top = config.env === "LOCAL" ? 100 : 5000
  await getPrices(top);
  await util.sleep(100);
  let {prices} = store_prices.getState()
 
  if(!prices) return
  let ele
  prices.forEach((q)=>{
    let rates =  parseRate(q.rates, q.currency,  exch_rate)
    
    if(rates[0]=== 0.01 || parseFloat(q.rate) === 0.01) return 

    rates = rates.map((r)=>r? r.toFixed(2) : "-")

    ele = [ 
      (q.origin_zone ? q.origin_zone + "zone" : "") + " " + ( q.origin ? q.origin : ""),  
      (q.dest_zone ? q.dest_zone + "zone" : "") + " " +  ( q.dest ? q.dest : ""),
      q.for_carrier, q.selling_type,
      (q.service_level ? q.service_level  : '') +(q.service_level && q.price_class ? ' / ' : '') + (q.price_class ? q.price_class : ''),
      (q.premium === "P") ? "Premium" : (q.premium === "S") ? "Sale" : "", 
      q.shipper,
      q.agent,
      q.agent_name,
      util.convertDate(q.from_date), 
      q.until_date ? util.convertDate(q.until_date) : "", 
      q.all_in_rate === "Y" ? "All-In" : "Net-Net" , 
      q.currency, 
      q.min_amount ? parseFloat(q.min_amount)  : "-",
      rates[0], 
      rates[1],
      rates[2], 
      rates[3], 
      rates[4], 
      rates[5], 
      rates[6],
      rates[7],
      q.uld_type, 
      q.surcharges, 
      q.shc_addons_details,  
      q.loc_addon_details, 
      (q.prepaid_collect === "P") ? "Prepaid" :  (q.prepaid_collect === "C") ? "Collect" : "", 
      (q.transit_airport || "") + " " + (q.transit_carrier||"") +  (q.routing ? " / " + q.routing : ""), 
      q.flight_group, 
      q.week_day, 
      q.seq 
    ]

    data.push(ele)
  });

    //CREATE EXCEL FILE 
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('prices');
    /////TABLE DES QUOTES & ENTETES
    worksheet.addTable({
      name: 'PricesTable',
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium2',
        showRowStripes: false,
      },
      columns: columns.map((c) => {return {name : c, filterButton  : true } }) ,
      rows:data,
    });

  //FORMATTAGE
    worksheet.getRow(1).eachCell((cell) => {
    cell.border= {
      top: {style:'thin', color:"€"},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    cell.fill = {
      type: 'pattern',
      pattern:'darkVertical',
      bgColor:{argb:'ff1e3194'},
      fgColor:{argb:'ff1e3194'}    
    };
    cell.font = {
      name: 'Arial Black',
      color: { argb: 'FFFFFFFF' },
      family: 2,
      size: 11,
    } 
  });
  
// save under export.xlsx
    workbook.xlsx.writeBuffer('prices_export.xlsx').then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      let filename = "Rates_Extract_" + util.convertDate(new Date(), false, ".") + ".XLSX"
      saveAs(blob, filename);
    });


        
    let logs = {
      type : "EXPORT_RATES",
      info : prices ? prices.length : ""
    }
    await Routes.get("/Logger",logs, true) 
}


function parseRate(rate_string, rate_currency, exch_rate){
  
  if(!rate_string) return []

  let rates_ = rate_string.split("|")
  let rates = [0,0,0,0,0,0,0,0]
  let weights = [1,45,100,250,300,500,1000,3000]
  let {currency} = store_filters.getState();

  for(let j = 0; j < rates_.length; j++){
          
    let weight = parseFloat(rates_[j].split(";")[1])
    let rate = parseFloat(rates_[j].split(";")[2])
          
    if(currency !== "No Conversion" && rate_currency !== currency ){
      rate = util.convert_currency(rate,rate_currency, currency ,  exch_rate)      
    }


    for(let i = 0; i < weights.length; i++){
            
      if(i === (weights.length -1) && i <= j) 
        rates[i] = rate       
      else if(rate > 0 && weight >= weights[i] && weight < weights[i+1]){
        rates[i] = rate
      }
   
    }
          
  }
       
  return rates 
}